////////////////////////////
//
//  BUTTON Styles
//
////////////////////////////

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
    @include btn-brand-color;
    @include trans-dur($button-retard);
    display: block;
    margin: auto;
    outline: 0;
    overflow: hidden;
    border-radius: 1px;
    cursor: pointer;
    font-family: $special-font;
    text-decoration: none;
    text-align: center;

    &:hover {
        @include btn-grey-color;
    }
    &:active,
    .btn-brand:active,
    .btn-grey:active {
        @include btn($disabled-btn, null, $disabled-btn, null);
    }

    &:focus,
    .btn-acc:focus {
        color: $txt-white;
    }

    &:disabled, &.disabled {
        @include btn($disabled-btn, null, $disabled-btn, $txt-grey-4);
        cursor: default;
    }
}

// Estil btn-brand (color marca), btn-grey (color alternatiu)

.btn-brand {
    @extend button;
    @include btn-brand-color;

    &:hover {
        @include btn-grey-color;
    }
}

.btn-grey {
    @extend button;
    @include btn-grey-color;

    &:hover {
        @include btn-brand-color;
    }
}

.btn-error-retry {
    @extend button;
    @include btn-error-retry-color;
}


////////////////////////////
//
//  ACCORDION BUTTON Styles
//
////////////////////////////
// BOTONS ACORDIÓ Colors
// Botons del Menú desplegable - .normal, el .selected i el hover es el btn-grey:hover
.btn-acc {
    @extend button;
    @include btn($grey-3b, $border-normal, $grey-3, $txt-grey-0);

    &:hover,
    &.selected {
        @extend .btn-grey:hover;
    }
 }

////////////////////////////
//
//  MOVEMENTS BUTTON Styles
//
////////////////////////////

.btn-movs-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5%;

    .btn-movs {
        margin-right: 4px;
    }
}

.btn-movs {
    @include ellipsis-text;
    @extend button;
    @include btn($white, $border-normal, $mov-label-color, $mov-label-color);
    @include trans-dur($button-retard);
    display: block;
    padding: 2px; //2px instead of 4px for correct measure of the tooltip
    width: 30%;
    border-radius: 3px;
    border-collapse: collapse;
    font-size: 12px;

    &:hover,
    &.selected {
        @include btn($mov-new-mark, $border-normal, $mov-new-mark, $mov-label-color);
    }
    &.edited {
        @include btn(null, $border-normal, $warning-color, $txt-warning);
        font-weight: bold;
    }

    &.disabled {
        pointer-events: none;
    }

}

//////////////////////////
//Botons del submenú - botons d'acció (especial text-align i min-height)
.btn-acc-action {
    @include btn($white, $border-normal, $grey-3, $txt-grey-0);
    min-height: 40px; //Respectar la mida mínima
    text-align: left;

    &:hover,
    &:focus {
        @include btn(transparent, $border-normal, $brand-color, $txt-brand);
    }

    // botons main menu i d'acció de l'acordió amb el mateix focus
    &:focus {
        @include btn(null, $border-normal, $grey-0, $txt-grey-0);
    }
    // Quan l'acció de l'acordió estigui desactivada "acc-root-item-disabled" -
    //el hover del botó es neutre i no hi ha canvi de color de la font
    .acc-root-item-disabled &:hover {
        @include btn(null, $border-normal, transparent, $txt-grey-0);
        cursor: default;
    }
}

//// RESULTS button styles
.btn-results {
    @extend button;
    @include btn($bkg-results-btn, $border-normal, $b-results-btn, $txt-results-btn);
    @include ellipsis-text;
    @include div-size(auto, 20px);
    @include padding(1px, 2px, 1px, 2px);
    @include margin (2px, 2px, 2px, 2px);
    @include trans-dur($button-retard);
    @include text($base-strong-font, 11px, null, nyll, null, null);
    display: block;
    border-radius: 3px;
    border-collapse: collapse;
    cursor: move;

    &:hover, &.selected, &.selected:hover {
        @include btn($yellow, $border-normal, $b-results-hover-btn, $txt-results-hover-btn);
    }
}

////////////////////////////
//
//  MAIN MENU BUTTON Styles
//
////////////////////////////
//BOTONS MAIN MENU - comparteix hover i selected amb els botons acordió
//també comparteix el posicionament de icon

.btn-mmenu,
.wgt-btn-combobox,
.wgt-btn-fingerPrint {
    @extend button;
    @include btn($grey-4, $border-normal, $grey-4, $txt-grey-0);
    @include text(null, 12px, null, null, left, null);

    &:hover,
    &.selected {
        @extend .btn-acc-action:hover;
        background-color: rgba(255,255,255,.5);
    }
    &:focus {
        @extend .btn-acc-action:focus;
    }
    .mov-detail-row & {
        text-align: center;
    }
}

//Estil botons mmenu quan estan deshabilitats (cform)
.btn-mmenu-disabled {
    @include btn(transparent, null, null, $txt-grey-2);
    cursor: default;
    opacity: 0.3;

    //Hover desactivat (mode cform-view)
    &:hover,
    &:focus {
        @include btn(null, $border-normal, transparent, $txt-grey-2);
    }
}

////////////////////////////
//
//  MIDES BOTONS -general Styles
//
////////////////////////////
// MIDES BOTONS - generals
// usar l'small quan hi hagi dos botons junts amb poc text (sí / No, Aceptar / Cancelar)
.btn-small {
    @extend button;
    @include div-size(90px, 38px);
    font-size: 12px;
    padding: 10px;

    //Quan hi hagi dos botons junts a "modal-win-footer-btn"
    .modal-win-footer-btn & {
        @include margin(0, 5px, 0, 5px);
        display: inline-block;
        width: auto;
        min-width: 90px;
    }
}

.btn-mini {
    @extend .btn-small;
    @include margin(0, 5px, 0, 10px);
    display: inline-block;
    height: 24px;
    line-height: 12px; //LLP : required to center text vertical (in CWgtFilterButton)
    font-size: 12px;
    padding: 5px;

    // Mini quan estigui dins cierres-toolbox
    .cierres-toolbox & {
        @include margin(0, 5px, 0, 5px);
        @include btn(transparent, $border-normal, $grey-0, $txt-grey-0);
        display: inline-block;

        &:hover,
        &:focus {
            @include btn(null, $border-normal, $brand-color, $txt-brand);
        }
    }
}

.btn-medium {
    @extend .btn-small;
    @include padding(10px, 20px, 10px, 20px);
    width: 140px;

    .fltr-col & {
        @include div-size(90px, 30px);
        top: 0;
        padding: 0;
    }

    .modal-footer & {
        @include margin(5px, 5px, 0, 5px);
        display: inline-block;
    }
}

.btn-large {
    @extend .btn-medium;
    width: max-content;
    min-width: 300px;
}

.btn-expand-group {
    @extend button;
    @include div-size(24px, 24px);
    @include btn($grey-3b, $border-normal, transparent, $txt-brand);
    @include text($special-font, 14px, null, bold, center, null);
    position: relative;
    display: inline-block;

    .cform-view & {
        color: $txt-grey-2;
        background-color: $grey-4;
    }

    &.calc {
        font-family: $special-font;
        font-size: 12px;
    }

    &:hover,
    &.calc:hover {
        @include btn($brand-color, null, null, $txt-white);
    }

    .modal-wrapper & {
        color: $tree-level-0-bkg;
    }

    .modal-wrapper &:hover {
        @include btn($tree-level-0-bkg, null, null, $tree-level-0-bkg);
        cursor: default;
    }
}

.calc {
    @include div-size(22px, 22px);
    font-family: $special-font;
    font-size: 12px;
    }

// Botons mide especial ACCORDION - MAIN MENU
// botó per a l'acordió - especial width 98% i height auto, font 12px
.btn-acc-menu {
    @extend button;
    @include div-size(98%, auto);
    @include margin(1px, 0, 1px, null);
    @include padding(10px, null, 10px, null);
    font-size: 12px;

    &.preview {
        @include div-size(100%, auto);
        margin: 0;
    }
}

.btn-acc-submenu {
    @include margin(1px, 0, 1px, 0);
    width: 100%;
    min-height: 40px;
    font-size: 10px;
}

// botó per main menu especial width 100% padding 4px
.btn-main-menu {
    width: 100%;
    padding: 1px 4px;
}

// Estil especial pel botó widget card-reader
.btn-card-reader {
    @include margin(10px, null, null, 0);
    @include  btn(null, $border-normal, $grey-3, null);
    padding: 5px;

    &.disabled {
        opacity: 0.4;
        background-color: transparent;
        border-color: $grey-4;
    }
}

////////////////////////////
//
//  ACCESORIS BOTONS Styles
//
////////////////////////////
// ACCESORIS ALS BOTONS (icones i arrow)
// main-menu i acció de l'acordió (posicionament) propietat img
.icon-btn {
    margin-right: 5px;

    // el botó de gestió de columnes no incrementa marge a la dreta pero sí top
    .win-column-btn &,
    .calendar-right-box &  {
        margin-right: 0;
    }

    .win-view-button-zone &:hover,
    .calendar-right-box &:hover {
        background-color: $yellow;
    }

    .calendar-right-box &:hover {
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.4;
        cursor: none;
    }
}

// main-menu (posicionament) propietat <img>
.arrow-btn-mmenu {
    float: right;
    margin-left: 4px;
}

// Cas si està a la capçalera d'una grid
.arrow-btn-cell {
    @include margin(0, 5px, null, 0);
}

////////////////////////////
//
//  PROPERTY BUTTON Styles
//
////////////////////////////
// BOTONS només amb icon (propietat de <button>)
.btn-icon {
    @include btn(transparent, none, null, null);
    display: inline-block;
    margin-left: 2px;
    padding: 0;

    &:hover,
    .win-column &:hover {
        @include btn($yellow, none, null, null);
        outline: 0;
        cursor: pointer;
    }

    &:focus {
       outline: 1px dotted $grey-3;
    }

    //LLP: Hi ha algun cas, (ex. botó filterBySelected de la toolbar) on el botó
    //     ha de funcionar com un 'toggle button' segons l'estat d'una variable JS
    //LLP El problema és que btn-icon posa un bkcolor groc degut al focus, però
    //    en aquest cas concret el bkcolor només pot venir marcat per l'estat del botó i no pel focus
    &.selected {
        background-color: $yellow;
    }

    &.win-view-button.not-selected {
        background-color: transparent;

        &:hover {
            background-color: $yellow;
        }
    }

    &.disabled,
    .win-column-btn &:disabled,
    &:hover.disabled {
        opacity: 0.4;
        cursor: default;
        background-color: transparent;
    }

    &.actiu {
        opacity: 0.9;
        cursor: pointer;
        background-color: transparent;
    }

    .login_form & {
        margin: auto;
    }

    .cal-dpicker & {
        height: 24px;
    }

    // Quan btn-icon es el botó de gestió de columnes
    .win-column-btn & {
        margin-left: 1px;
    }

    // Quan un botó o un btn-icon està dins btn-symbol (barra titòl de les finestres)
    //no hi ha hover ni focus
    .btn-symbol &,
    .btn-symbol &:hover {
        background-color: transparent;
        outline: 0;
        border: 0;
    }

    .win-tbl-filters-zone & {
       // margin-left: 3px;
    }

    .win-view-button-zone &:hover {
        background-color: $dark-yellow;
    }

    .shift-actions-wrapper & {
        margin-left: auto;
    }

    .shift-actions-wrapper &:hover {
        background-color: transparent;
        opacity: .5;
    }

    .treeview &:hover {
        background-color: transparent;
    }

    .action-collapse-position & {
        //margin-left: -5px;
    }

    .toggle-button-list-item &:hover {
        background-color: transparent;
        color: $txt-brand;
    }
}

.filters-list {
    @include div-size(20px, 20px);
    float: right;
}

//TODO : This style is temporary, must be reviewed...
.btn-icon-mini {
    @include div-size(20px, 20px);
    margin: 2px;
    background-color: #3fc3ee;
}

.img-mini {
    @include div-size(18px, 18px);
}

////////////////////////////
//
//  btn-icon ACCESORI Styles
//
////////////////////////////
//Etiqueta nombre de finestres obertes en segon plà
//div addicional amb aquesta class
.btn-badges {
    @include div-size(30px, 20px);
    @include btn($white, $border-normal, $brand-color, $txt-brand);
    @include text(null, null, 1.3, bold, center, null);
    display: inline-block;
    margin-right: -2px;
    border-radius: 20px 0 0 20px;

    .win-tbl-filters-zone & {
        @include div-size(20px, 20px);
        @include text(null, 10px, 1.8, normal, null, null);
        margin-right: -2px;
    }
}

// Botó que es veu al costat del g-win-item
// per tancar la finestra minimitzada
.btn-close-item {
    @include div-size(18px, 18px);
    @include btn($white, $border-normal, $error-color, $txt-error);
    @include text(null, 16px, 1.1, bold, center, null);
    position: relative;
    display: inline-block;
    margin-right: 10px;

    &:hover {
        @include btn($error-color, $border-normal, $error-color, $txt-white);
    }

    &:disabled {
        @include btn($white, $border-normal, $grey-2, $txt-grey-2);
    }
}

// BOTONS CONTROL FINESTRES (win-view)
// tancar, maximitzar, restaurar minimitzar

.btn-symbol, .btn-symbol button {
    @include btn(transparent, none, null, null);
    position: relative;
    display: inline-block;
    margin-left: 2px;
    padding: 0;

    &:hover, &:focus {
        outline: 0;
    }

    &:disabled {
        opacity: 0.4;
    }
}

.btn-win {
    @extend button;
    @include div-size(40px, 25px);
    display: inline-block;
    outline: 0;

    .btn-symbol &:hover {
        background-color: $grey-1;
    }

    // Quan la finestra estigui disabled "win-base.disabled" -
    //els botons de finestre adopten el fons corresponent
    .win-base.disabled & button {
        @include btn(transparent, $border-normal, transparent, null);
        @include trans-dur(0s);
    }
}

// BOTÓ x TANCAR MODAL-WIN //

.btn-close-modal-win {
    @include text(null, 20px, null, null, center, $txt-brand);
    display: inline-block;
    outline: 0;
    vertical-align: top;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    background-color: inherit;

    &:hover, &:focus, &:active {
        @include btn($brand-color, null, null, $txt-white);
        box-shadow: none;
    }

    &:focus {
        border-color: $white;
    }

    &:active {
        background-color: $grey-2;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }

    &:disabled:hover {
        box-shadow: none;
    }
}

// BOTONS FILTRES (TOPBAR - inside window)  //

.btn-filter {
    @include btn(null, $border-normal, $brand-color, null);
    display: inline-block;
    width: auto;
    margin-left: 3px;

    &:hover, &:focus, &:active {
        background-color: $yellow;
    }

    &:active {
        @include btn(null, $border-normal, $brand-color, null);
    }
}

//COMBOBOX BTN - dins el contenidor cb-button "TRIANGLE"//
.btn-cb, .btn-cb-inc {
    @include position-all(absolute, null, 0, null, null);
    @include div-size(24px, 22px);
    @include box-sizing(border-box);
    @include btn($white, null, null, $txt-grey-2);
    @include text(null, 12px, 1.5, null, center, null);
    display: inline-block;

    &:hover,
    .cb-button:hover &,
    .cb-button.cb-active &,
    .mov-detail-row &:hover {
        @include btn-brand-color;
    }

    .cb-button.disabled & {
        @include btn(transparent, null, null, transparent);
    }

    .btn-cb-inc {
        right: 0;
        padding-top: 0;
        background-color: transparent;

        .btn-cb-inc:hover {
            @include btn(transparent, $border-normal, transparent, $txt-white);
        }
    }

    //Movements row
    .mov-detail-row & {
        @include btn($white, null, null, $txt-grey-2);

        &.disabled,
            //Movements type of row ar mov
        .mov-deleted &.disabled,
        .mov-not-effective-mark-row &.disabled,
        .mov-error-mark-row &.disabled,
        .act-detail &.disabled,
        .ot-detail &.disabled,
        .acc-detail &.disabled,
        .virtual-detail &.disabled,
        .mov-virtual-mark &.disabled,
        .mov-new-mark &.disabled,
        .mov-new-shift &.disabled,
        .mov-new-row &.disabled,
            // Combo - Movements. Pending status
        .status-pending + .mov-detail-row &.disabled {
            @include btn(transparent, null, null, transparent);
        }
    }
}

.btn-cb-undo {
    @include position-all(absolute, 0, 0, null, null);
    @include div-size(22px, 22px);
    @include box-sizing(border-box);
    @include btn(null, $border-normal, transparent, $txt-brand);
    @include text(null, 20px, 0.9, null, center, null);
    display: inline-block;
    transform: rotate(-90deg);

    &:hover {
        @include btn-brand-color;
    }

    .cierres-list &:hover {
        @include btn($yellow, $border-normal, $yellow, $txt-brand);
        cursor: pointer;
    }

    .g-win-filter-op & {
        @include div-size(24px, 24px);
        @include btn(null, $border-normal, $grey-4, null);
        top: 45px;
        font-size: 24px;

        .g-win-filter-op &:hover {
            @include btn($grey-1, $border-normal, $grey-1, null);
        }
    }

    &.disabled, .cb-button.disabled ~ &  {
        @include btn(transparent, null, null, transparent);
        pointer-events: none;
    }

}

.btn-calendar {
    @include div-size(24px, 24px);
    @include position-all(absolute, 3px, null, null, null);
    @include box-sizing(border-box);
    @include btn(null, $border-normal, transparent, null);
    display: inline-block;

    &:hover {
        background-color: $yellow;
    }
}

//TREE - btn-tree - obre i tanca branques de l'arbre
.btn-tree {
    @include position-all(absolute, null, null, null, 0);
    @include div-size(24px, 24px);
    @include btn(transparent, $border-normal, transparent, $txt-brand);
    @include text(null, 12px, 24px, null, null, null);
    display: inline-block;
    margin: auto 0;
    top: 0;
    bottom: 0;

    &:hover {
        @include btn-brand-color;
    }

    &.opened {
        transform: rotate(90deg);
    }
}

//HEADER GRID - buttons - capçalera taules de dades //
.btn-tbl-th {
    @include btn($grey-2, null, null, $txt-grey-0);
    width: auto;
    font-size: 12px;
    cursor: pointer;

    &:hover, &:focus, &:active {
        @include btn($grey-1, null, null, $txt-white);
    }

    &:focus, &:active {
        background-color: $grey-3;
    }
}

////////////////////////////
//
// EDITOR DE FILTRES - BUTTONS
//
////////////////////////////

.btn-fltr-edit {
    @extend button;
    background-color: white;

    &:hover,
    &:focus {
        @include btn($grey-3, null, $grey-1, $txt-grey-1);
    }
}

.btn-fltr-edit {
    @extend .btn-small;
    @include padding(10px, 20px, 10px, 20px);
    width: 95%;
    font-size: 12px;

    .fltr-col & {
        top: 0;
        height: 30px;
        padding: 0;
    }
}

.btn-modo {
    @include btn(null, $border-normal, #5f9fa1, #5f9fa1);
}

//iframe import button
.btn-ibrand {
    @include btn(null, $border-normal, $brand-color, $txt-brand);
}
