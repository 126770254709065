////////////////////////////
//
//  Calendar module (general & personnel calendars, 1 year, etc.)
//  Used by \Modules\ModCalendar components
//
////////////////////////////

.calendar-container  {
    @include position-all(absolute, 42px, null, null, null);
    @include div-size(100%, 100%);
}

.calendar-acc-main {
    @include position-all(absolute, 70px, 1px, 0, null);
    width: 280px;
    float: right;
    overflow:  hidden auto;
}

//A box to hold 4 x 3 sheets (a year)
.calendar-year-box {
    width: 100%;
    margin: auto;
    background-color: $grey-5;
    text-align: center;
}

//A right box that contains panels (calendars, combo selector, sheets lists, etc)
.calendar-right-box {
    width: 100%;
}

//small quad to show the color of every item of shifts list , calendar list, etc.
.calendar-list-item-quad {
    @include div-size(18px, 18px);
    @include margin(2px, 4px, 0, 4px);
    display: inline-block;

    &.alt_shift {
        border-radius: 0 60% 0 0 / 0 45% 0 0;
    }
    &.timeType {
        border-radius: 50%;
    }
    &.img {
        margin-top: 0;
    }
}

.calendar-list-item-txt {
    display: inline-block;
    font-size: 12px;

    &:hover {
        color: $txt-brand;
    }

    &.label {
        @include text($special-font, null, null, bold, right, $txt-brand);
    }

    &.value {
        text-align: left;
    }

    .g-win-item & {
        @include ellipsis-text;
    }
}

.g-win-item .label {
    text-align: left;
}

.calendar-combo-lists {
    margin:5px;
    background-color: $white;
}

.calendar-info-list {
    @include margin(10px, 5px, 5px, 5px);
    @include divStyle($grey-5, $border-normal $grey-3, null);
    padding: 4px;
}

////////////////////////////
//
//  CALENDAR NAME ZONE Styles
//
//////////////////////////////

.cal-name-zone {
    //@include position-all(relative, 5px, null, null, null);
    //display: block;
    width: 100%;
    margin-top: 5px;
    padding-left: 2px;
}

.cal-item-label {
    @include text($special-font, 12px, 2, null, right, $txt-grey-0);
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    background-color: transparent;
}

////////////////////////////
//
//  BUTTONS ROW WRAPPER Styles (position)
//
//////////////////////////////

.calendar-btns-wrapper {
    display: flex;
    width: 100%;
    padding-top: 0;
    justify-content: center;

    .cal-dpicker & {
        padding-top: 0;
        background-color: $grey-5;
    }

    .win-slot-calendar-actions &, .wgt-wrapper & {
        padding-top: 5px;
        background-color: $grey-5;
    }
}

.cal-dpicker img {
    width:  18px;
}
////////////////////////////
//
//  CALENDAR Styles  (coming from "calendar.scss" but altered to render small sheets)
//
//////////////////////////////

.calendar-base {
    @include div-size(180px, 165px);
    @include divStyle($white, $border-normal $grey-3, null);
    @include text($special-font, null, null, null, null, null);
    position: relative;
    display: inline-block;
    margin: 0.1em;
    padding: 4px;
    overflow: hidden;

    &.cal-dpicker {
        @include div-size(230px, 185px);
        @include divStyle(null, $border-normal $brand-color, null);
        padding: 4px;
        box-shadow: 1px 1px 2px $grey-1;
    }
}

.year {
    @include text(null, null, 1.5em, null, center, $txt-brand);
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    grid-gap: 5px;
    background-color: $white;
}

.week-structure {
    @include div-size(100%, 1.2em);
    @include divStyle($white, null, $txt-grey-0);
    @include box-sizing(border-box);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;
}

.name-month, .name-year {
    @include text($special-font, 11px, null, bold, right, $txt-brand);
    @include box-sizing(border-box);
    width: auto;
    margin: auto;
    padding-top: 1px;
    text-transform: uppercase;
}

.name-year {
    text-align: left;
}

.name-month-text, .name-year-text {
    border-bottom: $border-invisible;

    &:hover, &.hovered {
        @include divStyle($yellow, null,  null);
    }

    //Per evitar que quan es vol clicar l'etiqueta s'acabi clicant el fons, es fa més ampla l'etiqueta
    &.year-left {
        width: 50%;
        margin-left: 5px;
    }

    &.month-right {
        text-align: right;
    }
}

.name-day {
    @include box-sizing(border-box);
    @include padding(2px, null, 2px, null);
    @include text(null, 9px, null, null, center, null);
    text-transform: capitalize;
    border-bottom: $border-invisible;

    &:hover, &.hovered {
        @include divStyle($yellow, null, null);
    }

    .cal-dpicker &:hover, .cal-dpicker &.hovered {
        @include divStyle(transparent, null, null);
    }
}

.num-day {
    @include text(null, 10px, 1.5em, null, null, $txt-grey-0);
    @include box-sizing(border-box);
    position: relative;
    width: 20px;
    border: $border-invisible;


    &.applied {
        @include divStyle($brand-color, $border-normal $brand-color, $txt-white);
    }

    &.selected-cycle {
        @include divStyle(rgba(0, 160, 155,.3), $border-normal $brand-color, null);
    }

    &.selected {
        @include box-sizing(border-box);
        @include divStyle(null, $border-normal rgb(255,0,0), null);
        font-weight: bolder;
        box-shadow: 0 2px 0 -1px rgb(255,0,0), 0 -2px 0 -1px rgb(255,0,0), 2px 0 0 -1px rgb(255,0,0), -2px 0 0 -1px rgb(255,0,0),  0 0 1px 1px inset rgb(255,0,0);

        &.selected:hover {
            @include divStyle(null, $border-normal $brand-color, null);
            box-shadow: 0 2px 0 -1px $brand-color, 0 -2px 0 -1px $brand-color, 2px 0 0 -1px $brand-color, -2px 0 0 -1px $brand-color,  0 0 1px 1px inset $brand-color;
        }

        &.selected[class*='COL_'],  &.selected[class*='_ALPHA_'] {
            @include box-sizing(border-box);
            @include divStyle(null, $border-normal rgb(255,0,0), null);
            font-weight: bolder;
            box-shadow: 0 2px 0 -1px rgb(255,0,0), 0 -2px 0 -1px rgb(255,0,0), 2px 0 0 -1px rgb(255,0,0), -2px 0 0 -1px rgb(255,0,0),  0 0 1px 1px inset rgb(255,255,255);}
        }

    &.alt_shift:not(.selected) {
        border-radius: 0 60% 0 0 / 0 45% 0 0;
    }

    &.alt_shift.selected {
        @include box-sizing(border-box);
        @include divStyle(null, $border-normal rgb(255, 0, 0), null);
        font-weight: bolder;
        border-radius: 0 60% 0 0 / 0 45% 0 0;
        box-shadow: 0 2px 0 -1px rgb(255, 0, 0), 0 -2px 0 -1px rgb(255, 0, 0), 2px 0 0 -1px rgb(255, 0, 0), -2px 0 0 -1px rgb(255, 0, 0), 0 0 1px 1px inset rgb(255, 0, 0);

        &.alt_shift.selected.hovered {
            font-weight: bold;
            @include divStyle(null, $border-normal $brand-color, null);
            border-radius: 0 60% 0 0 / 0 45% 0 0;
            box-shadow: 0 2px 0 -1px $brand-color, 0 -2px 0 -1px $brand-color, 2px 0 0 -1px $brand-color, -2px 0 0 -1px $brand-color,  0 0 1px 1px inset $brand-color;
        }
    }

        &.calendar:not(.selected) {
        @include box-sizing(border-box);
        border-right: $border-normal $brand-color;
        border-bottom: $border-normal $brand-color;
        box-shadow: 1px 1px 1px 0 $brand-color;
    }

    &.timeType {
        width: 18px;
        border-radius: 50%;
    }

    &:hover, &.hovered {
        font-weight: bold;
        @include divStyle(null, $border-normal $brand-color, null);
        box-shadow: 0 2px 0 -1px $brand-color, 0 -2px 0 -1px $brand-color, 2px 0 0 -1px $brand-color, -2px 0 0 -1px $brand-color,  0 0 1px 1px inset $brand-color;
    }

    .cal-dpicker & {
    text-align:  center;
}

    .cal-dpicker &.selected, .wgt-wrapper &.selected {
        font-weight: bold;
        @include divStyle($selected-day-dpicker, $border-normal $selected-day-dpicker, null);
        box-shadow: 0 2px 0 -1px $selected-day-dpicker, 0 -2px 0 -1px $selected-day-dpicker, 2px 0 0 -1px $selected-day-dpicker, -2px 0 0 -1px $selected-day-dpicker,  0 0 1px 1px inset $selected-day-dpicker;
    }
}

.prev:hover, .next:hover {
    @include divStyle(transparent, $border-invisible, $txt-brand);
    border-bottom: 2px solid $brand-color;
    font-weight: bold;
}

.num-day-selected {
    @include box-sizing(border-box);
    @include divStyle($yellow, $border-invisible, $txt-grey-0);
    @include text(null, 10px, 2, bold, center, null);
}

.num-day-empty {
    @include box-sizing(border-box);
    @include divStyle(transparent, $border-invisible, null);
    height: 0;
}

.scalendar-col-title {
    @include text($special-font, 14px, 2, null, center, $txt-white);
    display: block;
    width: 95%;
    margin: auto;
    text-transform: uppercase;
    background-color: $grey-2;
}

.scicles-col-title {
    @include text($special-font, 14px, 2, null, center, $txt-white);
    margin: auto;
    text-transform: uppercase;
    background-color: $grey-2;
}

.calendar-breadcrumb {
    @include divStyle(rgba(255, 244, 221, .8), $border-normal $grey-3, $txt-grey-0);
    @include text($special-font, 12px, null, null, null, null);
    @include ellipsis-text;
    display: block;
    position: sticky;
    align-self: flex-end;
    width: 90%;
    bottom: 0;
    padding: 5px;
}

// DATEPICKER CALENDAR

.calendar-sheet {
    position: absolute;
}
