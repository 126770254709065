/* COMBO */
/* .cb-button - estructura combo */
/* .cb-button.cb-active - estil combo amb llista oberta */
/* .cform-item-widget .cb-button -  variant de la combo quan es sobre un cform
    afegeix box-sizing per tant es treuen els marges */

.cb-button {
    //LLP: considerem que la mida de la combo sempre vindrà determinada pel seu 'parent'
    @include div-size(100%, 22px);
    @include divStyle($white, $border-normal $grey-3, null);
    position: absolute;
    margin-bottom: 5px;
    font-size: 12px;
    cursor: pointer;
    overflow: hidden;

    &:hover, &.cb-active, .mov-detail-row &:hover {
        border: $border-normal $brand-color;
    }
    .calendar-combo-box & {
        position: relative;
    }

    .cform-item-widget & {
        @include box-sizing(border-box);
        position: relative;
        margin-left: 0;
        margin-bottom: 0;
        min-width: 50px;
        text-overflow: ellipsis;
    }

    .fltr-list-container & {
        top: 1px;
        right: 0;
        margin: 2px;
        width: 24px;
        border: $border-invisible;
    }

    .fltrs-name-zone & {
        @include box-sizing(border-box);
        width: 100%;
        text-overflow: ellipsis;
    }

    &.disabled {
        @include divStyle(transparent, $border-normal $grey-3b, null);
        pointer-events: none;
    }

    .cal-name-zone &.disabled {
        @include divStyle(transparent, $border-normal $grey-3b, null);
    }

     .mov-detail-row & {
         @include divStyle($white, $border-normal $grey-3, null);
        width: 100% !important;

        .mov-detail-row &.disabled {
            @include divStyle($white, $border-invisible, null);
        }
    }

     .access-monitor-module  &,
     .divider.vert-left & {
        width: 75%;
    }

    .act-detail &.disabled, .ot-detail &.disabled, .acc-detail &.disabled,
    .mov-virtual-mark &.disabled, .mov-new-mark &.disabled,
    .mov-new-shift &.disabled, .mov-new-row &.disabled{
        background-color: transparent !important;
    }

    &.invalid {
        @include divStyle(#fce4e4, $border-normal $error-color, null);

        .input-multiname-wgt,
        .area-text-wgt {
            background-color: #fce4e4 !important;
        }
    }
}

.status-pending + .mov-detail-row .cb-button.disabled {
    background-color: $status-pending-bkgr !important;
}

.cb-list {
    @include divStyle($white, $border-normal $brand-color, null);
    box-shadow: 1px 1px 2px $grey-1;
    min-width: 70px;
}

.overlay-base {
    position: absolute;
    z-index: $layer-modal-background;
}

.overlay-backdrop {
    //not absolute, because must cover the whole screen
    @include position-all(fixed, 0, 0, 0, 0);
    background-color: transparent;
}

.overlay-backdrop-flex {
    @include div-size(100vw, 100vh);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $layer-modal-background;
}

.cb-text {
    @include div-size(80%, 22px);
    @include position-all(absolute, null, null, null, 0);
    @include text(null, null, 1.6, null, left, null);
    display: inline-block;
    margin: 0;
    padding: 0;
    //LLP ? No hauria de ser %, sinó que caldria restar la mida del triangle de la dreta...
    text-indent: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    //When combo text has a renderer on its left
    &.has-renderer {
        margin-left: 22px;
    }

    .win-view-combo-zone &.has-renderer {
        margin-left: 0;
    }

     .cb-button.disabled & {
         color: $grey-1;
     }

    .mov-deleted  .cb-button.disabled & {
        color: $mov-deleted !important;
    }

    .mov-not-effective-mark-row .cb-button.disabled &,
    .mov-error-mark-row .cb-button.disabled &{
        color: $mov-error !important;
    }
}

//Espai de formula dels filtres "No editable" + scroll horitzontal visible
.cb-filter {
    @extend .cb-text;
    @include div-size(272px, auto);
    @include divStyle($grey-4, null, $txt-grey-0);
    top: 0;
    text-overflow: inherit;
    white-space: nowrap;
    overflow-x: scroll;
}

//Estils dels operadors del calcul (and i or)
.cb-txt-and, .cb-txt-or {
    @include divStyle($grey-4, null, $txt-warning);
    @include text(null, 12px, null, bold, left, null);
    display: inline-block;
    padding-right: 5px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        background-color: $yellow;
    }
}

.cb-txt-or {
    color: $txt-brand;
}
