// ----------------------------------------------------
// Main definitions used in other *.scss files.
// IMPORTANT NOTE that this "_variables.scss" is not compiled, only it is imported from other *.scss files
// FROM TUTORIAL: https://scotch.io/tutorials/getting-started-with-sass
// ----------------------------------------------------

//Colors
$brand-color: rgb(0, 160, 155);
$qa-color: rgb(255, 102, 0);
$grey-0: rgb(90, 90, 90);
$grey-1: rgb(128, 128, 128);
$grey-2: rgb(192, 192, 192);
$grey-3: rgb(219, 219, 219);
$grey-3b: rgb(230, 230, 230);
$grey-4: rgb(241, 241, 241);
$grey-4b: rgb(246, 246, 246);
$grey-5: rgb(250, 250, 250);
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$error-color: rgb(255,0,0);
$info-color: rgb(0, 160, 155);
$warning-color: rgb(255, 102, 0);
$yellow: rgb(255, 235, 114); //hover
$dark-yellow: rgb(208,192,93); //hover btn-icon
$disabled-btn: rgb(210, 210, 210);
$tree-level-0-bkg: rgb(230, 230, 230);
$focus-btn: $grey-0;
$txt-brand: $brand-color;
$txt-white: $white;
$txt-black: $black;
$txt-info: $info-color;
$txt-error: $error-color;
$txt-warning: $warning-color;
$txt-grey-0: $grey-0;
$txt-grey-1: $grey-1;
$txt-grey-2: $grey-2;
$txt-grey-3: $grey-3;
$txt-grey-4: $grey-4;
$txt-grey-5: $grey-5;
$app-back-color: $grey-5;
$selected-color: $brand-color;
$selected-back-color: $grey-4;
$movs-hover-color: rgb(255, 235, 114);
$blue: rgb(51,153,255);
$selected-day-dpicker:  rgb(255,235,70); //only dpicker calendar and widget calendar.


// Filter editor Colors
$txt-field: rgb(117, 10, 10);
$txt-tempOp: rgb(69,129,180);

// Incidencias - Colors
$inc-green: rgb(0,153,51);
$inc-yellow: rgb(248,206,0);
$inc-blue: rgb(51,153,255);
$inc-orange: rgb(255,153,0);

//Tooltip
//$tltp-back-color: rgb(255, 255, 242);
$tltp-back-color: rgb(253, 245, 255);
$tltp-box-shadow: 0 1px 2px rgba(0,0,0,0.8);

//Movements color
$mov-label-color: rgb(138, 112, 67);
$acc-label-color: rgb(138, 112, 67);
$acc-mark-color: rgb(135, 91, 53);
$acc-refused-mark-color: $error-color;
$virtual-detail: rgba(111, 117, 128, 0.3);
$ot-detail: rgba(96, 148, 234, .3);
$act-detail: rgba(94, 37, 99, .2);
$acc-section:  rgba(138, 112, 67, .3);
$mov-new: rgba(252, 227, 138, .2);
$mov-new-mark: rgb(252, 227, 138 );
$mov-new-row:  rgba(87, 202, 133, .2);
$mov-virtual-mark: rgba(111, 117, 128, 0.3);
$mov-error: rgba(255, 0, 0, 0.5);
$mov-deleted: rgba(255, 102, 0, .5);
$pending-border: rgb(248,206,0);
$pending-bkgr: rgb(252, 247, 222);
$mov-graph-day-pending: rgba(248,206,0, .5); //When viaualizing the month days, if a day has petitions to resolve, this color is shown
$status-pending-border: 2px solid $pending-border; // Workflow border
$status-pending-bkgr: $pending-bkgr; // Workflow background

//Results buttons
$bkg-results-btn: rgba(96, 148, 234, .15);
$b-results-btn: rgba(96, 148, 234, .15);
$txt-results-btn: rgb(96, 148, 234);
$bkg-results-hover-btn: $yellow;
$b-results-hover-btn:  rgba(255, 102, 0, .2);
$txt-results-hover-btn: rgba(255, 102, 0, .7);


//Error-Page color
$error-page-color: #D5354E;

//Movements box-shadow
$mov-detail-open-shadow: 0 0 0 1px rgba(0, 160, 155, .8) inset;
$mov-row-shadow: 0 0 1px 1px inset;

//Fonts
//$base-font: "Roboto", sans-serif;
$base-font: 'Roboto-Light';
$base-strong-font: 'Roboto-Regular';
$special-font: "OpenSans-Regular";

//Variables consulted by JS
$grid-row-selected-color: rgb(255,235,70); //Grid's row selected color
$grid-row-hover-color: rgba(255,235,114, .7);  //Grid's row selected;hover color
$grid-row-hover-selected-color: rgba(255,235,114, .7);  //Grid's row selected;hover color
$grid-row-hover-selected-hover-color: rgba(255,235,100, .8);  //Grid's row selected;hover color
$win-accordion-width: 144px;  //Width value to preserve space for accordion buttons inside a CWin
$win-bottom-bar-height: 35px; //Height value to preserve height for bottom bar inside a CWin
$cform-topbar-height: 68px;
$grid-tree-tab-width: 24px; //Distance of every indentation in Grid rendered as a tree

//Movements structure
$mov-day-width: 62px;//day width (column). MUST BE IN SYNC WITH CTMOV.WIDTH_COL_DAY
$mov-day: 25px; //default day height (row) when only tt is visible
$mov-day-extra-1: 35px; //day-heihgt where either "wa" OR "wo" is visible + timeTypes
$mov-day-extra-2: 45px; //day-heihgt where either "wa" AND "wo" are visible (+ timeTypes)

$mov-day-shift: 18px; //default day shift box height (row) when only tt is visible
$mov-day-shift-extra-1: 30px; //day shift box where either "wa" OR "wo" is visible + timeTypes
$mov-day-shift-extra-2: 40px; //day shift box where either "wa" AND "wo" are visible (+ timeTypes)

$mov-day-stripes-extra-1: 36px; //stripes box where either "wa" OR "wo" is visible + timeTypes
$mov-day-stripes-extra-2: 48px; //stripes box where "wa" AND "wo" are visible (+ timeTypes)

//Mides consultades entre estils
$win-top-border-height: 25px;

//Variables used in animations
$fade-in-time: 0.3s;
$button-retard: 0.5s; //retard botons - suavitza l'acció del botó //
$radio-btn-trans: all 270ms ease; //retard radio-button - suavitza l'acció del botó //

//Estructures
$border-normal: 1px solid; //botons i altres elements que necessiten border - cal aplicar color//
$border-invisible: 1px solid transparent; //elements que necessiten border pero no cal veure//

//Posició Modals
$modal-win-box-450: -75px 0 0 -250px; // per width=450px margin values //

//Z-index (layers)
$layer-zero: 0;
$layer-column-btn: 1;  //For grid column selector button to appear over cell handle
$layer-win: 10;
$header-sticky: 10;
$layer-menu: 20;
$layer-bar-loader: 25;
$layer-modal-background: 30;
$layer-modal-box: 40;
$layer-tooltip: 50;
$layer-dropdown-import: 100;

// Loader
$loader-bg-color: $white;
$layer-loader: 7777;


//////////////////////////////////////////
//
// CHARTIST-SETTINGS (chartist.scss)
//
//////////////////////////////////////////

// Scales for responsive SVG containers
$ct-scales: ((1), (15/16), (8/9), (5/6), (4/5), (3/4), (2/3), (5/8), (1/1.618), (3/5), (9/16), (8/15), (1/2), (2/5), (3/8), (1/3), (1/4)) !default;
$ct-scales-names: (ct-square, ct-minor-second, ct-major-second, ct-minor-third, ct-major-third, ct-perfect-fourth, ct-perfect-fifth, ct-minor-sixth, ct-golden-section, ct-major-sixth, ct-minor-seventh, ct-major-seventh, ct-octave, ct-major-tenth, ct-major-eleventh, ct-major-twelfth, ct-double-octave) !default;

// Class names to be used when generating CSS
$ct-class-chart: ct-chart !default;
$ct-class-chart-line: ct-chart-line !default;
$ct-class-chart-bar: ct-chart-bar !default;
$ct-class-horizontal-bars: ct-horizontal-bars !default;
$ct-class-chart-pie: ct-chart-pie !default;
$ct-class-chart-donut: ct-chart-donut !default;
$ct-class-label: ct-label !default;
$ct-class-series: ct-series !default;
$ct-class-line: ct-line !default;
$ct-class-point: ct-point !default;
$ct-class-area: ct-area !default;
$ct-class-bar: ct-bar !default;
$ct-class-slice-pie: ct-slice-pie !default;
$ct-class-slice-donut: ct-slice-donut !default;
$ct-class-slice-donut-solid: ct-slice-donut-solid !default;
$ct-class-grid: ct-grid !default;
$ct-class-grid-background: ct-grid-background !default;
$ct-class-vertical: ct-vertical !default;
$ct-class-horizontal: ct-horizontal !default;
$ct-class-start: ct-start !default;
$ct-class-end: ct-end !default;

// Container ratio
$ct-container-ratio: (1/1.618) !default;

// Text styles for labels
$ct-text-color: rgba(0, 0, 0, 0.4) !default;
$ct-text-size: 0.75rem !default;
$ct-text-align: flex-start !default;
$ct-text-justify: flex-start !default;
$ct-text-line-height: 1;

// Grid styles
$ct-grid-color: rgba(0, 0, 0, 0.2) !default;
$ct-grid-dasharray: 2px !default;
$ct-grid-width: 1px !default;
$ct-grid-background-fill: none !default;

// Line chart properties
$ct-line-width: 2px !default;
$ct-line-dasharray: false !default;
$ct-point-size: 10px !default;
// Line chart point, can be either round or square
$ct-point-shape: round !default;
// Area fill transparency between 0 and 1
$ct-area-opacity: 0.1 !default;

// Bar chart bar width
$ct-bar-width: 10px !default;

// Donut width (If donut width is to big it can cause issues where the shape gets distorted)
$ct-donut-width: 60px !default;

// If set to true it will include the default classes and generate CSS output. If you're planning to use the mixins you
// should set this property to false
$ct-include-classes: true !default;

// If this is set to true the CSS will contain colored series. You can extend or change the color with the
// properties below
$ct-include-colored-series: $ct-include-classes !default;

// If set to true this will include all responsive container variations using the scales defined at the top of the script
$ct-include-alternative-responsive-containers: $ct-include-classes !default;

// Series names and colors. This can be extended or customized as desired. Just add more series and colors.
$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o) !default;
$ct-series-colors: (
    #d70206,
    #f05b4f,
    #f4c63d,
    #d17905,
    #453d3f,
    #59922b,
    #0544d3,
    #6b0392,
    #f05b4f,
    #dda458,
    #eacf7d,
    #86797d,
    #b2c326,
    #6188e2,
    #a748ca
) !default;
