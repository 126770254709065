////////////////////////////
//
//  MAIN window Styles
//
////////////////////////////

/* Applied to BODY */
.main-body-back {
    @include div-size(auto, auto);
    display: flex;
    background-color: $app-back-color;
}

.app {
    @include position-all(fixed, 0, 0, 0, 0);
}

/* Applied to CMain component, that contains main area used to contain everything: windows, menu, etc.*/
.cmain {
    @include position-all(fixed, 0, 0, 0, 0);
}

.main-img {
    @include position-all(fixed, 50%, null, null, 50%);
    transform: translate(-50%, -50%);
}

/* MAIN opcions estàtiques - superior dreta */

.main-fixed-options {
    @include position-all(fixed, 5px, 5px, null, null);
    text-align: right;
    display: block;
    width: auto;
    border: $border-invisible;
    z-index: $layer-menu;
}

/* Posició de data i hora */
.main-date-time {
    @include margin(5px, 10px, 0, 10px);
    @include text($special-font, .8em, null, null, left, $txt-grey-1);
    display: inline-block;
    width: auto;
}

////////////////////////////
//
//  GESTOR DE FINESTRES - LLISTA Styles
//
////////////////////////////

/* Posició BOTÓ gestor finestres */
.main-btn-windows {
    margin-right: 100px;
}

/* Etiqueta nombre de finestres obertes (minimitzades o no) */
.main-badges-windows {
    @include div-size(30px, 20px);
    @include divStyle($selected-color, null, $txt-white);
    display: inline-block;
    text-align: center;
    border-radius: 20px 0 0 20px;
    margin-right: -4px;
    font-weight: bold;
}

/*  Sitúa el menú de finestres sobre el botó */
.g-win-menu {
    @include div-size(auto, 22px);
    position: relative;
    display: flex;
    overflow: hidden;
}

.btn-g-win-menu {
    @include divStyle(transparent, none, $txt-white);
    cursor: pointer;
}

.g-win-panel {
    position: relative;
    display: inline-block;
    margin-right: 15px;

    &:hover .g-win-panel-content {
        display: block; // el panell i el contingut es mostren amb el hover
    }

    &:hover .btn-g-win-menu {
        background-color: $white;
    }

    .s-col & {
        display: inline-flex;
        margin-right:  2px;
    }
}

.g-win-panel-content {
    @include divStyle( #f9f9f9, null, $txt-grey-0);
    position: absolute;
    margin-left: -30px; //Posició de la llista vers el botó "en negatiu el marge left"
    width: 200px; // revisar l'amplada, si el text es molt llarg no se si es veurà be
    box-shadow: 1px 2px 2px $grey-1;

    &:hover {
        background-color: #f1f1f1
    }
}

.g-win-panel-content1 {
    @include divStyle($white, $border-normal $brand-color, $grey-0);
    position: absolute;
    box-shadow: 1px 2px 2px $grey-1;

    .g-win-panel-content1:hover {
        background-color: $white;
    }
}

/*  Correspon al nom de cada finestra minimitzada */
.g-win-item {
    @include divStyle(null, $border-normal $grey-3, $txt-black);
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 2px;
    text-align: left;
    font-size: 12px;
    border-collapse: collapse;

    .filter-list-container & {
        background-color: transparent;
        border: $border-invisible;
        border-top: $border-normal $grey-3;
        padding: 2px 5px;

        .g-win-txt {
            @include ellipsis-text;
            display: inline-block;
            margin-left: 10px;
            line-height:  1;
            width: 70%;
        }

        .filter-list-container &:hover {
            @include divStyle($yellow, $border-normal $brand-color, $txt-brand);
        }
    }

    .mod-access-cont-stripes-list &,
    .scalendar-col-title &,
    .scicles-col-title & {
        font-size: 12px;
        border: $border-invisible;
        padding: 2px;
        cursor: pointer;
    }

    .devices-activation & {
        font-size: 12px;
        border: $border-invisible;
        padding: 0;
        line-height: 19px;
    }

    &:hover, .scalendar-col-title &:hover,
    .scicles-col-title &:hover, .devices-activation &:hover,
    .cform-item-widget .generic-list  &:hover {
        @include divStyle($white, $border-normal $brand-color, $txt-brand);
    }

    .fltr-col &,
    .fltr-list-container,
    .fltr-list-op-container,
    .calendar-info-list &,
    .cform-item-widget .generic-list  & {
        border: $border-invisible;
    }

    .calendar-info-list &:hover,
    .win-mini-container &:hover {
        @include divStyle(transparent, $border-normal $grey-3, $txt-black);
    }


    .win-slot-cform &:hover {
            background-color: rgba(255, 235, 114, .5);
        }
}

.g-win-item-search {
    @include margin(5px, auto, auto, auto);
    @include div-size(97%, null);
    position: relative;
    display: block;
    padding: 5px;
}

.g-win-txt {
    display: inline-block;
    margin-left: 10px;
    line-height:  20px;
}

//Dades de l'usuari logat
.main-user {
    @include position-all(fixed, 40px, 55px, null, null);
    @include divStyle(null, none, $txt-grey-1);
    float: right;
    display: inline-block;
    font-size: 12px;
    text-align: right;
    font-weight: bold;
    width: auto;
    font-family: $special-font;
}

// MAIN opcions estàtiques - inferior dreta

.main-fixed-data-footer {
    @include div-size(auto, auto);
    @include position-all(absolute, null, 5px, 0, null);
    text-align: right;
    padding: 5px;
    font-size: 10px;

    //RAG --> QA Styles
    &.qa {
        background-color: $qa-color !important;
    }
}

.main-data-footer {
    @include divStyle(null, none, $txt-grey-1);
    display: block;
    width: auto;
    line-height: 2em;

    //RAG --> QA Styles
    &.qa {
        color: $txt-white !important;
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY , USED TO VISUALIZE TESTS
///////////////////////////////////////////////////////////////////////////////////////////////////
// DEBUG: a div to position components to be tested
.main-test-box {
    @include div-size(1000px, 700px);
    @include position-all(absolute, 200px, null, null, 100px);
    background-color: white;
    border: 1px solid #cccccc;
}

.main-test-console {
    @include position-all(absolute, 10px, null, null, 100px);
    @include div-size(1000px, 50px);
    @include divStyle($white, null, $txt-grey-1);
}

.main-test-title {
    @include div-size(1000px, 50px);
    @include position-all(absolute, 50px, null, null, 100px);
    @include divStyle($white, null, $txt-grey-1);
}

.main-test-status {
    @include position-all(absolute, 80px, null, null, 100px);
    @include div-size(1000px, 110px);
    @include divStyle($white, null, $txt-brand);
    font-weight: bold;
    overflow-y: auto;
    border: 1px solid #cccccc;
}

///////////////////////////////////////////////////////////////////////////////////////////////////

