////////////////////////////
//
//  TREE Styles
//
////////////////////////////

.tree-dimensions {
    width: $grid-tree-tab-width;
}

// Level 0 - titol - capçalera desplegable
.tree-level-0 {
    @include ellipsis-text;
    @include text($special-font, 12px, null, bold, null, $txt-brand);
    display: block;
    width: 100%;
    cursor: pointer;

    .selected & {
        background-color:  transparent;
    }

    //RAG - in case the tree grid does not have a topbar (ex. access viewer).
    &:not(.topbar &) {
        @include text($base-font, 12px, null, normal, null, $txt-black);
    }
}

// Ítem seleccionable sense arrow ni check
.tree-item-select {
    @extend .tree-level-0;
    @include divStyle(transparent, null, $txt-grey-0);
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
    text-indent: 10px;
}

// Arrow - Posició del item seleccionable (no títol) que precedeix la fletxa (.btn-tree)
.tree-level-arrow {
    @extend .tree-level-0;
    @include divStyle(transparent, null, $txt-grey-0);
    font-size: 12px;
    font-weight: normal;
}

// Check - Posició del item seleccionable que precedeix el check (.small-check)
.tree-level-check {
    @extend .tree-level-arrow;
    display: inline-block;
    text-indent: 10px;
    background-color: transparent;
}

//Special style used in special configured tree for enroll devices
.bk-color-tree-level-0 {
}


////////////////////////////////////////////////////////////////////////////////////////////
//
//  SHIFT TREE styles (.shift-wrapper-treeview)
//
///////////////////////////////////////////////////////////////////////////////////////////

.treeview, .treeview ul {
    @include divStyle($grey-5, null, $txt-grey-0);
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 12px;
    line-height: 25px;
}

.treeview ul {
    margin-left: 5px;
    position: relative;
}

.treeview ul ul {
    margin-left: .5em;
}

.treeview ul:before {
    @include position-all(absolute, 0, null, 15px, 0);
    content: "";
    display: block;
    width: 0;
    border-left: $border-normal $grey-1;
    // creates a more theme-ready standard for the bootstrap themes
}

.treeview li {
    margin: 0;
    line-height: 25px;
    position: relative;
}

.treeview ul li {
    &:before {
        @include div-size(10px, 0);
        @include position-all(absolute, 1em, null, null, 0);
        content: "";
        display: block;
        border-top: $border-normal $grey-1;
        margin-top: -2px;
    }
    &.unlocked:before {
        @include div-size(10px, 0);
        @include position-all(absolute, 1em, null, null, 0);
        content: "";
        display: block;
        border-top: $border-invisible;
        margin-top: -2px;
    }
}

.treeview li a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.treeview li button,
.treeview li button:active,
.treeview li button:focus {
    text-decoration: none;
    color: inherit;
    border: none;
    background: transparent;
    margin: 0 0 5px 0;
    padding: 0 10px 0 0;
    outline: 0;
}
