////////////////////////////
//
//  MOVEMENTS  Styles
//
////////////////////////////

//Style that allows scroll horizontal when window width is less than "mov-month" width
.mov-month-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
}

.mov-main-area {
    @include position-all(absolute, 70px, 0, 0, 0);
}

.mov-month {
    @include div-size(100%, 100%);
    min-width: 680px; //IMPORTANT: this is the minimal width for the month to allow visualizing days & marks properly
    position: absolute;
}

.mov-days {
    position: absolute;
    top: 40px;
    background-color: $white;
    bottom: 0;
    overflow-y: scroll;
    width: 100%;
}
.mov-day {
    @include div-size(100%, $mov-day);
    position: relative;
    display: flex;

    //day-height where either "wa" OR "wo" is visible (+ timeTypes)
    &.extra-1 {
        height: $mov-day-extra-1;
    }

    //day-height where either "wa" AND "wo" are visible (+ timeTypes)
    &.extra-2 {
        height: $mov-day-extra-2;
    }
}

.mov-graph-col {
    display: inline-block;
    &.mov-detail-open {
        border: 1px solid $brand-color;
    }
}

.mov-day-shift {
    @include divStyle($white, null, $txt-grey-0);
    position: absolute;
    height: 100%;
    top: 1px;
    left: 15px; //Centered
    bottom: 1px;
    margin: auto;
    width: 26px;
    min-height: 22px;
    text-align: center;
    font-weight: bold;
    font-family: $special-font;
    font-size: .8em;
    line-height: 1.7;

    &:hover {
        box-shadow: 2px 1px 4px  $grey-0;
        cursor: pointer;
    }

    &.highlighted {
        @include divStyle($brand-color, null,  $txt-white);
        height: 100%;
    }

    &.shifted-left {
        left: 2px;
    }
}
// Marc del dia (el color es posa per codi)
[class*='DAY_COLOR_'] {
    border-style: solid;
    border-width: 1px 1px 1px 7px;
    height: 100%;
}

//Quan hi ha missatge es posen les icones corresponents.
.msg-icon-day-col {
    //---LLP Required to see the icons in new layout
    position: relative;
    left: 30px;
    top: 0;
    //----
    width: 22px;

    //Quan hi ha dos tipus de missatges les icones son petites.
    &.mini {
        width: 14px;
    }
}

.mov-day-stripes, .acc-mov-day-stripes {
    position: relative;
    height: 16px;
    border-left: $border-normal $grey-3b;
    border-top: $border-normal $grey-3b;
    border-bottom: $border-normal $grey-3b;
    //border-right: DO NOT SPECIFY => it will be set by the last of 24 mov-hour-lines

    //stripes box where either "wa" OR "wo" is visible + timeTypes
    &.extra-1 {
        height: $mov-day-stripes-extra-1;
    }

    //stripes box where "wa" AND "wo" are visible (+ timeTypes)
    &.extra-2 {
        height: $mov-day-stripes-extra-2;
    }

    //When viaualizing the month days, if a day has petitions to resolve, this color is shown
    &.pending {
        background-color: $mov-graph-day-pending;
    }
}

.acc-mov-day-stripes {
    height: 12px;
}

.mov-hour-lines {
    position: relative;
    display: inline-block;
    border-right: .01em solid $grey-3;
    opacity: .7;
    pointer-events: none;
    background-color: transparent;
    height: 16px;

    //hour-lines where either "wa" OR "wo" is visible + timeTypes
    &.extra-1 {
        height: $mov-day-stripes-extra-1;
    }

    //hour-lines where "wa" AND "wo" are visible (+ timeTypes)
    &.extra-2 {
        height: $mov-day-stripes-extra-2;
    }
}
.mov-shift-bg, .mov-shift-bg-error {
    @include position-all(absolute, 0, null, null, null);
    height: 16px;
    background-color:  rgba(230, 230, 230, .7);

    &:hover {
        @include divStyle(null, $border-normal $selected-color, null);
        cursor: pointer;
        animation: blinking 1.5s linear infinite;
    }
}

.mov-shift-bg-error {
    background-color:  rgba(190, 22, 34, .15);
}

.mov-shift-highlighted {
    @include position-all(absolute, 0, null, null, null);
    @include divStyle(rgba(0, 160, 155, .15), $border-invisible, null);
    height: 100%;
    pointer-events: none; //LLP: to allow sowing toolip for underlying shift

    &.block-start {
        @include divStyle(transparent, 2px solid $brand-color, null);
        border-right: none;
        width: 20px;
    }

    &.block-end {
        @include divStyle(transparent, 2px solid $brand-color, null);
        border-left: none;
        width: 20px;
    }
}

.mov-shift-end {
    @include position-all(absolute, -1px, null, null, null);
    cursor: pointer;
}

//Used by CMovDay to render ranges
.mov-range {
    border: $border-normal rgba(0, 0, 0, 0.2);
    position: absolute;
    box-shadow: 1px 1px 2px $grey-2;

    //applied to all ranges of type "horario"
    &.tt {
        height: 8px;
        top: 4px;
    }

    &.planif  {
        height: 4px;
        top: 12px;
        border: none;

        &.planif.request {
            color: $white;
            background-image: linear-gradient(-45deg,
                transparent,
                transparent 25%,
                currentColor 25%,
                currentColor 50%,
                transparent 50%,
                transparent 75%,
                currentColor 75%);
            background-size: 7px 7px;
        }
    }


    &.calc, &.facc {
        height: 4px;
        top: 0;
    }

    &.facc {
        height: 4px;
        top: 0;
        background-color: rgba(138, 112, 67, .7) !important;
    }

    &.access {
        top: 12px;
        height: 4px;
        background-color: rgb(138, 112, 67) !important;
    }

    //applied to all ranges of type "Actividad" or "OT" located at 1st row under "horario"
    &.row-1 {
        height: 4px;
        top: 24px;
    }

    &.row-1.planif {
        height: 2px;
        top: 28px;
    }

    //applied to all ranges of type "Actividad" or "OT" located at 2d row under "horario"
    &.row-2 {
        height: 4px;
        top: 38px;
    }

    &.row-2.planif {
        height: 2px;
        top: 42px;
    }

    &:hover {
        border: $border-normal  $grey-0;
        box-shadow: 2px 1px 4px  $grey-0;
        animation: blinking 1.5s linear infinite;
        cursor: pointer;

        &:hover.planif,  &:hover.request{
            outline: $border-normal $grey-0;
            border: none;
            box-shadow: 2px 2px 4px  $grey-0;
            cursor: pointer;
            animation: blinking 1.5s linear infinite;
        }
    }

    &.aprox {
        top: 0;
        @include div-size(3px, 6px) ;
        background-color: transparent;
        border-top: 2px solid rgba(255, 255, 255, .7);
        border-bottom: 2px solid rgba(255, 255, 255, .7);
        border-left: 2px transparent;
        border-right:  2px transparent;

        &.left {
            left: -1px;
        }

        &.right {
            right: -1px;
        }
    }
}

.acc-section {
    background-color: $acc-section!important;
}

.mov-mark {
    width: 4px;
    position: absolute;
    border: $border-normal rgba(0, 0, 0, 0.2);

    //applied to all ranges of type "horario"
    &.tt {
        height: 16px;
        top: 0;
    }

    //applied to all ranges of type "Actividad" or "OT" located at 1st row under "horario"
    &.row-1 {
        height: 8px;
        top: 18px;
    }

    //applied to all ranges of type "Actividad" or "OT" located at 2d row under "horario"
    &.row-2 {
        height: 8px;
        top: 32px;
    }

    &:hover {
        border: $border-normal  $grey-0;
        box-shadow: 2px 1px 4px  $grey-0;
        cursor: pointer;
        animation: blinking 1.5s linear infinite;
    }
}

.acc-mark {
    width: 1px;
    position: absolute;
    border: $border-normal $acc-mark-color;

    &.refused {
        border: $border-normal $acc-refused-mark-color;
    }

    //applied to all ranges of type "acceso"
    &.tt {
        height: 10px;
        top: -1px;
    }

    &:hover {
        border: $border-normal $grey-0;
        box-shadow: 2px 1px 4px $grey-0;
        cursor: pointer;
        animation: blinking 1.5s linear infinite;
    }
}


///////////////////////////////////
/////
///// CDayDetail, CMark
//////
/////////////////////////////////
.mov-detail-row {
    @include margin(null, 4px, null, 4px);
    border-bottom: $border-normal $grey-3;

    &.btn-wrap {
        @include margin(5px, null, 5px, null);
        @include divStyle($white, $border-invisible, null);
    }

    &.status-pending  {
        @include padding(2px, null, 4px, null);
        border-top: $status-pending-border;
        border-bottom: none;
        background-color:$status-pending-bkgr;
        margin-bottom: 0;
    }

    //Variant de "mov-detail-row" quan l'immediat superior es "status-pending".
    .status-pending + .mov-detail-row {
        border-bottom: $status-pending-border;
        background-color:$status-pending-bkgr;
    }

    &.activators-block {
        //height: 120px;
        overflow-x: hidden;
    }
}

.activators-grid-position {
    position:relative;
    width: 400px;
    height:100px;
    border-left: $border-normal $grey-3;
    border-right: $border-normal $grey-3;
    margin:4px;
    padding:4px;

    .activators-add-btn-position {
        position:relative;
        left: 410px;
        top: 65px;
    }
}

.mov-anomaly-block {
    @include margin(null, 4px, null, 4px);
    line-height: 24px;
    border: $border-normal $error-color;
}

//Variants de rows possibles
.virtual-detail {
    background-color: $virtual-detail;
}

.ot-detail {
    background-color: $ot-detail;
}

.act-detail {
    background-color: $act-detail;
}

.acc-detail {
    background-color: $acc-section;
}

.mov-new-mark, .mov-new-shift {
    @include padding(4px, 4px, 4px, 4px);
    margin-top: 5px;
}

.mov-new-row {
    background-color: $mov-new-row;
}

.mov-virtual-mark {
    background-color: $mov-virtual-mark;
}

//Disparador del "Mode Esborrats" (.deleted). Quan es detecta hi ha canvis d'estil als components.
.mov-deleted { }

//Disparador del "Mode Marcatges NO EFECTIUS". Quan es detecta hi ha canvis d'estil als components.
.mov-not-effective-mark-row { }

//Disparador del "Mode Marcatges amb ERROR". Quan es detecta hi ha canvis d'estil als components.
.mov-error-mark-row { }

.mov-text {
    color: $txt-grey-0;
    font-family: $base-font;
    font-size: 12px;

    .status-pending & {
        @include divStyle($pending-bkgr, null, $mov-label-color);
        @include padding(2px, 5px, 2px, 5px);
    }
}

.mov-label, .acc-mov-label {
    @include ellipsis-text;
    @include divStyle(null, $border-invisible, $mov-label-color);
    padding: 2px;
    text-align: right;
    font-size: 11px;
    font-family: $special-font;
    text-transform: uppercase;

    &.error {
        color: $txt-error;
        text-align: left;
    }

    &.txt-left {
        text-align: left;
    }

    &.shift-box {
        margin: 0;
        text-transform: none;
        font-weight: normal;
        font-size: 12px;
    }
}

.acc-mov-label {
    @include ellipsis-text;
    @include divStyle(null, $border-invisible, $mov-label-color);
    text-align: left;
    font-size: 11px;
    font-family: $special-font;
    text-transform: none;
    padding-top: 0;
    margin-top: -2px;

    .acc-detail & {
        line-height: 2;
    }
}


.shift-color {
    display: inline-block;
    @include div-size(14px, 14px);
    @include margin(3px, 4px, 0, 4px);

    &.workflow {
        @include margin(0, 4px, null, null);
    }
}

//Component CStartEnd (triangle : inico / quad : fin )
.mov-start-end {
    @include div-size(24px, 22px);
    @include padding(1px, null, null, 1px);
    position: relative;
    display: block;
    margin: auto;
    overflow: hidden;

    //RAG - quan està a la row "mov-new-mark" te estil diferent. Hover com els botons.
    .mov-new-mark & {
        @include divStyle($white, $border-normal $grey-3, null);
        margin-right: 2px;

        .mov-new-mark &:hover {
            @include divStyle($yellow, $border-normal $brand-color, null);
        }
    }
}

//////////////////////////////////
//
// COMENTARIOS - MOVEMENTS - Styles
//
//////////////////////////////////

.mov-msg-txt {
    color: $txt-grey-0;
    text-align: left;
    font-size: 12px;
    font-family: $special-font;
    overflow: hidden;
    margin: auto;
    padding: 5px;

    &.title {
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 2px;
        border-bottom: $border-normal $grey-1;
    }
}

.mov-msg-row {
    margin-bottom: 2px;
    min-height:  26px;

    //RAG -- rows alternes amb diferent background.
    &:nth-child(even) {
        background-color: $grey-4;
    }
}

.mov-panel-mark-corrector {
    @include div-size(250px, 100px);
    @include divStyle($white, $border-normal orange, null);
    position: relative;
    box-shadow: 1px 1px 2px grey;
    padding: 4px;
    font-family: $special-font;
}

.mov-panel-txt {
    font-family: $special-font;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: $grey-1;
    text-align: center;
    margin: auto;

    &.pending {
        color: $txt-brand;
        text-transform: none;
    }
}

.result-date-header {
    font-weight: bold;
    width: 100%;
    text-align: center;
    @include ellipsis-text;

    &.modified-results {
        color: $warning-color;
    }
}

.result-date-header-background {
    background: $grey-3;

    &.modified-results {
        background: $grey-4;
    }
}



////////////////////////////////////////////////////////// LLP: ADDED STYLES FOR NEW LAYOUT//////////////////////////////////////////////
.mov-root-day {
    position: relative;
    display: block;
    width: 100%;
    margin: 1px;
    min-height: 22px;
}

.mov-cont-day-box {
    display: inline-block;
    width: $mov-day-width;
}

.mov-msg-day {
    position: absolute;
    top: 0;
    display: inline-block;
    width: $mov-day-width;
    height: 100%;
}

.mov-ruler {
    position: absolute;
    top: 0;
    left: $mov-day-width;
    height: 30px;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $grey-1;
}

.mov-hour-lines-ruler {
    position: relative;
    display: inline-block;
    border-left: .01em solid $grey-0;

    pointer-events: none;
    background-color: transparent;
    height: 8px;
    top: 20px;

    //When a line is 'escaped' (it doesn't have a label), height is minimal
    &.escaped {
        height: 4px;
        top: 17px;
    }

}

.mov-text-ruler {
   position: relative;
   top: -15px;
   left: -10px;
    //IMPORTANT: if font size is changed, then CRuler.LABELS_WIDTH constant requires a re-mesasurement!
   font-size: .8em;
   color: $txt-grey-1;
   font-family:  $special-font;
}
