////////////////////////////
//
//  CFORM Styles
//
////////////////////////////

.cform-root {
    @include position-all(absolute, 0, 0, 0, 0);
}

.cform-topbar {
    @include position-all(absolute, $win-top-border-height, null, null, null);
    @include div-size(100%, 35px);
    background-color: $grey-4;
    box-shadow: 0 2px 8px -1px rgba(0,0,0,0.30);
}

.topbar-filters {
    position: relative;
    display: block;
}

.cform-acc-container {
    @include position-all(absolute, 40px, null, null, null);
    @include div-size(100%, 100%);
}

// Position of "cform-group" (root of group) must be 'relative' (one group goes after the others)
//  Internal elements inside "cform-group" must be positioned 'absolute' (relative to this element)
.cform-group {
    margin-top: 5px;
    padding: 4px;
}

.cform-group-open {
    float: left;

    .modal-wrapper & {
        margin-top: 5px;
    }
}

.cform-group-label {
    @include text($special-font, 12px, null, bold, null, $txt-brand);
    margin-left: 0;
    padding: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-indent: 5px;
    background-color: $grey-3b;

    // cform en mode 'vista' canvi color lletra
    .cform-view & {
        color: $txt-grey-2;
        background-color: $grey-4;
    }

    .modal-wrapper & {
        @include margin(5px, null, 10px, null);
        text-indent: 0;
    }
}

.cform-group-children {
    top: 30px;
    margin-top: 10px;
}

.cform-row {
    //width: 100%; IF 100%, ==> problems with horizontal scroll of cform
    @include box-sizing(border-box);
    padding: 5px;


    // cform en mode 'vista' hi ha opacitat
    .cform-view & {
        opacity: 0.7;
    }
}

.border-left {
    border-left: $border-normal rgb(230, 230, 230);
}

// ETIQUETA CFORM - cform-item-label
// 'endline' is applied to labels & widgets to establish that the label & widget must occupy all
// the horizontal space (Ex: IncidenciaFutura.xml, widget calendar)
// 'modified' - s'aplica a les etiquetes dels widgets que han estat modificats
.cform-item-label, .cform-col-label {
    @include text($special-font, 12px, null, null, right, $txt-grey-0);
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    overflow-wrap: break-word;

    &.endline {
        display: block;
        text-align: left;
    }

    &.txt-left {
        text-align: left;
    }

    &.height-btn-mini { //LLP: used in CWgtFilterButton, to have a vertical label aligned with btn-mini buttons
        @include margin(0, 5px, 0, 10px) ;
        display: inline-block;
        height: 24px;
        line-height: 12px;
        font-size: 12px;
        padding: 5px;
    }

    .icontainer & {
        @include text(null, null, 2em, null, left, null);
        display: inline;
    }

    &.disabled {
        opacity: 0.4;
    }
}

.cform-col-label {
    text-align: center;
    text-transform: uppercase;
}

.cform-item-widget {
    position: relative;
    display: inline-block;
    width: 100%; //LLP: per aconseguir que la combo al 100% quedi inscrita dins l'espai del widget
    margin: 0;
    vertical-align: middle;
    background-color: transparent;

    &.endline {
        background-color: transparent;
        margin: 0 !important; // Important is required to clear the default "margin-left: 1.6%;" introduced by 'column + .column'
    }
}

//FROM:  https://www.sitepoint.com/understanding-css-grid-systems/
.column-1 {
    width: 6.86666666667%;
}

.column-2 {
    width: 15.3333333333%;
}

.column-3 {
    width: 23.8%;
}

.column-4 {
    width: 32.2666666667%;
}

.column-5 {
    width: 40.7333333333%;
}

.column-6 {
    width: 49.2%;
}

.column-7 {
    width: 57.6666666667%;
}

.column-8 {
    width: 66.1333333333%;
}

.column-9 {
    width: 74.6%;
}

.column-10 {
    width: 83.0666666667%;
}

.column-11 {
    width: 91.5333333333%;
}

.column-12 {
    width: 100%;
}

.column {
    @include box-sizing(border-box);
}

.column + .column {
    margin-left: 1.6%;
}
