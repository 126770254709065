////////////////////////////
//
//  CWin FRAME Styles
//
////////////////////////////

.win-base {
    position: absolute;
    background-color: $grey-5;
    border-width: 2px 4px 4px 4px;
    border-style: solid;
    border-color: $brand-color;
    border-radius: 2px;
    z-index: $layer-win;

    // JDS 21/10/2020 minimal size to avoid "windows like points"
    min-width: 300px;
    min-height: 35px;

    &.disabled {
        border-color: $grey-2 !important;
        z-index: $layer-zero;
    }

    //RAG --> QA styles
    &.qa {
        border-color: $qa-color ;
    }
}

.win-internal {
    @include div-size(100%, 100%);
    position: absolute;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.win-right-handle {
    @include position-all(absolute, 0, -3px, null, null);
    @include div-size(6px, 100%);
    cursor: ew-resize;
    z-index: $layer-win;
}

.win-left-handle {
    @include position-all(absolute, 0, null, null,-3px);
    @include div-size(6px, 100%);
    cursor: ew-resize;
    z-index: $layer-win;
}

.win-top-handle {
    @include position-all(absolute, -3px, null, null, 0);
    @include div-size(100%, 6px);
    cursor: ns-resize;
    z-index: $layer-win;
}

.win-top-left-handle {
    @include position-all(absolute, -6px, null, null,-6px);
    @include div-size(12px, 12px);
    cursor: nwse-resize;
    z-index: $layer-win;
}

.win-bottom-handle {
    @include position-all(absolute, null, null, -3px, 0);
    @include div-size(100%, 6px);
    cursor: ns-resize;
    z-index: $layer-win;
}

.win-bottom-right-handle {
    @include position-all(absolute, null, -6px,-6px, null);
    @include div-size(12px, 12px);
    cursor: nwse-resize;
    z-index: $layer-win;
}

.win-title {
    @include position-all(absolute, 0, null, null, 0);
    @include div-size(100%, $win-top-border-height);
    @include divStyle($brand-color, null, $txt-white);
    margin: 0;
    padding-left: 5px;
    display: inline-block;
    font-size: 12px;
    line-height: $win-top-border-height;
    font-family: $special-font;

    &.disabled {
        border-color: $grey-2 !important;
        background-color: $grey-2 !important;
    }

    //RAG --> QA styles
    &.qa {
        background-color: $qa-color;
        border-color: $qa-color ;
    }
}

// Contenidor botons finestra
.win-symbol {
    @include position-all(absolute, 0, 0, null, null);
    display: block;
    background-color: transparent;
}

// win-slot-default: used in components that render inside a CWin without extra margin
.win-slot-default {
    @include position-all(absolute, $win-top-border-height, 0, 0, 0);
    overflow: auto; //Visible vertical scroll if needed
}

// win-slot-grid: vertical scroll will be inserted programatically
.win-slot-grid {
    @include position-all(absolute, $win-top-border-height, $win-accordion-width, 0,  0);
    @include trans-right(right .25s ease-in-out);
    overflow: auto hidden;  //Vert scroll will be inserted by CGrid
    background-color: $grey-5;

    &.collapsed {
        right: 20px;
    }
}

// win-slot-cform: vertical scroll will automatically be inserted if needed
.win-slot-cform {
    @include position-all(absolute, $cform-topbar-height, 0, 0,  0);
    overflow: auto auto;
}

// JDS - enable collapsible actions
.win-slot-cform {
    @include trans-right(right .25s ease-in-out);

    &.actions {
        right: $win-accordion-width;

        &.collapsed {
            right: 20px;
        }
    }
}

.win-slot-calendar-actions {
    @include position-all(absolute, 70px, 280px, 0,  0);
    overflow: hidden auto;

    &.disabled {
        opacity: 0.4;
    }
}

// Access Module calendar container
.mod-access-cont-calendar {
    @include position-all(absolute, 0, 0, 0,  0);
    overflow-y: auto;
}

// Access Module stripes-list container
.mod-access-cont-stripes-list {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    margin: auto;
}
// Access Module stripes-list header
.mod-access-stripes-header {
    border: 1px solid $grey-3;
    background: $grey-4b;
    height: 25px;
    line-height: 25px;
    border-bottom: none;
}
// Access Module stripes-list header text
.mod-access-stripes-header-text {
    margin-left: 10px;
    color: $txt-black;
    font-size: 12px;
    font-weight: bold
}


.mod-access-status-label {
    @include ellipsis-text;
    @include divStyle(null, $border-invisible, $mov-label-color);
    padding: 2px;
    font-size: 12px;
    font-family: $special-font;
    font-weight: bold;
    //color: $info-color;
    color: $txt-warning;
}


////////////////////
// Resultados
/////////////////

.win-results {
    @include position-all(absolute, 68px, 2px, 2px, 2px);
    overflow: hidden auto;

    .results-main-area {
        position: relative;
        width: 100%;
        height: 100%;

        .win-slot-results {
            @include div-size(100%, 100%);
            position: absolute;
            display: inline-block;
            bottom: 0;
            top: 0;

            .form-section {
                position: relative;
                display: inline-block;
                box-sizing: border-box;
                width: 100%;
            }

            .grid-section {
                position: absolute;
                top: 220px;
                bottom: 3px;
                left: 0;
                right: 0;
                display: inline-block;
                box-sizing: border-box;
                min-height: 200px;


                &.top-zero {
                    top: 0;
                }

                .grid-widget {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border: $border-normal $grey-2;
                }
                .container-grid {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}

.grid-base {
    height: 100%;
}


.win-content, .win-mini-container {
    @include position-all(absolute, 0, 2px, 0, 2px);
}

.win-mini-container {
    top: 42px; // Required to prevent menu buttons occlude the grid

    .win-slot-cform &{
        top: 2px;
    }
}



// Style only consulted by JS in orther to preserve width for accordion (actions)
.win-accordion-width {
    width: $win-accordion-width;  //144px
}

// Style only consulted by JS in orther to preserve height for bottombar
.win-bottom-bar-height {
    height: $win-bottom-bar-height;
}

// Barra d'opcions PEU de finestra Contenidor
.win-footer-left {
    @include position-all(absolute, 95%, null, null, null);
    @include div-size(99%, 20px);
    @include divStyle(transparent, $border-normal $grey-3, null);
    @include margin(null, 3px, null, 3px);
    display: inline-block;
    transform: translate(-0%, -0%);
    text-align: left;
    padding-left: 5px;
}

// Barra d'opcions PEU de finestra Contenidor botó redimensió finestra
.win-footer-right {
    @include position-all(absolute, 100%, null, null, 100%);
    @include div-size(10%, 15px);
    @include divStyle(transparent, $border-normal $grey-3, null);
    display: inline-block;
    background-color: transparent;
    transform: translate(-100%, -100%);
    border-right: none;
    border-bottom: none;
    text-align: right;
}

// Botó redimensio finestra ¿es fa servir?. no se pas!!
.btn-resizer {
    @include div-size(15px, 15px);
    @include divStyle(transparent, none, null);
    position: relative;
    display: block;
    text-align: right;
    float: right;
    cursor: se-resize;
}

// CONTINGUT FINESTRA - Estructura - Barra de cerca (search) i filtres
.win-options {
    @include div-size(83%, $win-top-border-height);
    @include position-all(absolute, null, null, null, 0);
    @include padding(5px, null, null, 0);
    display: inline-block;
    background-color: $grey-3;
}

////////////////////////////
//
//  TOOLBAR inside window Styles
//
////////////////////////////

.win-tbl-search {
    @include div-size(100%, 30px);
    @include position-all(static, null, null, null, 0);
    @include padding(5px, null, null, 0);
    display: inline-block;
    background-color: $grey-3;
}

.win-tbl-filters-zone {
    position: relative;
    display: inline-block;
    width: auto;
    margin-left: 5px;
    cursor: pointer;
    overflow: hidden;
}


//Position for the 'filter by selected' eye button
.win-filter-by-sel {
    position: relative;
    display: inline;
    margin: 0 2px;
}

.win-info-num-items {
    @include position-all(null, null, null, null, null);
    display: inline-block;
    line-height: 1;
    font-size: 10px;
    overflow: hidden;

    // variant quan es dins dins de fltr-list-container
    .fltr-list-container & {
        @include position-all(absolute, null, 60px, null, null);
        right: 0;
    }
}

.hor-bottom .win-info-num-items, .hor-top .win-info-num-items {
    display: inline-block;
    line-height: 2;
    font-size: 10px;
    overflow: hidden;
    text-align: right;
    right: 5px;
}

.win-info-num-items.relative-right {
    right: 5px;
    text-align: right;
}

.win-info-num-items.extra-combo {
    right: 5px;
    line-height: 2.5;
}

.win-mini-container .win-info-num-items {
}

.win-info-all-check {
    @include position-all(null, 3px, null, null, null);
    display: inline-block;
    margin: 0;
}

// imatge fletxes de capçalera de taula -
// ordre alfabètic ascendent o descendent de les dades //
.win-tbl-img {
    display: inline-block;
    width: auto;
    margin-right: 5px;
}

// bottom botons "vistes estructures" //
.win-view-button-zone {
    @include position-all(absolute, null, 10px, 0, null);
    display: inline-block;
    margin-bottom: 10px;
    width: auto;
    cursor: pointer;
    overflow: hidden;
}

.win-view-button {
    margin-right: 4px
}

// Vista de 'Container', combo de 'views' inferior
.win-view-combo-zone {
    @include position-all(absolute, null, null, 32px, 4px);
    width: 200px;

    .wgt-box & {
        bottom: 9px;
    }

    .access-monitor-module &  {
        @include position-all(null, null, null, 34px, null);
    }
}

.win-view-save-zone {
    @include position-all(absolute, null, null, 34px, 205px);
    width: 24px;
}

// DEBG panel
.debug-container {
    @include position-all(absolute, 0, 0, 0, 0);
    overflow: hidden;
}

.debug-text-area {
    @include div-size(100%, 100%);
    position: absolute;
}


// Used inside a CModule component, to ensure that contents inside module don't affect the window (especially
// CIFrameContainer component)
.win-module-container {
    @include div-size(100%, 100%);
    padding-top: $win-top-border-height;
}

//DASHBOARD - Vista Resumen
.win-module-container-dashboard {
    @include position-all(absolute, 25px, 0, 0, 0);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $grey-4;
}

//USED only when debugging...
.win-module-container.debug-grid-monitor {
    @include div-size(100%, 100%);
    @include padding($win-top-border-height, null, 4px, null);
    background-color: $white;
}

.debug-col {
    @include position-all(absolute, $win-top-border-height, null, 0, 0);
    width: 200px;
}
.debug-col.col-1 {
    left: 200px;
}

.debug-col.col-2 {
    left: 400px;
}

.debug-col.col-3 {
    left: 600px;
}

.debug-do-query {
    @include divStyle(#fffed7, $border-normal $grey-1, null);
    margin-bottom: 2px;
}

.debug-on-data {
    @include divStyle(#e7eee2, $border-normal $grey-1, null);
    margin-bottom: 2px;
}

.debug-REQ-1 {
    @include divStyle(#e6e8eb, $border-normal $grey-1, null);
    margin-bottom: 2px;
}

.debug-REQ-2 {
    @include divStyle(#8cf988, $border-normal $grey-1, null);
    margin-bottom: 2px;
}
.debug-REQ-2.missing {
    background-color: #f9bec5;
}
.debug-REQ-2.pending {
    background-color: $yellow;
}

.debug-REQ-2.received {
    background-color: #ccd8f9;
}
.debug-REQ-2.error {
    @include divStyle($grey-1, null, $txt-white);
}
