
////////////////////////////////////////////
//
// ERROR PAGE
//
///////////////////////////////////////////

.error-page {
    display: flex;
    height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: $brand-color;
    font-family: $special-font;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transform-style: preserve-3d;
}

main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.error-msg, .error-info {
    @include text($special-font, 3vw, 1.4, bold, center, $white);
    width: 15em;
    display: block;
    position: relative;
}

.connecting-error {
    @include text($special-font, 2vw, null, bolder, null, $white);
    text-shadow: -2px -2px 2px rgba(0, 0, 0, .3);
    opacity: 0;
    animation: error-connect 1s ease-in-out forwards;
}

.on-polling {
    @include text($special-font, 4vw, null, bolder, null, $error-page-color);
    margin-top: 1em;
    text-shadow: -2px -2px 2px rgba(0, 0, 0, .3);
    opacity: 0;
    animation: pulseColor 5s linear infinite 2s;
}


.btn-retry {
    padding-top: 20px;

    & .btn-error-retry {
        font-size: 2vw;
        width: max-content;
        min-width: 100px;
        max-width: 250px;
        height: auto;
        opacity: 0;
        animation: error-connect 1s ease-in-out forwards;
    }
}


///// TRANSLATE ERROR-MSG SECTION

.translateLeft {
    transform: translateX(100%);
    animation: translateLeft 1000ms linear 2000ms forwards;
    opacity: 0;
}

.translateRight {
    opacity: 0;
    transform: translateX(-100%);
    animation: translateRight 1200ms linear 1300ms forwards;
}


////   ERROR PAGE  SVG STYLES

svg.svg-broken-link {
    width:30vw;
}

.svg-broken-link   {
    path#broken_link1.stroke-brand, path#broken_link2.stroke-brand {
        stroke: rgb(0, 160, 155) !important;
    }
    path#broken_link1.stroke-white, path#broken_link2.stroke-white {
        stroke: rgb(255, 255, 255) !important;
    }
    path {
        stroke-dasharray: 1649.099;
        stroke-dashoffset: 1649.099;
        filter: drop-shadow(7px 7px 5px rgba(0, 0, 0, 0.4));
        -webkit-filter: drop-shadow(7px 7px 5px rgba(0, 0, 0, 0.4));
        animation: drawStroke 2000ms linear 1500ms forwards;
    }
    g  {
        filter: drop-shadow(7px 7px 5px rgba(0, 0, 0, 0.4));
        -webkit-filter: drop-shadow(7px 7px 5px rgba(0, 0, 0, 0.4));
        animation: broken-blink 3.5s linear infinite 2s;
        opacity: 0;
    }
}

@keyframes pulseColor {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity:0;
    }
}

@keyframes error-connect {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@keyframes drawStroke {
    0% { stroke-dashoffset: 1649.099; }
    100% { stroke-dashoffset: 0; }
}

@keyframes broken-blink {
    0% { opacity: 0;  }
    45% { opacity: 1;  }
    50% { opacity: 0;  }
    51% { opacity: 1;  }
    55% { opacity: 0;  }
    100% { opacity: 1;  }
}

@keyframes translateLeft {
    0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 150, 0, 0, 1); opacity: 1; }
    7.61% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 69.561, 0, 0, 1); }
    11.41% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 39.355, 0, 0, 1); }
    15.12% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 17.801, 0, 0, 1); }
    18.92% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.02, 0, 0, 1); }
    22.72% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -5.661, 0, 0, 1); }
    30.23% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -10.852, 0, 0, 1); }
    50.25% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -2.282, 0, 0, 1); }
    70.27% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.519, 0, 0, 1); }
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1; }
}

@keyframes translateRight {
    0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -150, 0, 0, 1); opacity: 1; }
    7.61% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -69.561, 0, 0, 1); }
    11.41% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -39.355, 0, 0, 1); }
    15.12% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -17.801, 0, 0, 1); }
    18.92% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.02, 0, 0, 1); }
    22.72% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.661, 0, 0, 1); }
    30.23% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 10.852, 0, 0, 1); }
    50.25% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.282, 0, 0, 1); }
    70.27% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.519, 0, 0, 1); }
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1; }
}
