////////////////////////////
//
//  LISTS
//
////////////////////////////

// A generic list of items (used in CList component)
.generic-list {
    @include divStyle($white, $border-normal #dbdbdb, null);
    padding: 2px;
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 190px;

    .mod-access-cont-stripes-list & {
        position: absolute;
        top: 25px;
        bottom: 0;
        overflow: hidden auto;
        min-height: 0;
    }

    .scalendar-col-title  &,
    .scicles-col-title & {
        @include divStyle($white, none, null);
        text-transform: none;
        font-weight: normal;
        font-family: $base-font;
        height: 200px;
        overflow: hidden auto;
    }

    .devices-activation & {
        overflow-y:  auto;
        height: 100%;
        min-height: 140px;
        max-height: 175px;
    }

    .scicles-col-title & {
        border: $border-normal $grey-3;
    }

    .cform-item-widget & {
        min-height: auto;
    }
}


// A generic item inside a generic-list (used in CListItem component)
// (inspired by g-win-item)
.generic-list-item {
    @include divStyle(null, $border-invisible, $txt-black);
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 2px;
    text-align: left;
    font-size: 10px;
    border-collapse: collapse;
    font-family: $special-font;

    &:hover {
        @include divStyle($white, $border-normal $brand-color, $txt-brand);
    }

    .fltr-list-container &,
    .fltr-list-op-container & {
        border: $border-invisible;
    }
}

/////////////////////////////////////// CToggleButtonsList ///////////////////////////////////////

.toggle-button-list-panel {
    position: absolute;
    box-shadow: 1px 1px 2px $grey-1;
    color: #5a5a5a;
    font-family: $special-font;
    background-color: $white;
    padding: 5px;
}

.toggle-button-list-icon {
    @include div-size(18px, 20px);
    position: relative;
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    font-family: $special-font;

    &:disabled {
        @include divStyle($white, $border-normal $grey-2, $txt-grey-2);
    }

    .icon-flex {
        max-width: 100%;
        width: 20px;
        min-width: 14px;
        margin: 0;
        margin-bottom: 6px;
    }

    .timeline-nav  & {
        @include div-size(20px, 20px);
        margin-right: 10px;
        margin-left: 10px;

        img.icon-flex {
            @include div-size(100%, auto);
            max-width: 100%;
            margin: 0;
        }
    }
}

//When CToggleButtonsListTrigger is used as ItemRenderer, we want to clear default 'toggle-button-list-item' style
.toggle-button-list-root-null {
    padding: 0;
    margin: 0;
    &:hover {
        cursor: pointer;
    }
    &.disabled {
        cursor: default;
    }

    .toggle-button-list-icon {
        @include div-size(auto, auto);
        margin-right: 0;
    }
}


.toggle-button-list-item {
    @include divStyle(null, $border-invisible, null);
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 4px;
    text-align: left;
    font-size: 12px;
    border-collapse: collapse;
    font-family: $special-font;
    color: #5a5a5a;

    &:hover {
        @include divStyle(null, $border-normal $brand-color, $txt-brand);
        cursor: pointer;
    }

    //Monitor de actividades (style)
    &:hover .cform-item-label {
        color: $txt-brand;
    }

    .timeline-nav  & {
        padding: 0;
        margin-left: 10px;
        margin-right: 10px;
    }

    .timeline-nav  &:hover  {
        border: $border-invisible;
        background-color: $yellow;
        cursor: pointer;
    }
}
