////////////////////////////
//
//  MUSEUM MODULE  Styles
//
////////////////////////////

.museum-module {
    display: flex;
    flex-direction: column;
}

.museum-menu-wrap {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;

    button {
        &.btn-medium {
            height: auto;
        }
    }
}

.museum-selector-wrap {
    display: block;
    background-color: $error-color;
    height: 50px;
    width: 100%;
}

.museum-grid-wrap {
    position: relative;
    display: block;
    margin: auto auto 5px auto;
    width: 100%;
    height: 100%;
    border-left: $border-normal #dbdbdb;
    border-right: $border-normal #dbdbdb;
}

.museum-panel {
    display: flex;
    flex-direction: column;
    margin: auto 1em auto 1em;
    border: $border-normal $brand-color;
    /*height: auto;*/
    height: 55px; /* LLP: changed auto => 60px, because Firefow showed mega-big buttons */
    flex: 1 1 0;

    img {
        padding: .25em;
    }
}

.museum-info {
    color: $txt-white;
    display: table;
    width: 100%;
    padding: .25em;
    background-color: $brand-color;

    span {
        display: table-cell;

        &.label-museum {
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.content-museum {
            text-align: right;
        }
    }
}
