////////////////////////////
//
//  SHIFT Styles
//
////////////////////////////
// JORNADES
// Estructura base del contingut de la finestra

.sh-win-base {
    top: 60px;
}

.sh-container, .sh-tt-container {
    border: $border-normal $grey-3;
    padding: 2px;
    overflow: hidden;
}

.sh-tt-container {
    padding: 0;
    margin: 0.5em;
    overflow: hidden;
}

.sh-col-title {
    background-color: $grey-2;
}

.col-title {
    @include text(null, 14px, 2, null, center, $txt-white);
    margin: auto;
    width: auto;
    display: block;
}

.sh-slot-panel {
    padding: 15px;
}

.sh-inc-slot {
    height: 8px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.sh-progress-bar {
    @include div-size(0, 100%);
    @include divStyle($blue, null, $txt-white);
    @include text(null, 12px, 20px, null, center, null);
    margin: auto;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
}

.sh-mascara {
    @include div-size(1px, 1px);
    position: absolute;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Shifts form styles related
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
 //   Shifts by time types. Right side graphical: rulers, bars and this kind of stuff.
 //  Wrapper of several inner canvas.
 //

.shift-graph-wrapper,
.calc-graph-wrapper {
    display: block;
    min-width: 415px;
    background-color: $grey-5;
    line-height: 0;
    // overflow: auto hidden; => JDS: 20/02/2020 on Edge results as hidden
    overflow: auto;

    canvas {
        margin-left: 0;
    }
}

.calc-graph-wrapper {
    overflow-x: hidden;
}

.grey-5 {
    background-color: $grey-5;
}

// Left side shift wrapper
.shift-side-wrapper {
    overflow: hidden; // auto;
    display: block;
}

// Side actions
.shift-actions-wrapper {
    display: flex;
    background-color: $grey-5;
    justify-content: center;
}

.shift-wrapper-treeview,
.calc-wrapper-view {
    @include div-size(100%, auto);
    background-color: $grey-5;
}

.calc-wrapper-view {
    @include div-size(auto, 25px);
}

.collapsed-zone {
    display: flex;
}

.shift-title-collapsed,
.calc-title-collapsed {
   @include ellipsis-text;
   @include text($special-font, null, null, null, null, $txt-grey-5);
    text-transform: uppercase;
}

.calc-title-collapsed {
    @include text(null, 12px, 2, bold, null, $txt-grey-0 !important);
    text-transform: none;
    text-indent: 5px;
    cursor: pointer;
}

.arrow {
    @include div-size(0, 0);
    margin: auto;

    &.up {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;

        &.black {
            border-bottom: 5px solid black;
        }
    }

    &.down {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;

        &.black {
            border-top: 5px solid black;
        }
    }
}

.iarrow {
    @include div-size(16px, 16px);
    font-size: 16px;

    &.up::after {
        content: '\025b2';
    }

    &.down::after {
        content: '\025bc';
    }
}

.shift-elements-tree-wrapper {
    padding-left: 10px;
}

.shift-text-element-tree,
.calc-text-element-title,
.calc-grid-text {
    @include ellipsis-text;
    @include padding(null, null, 5px, 5px);
    width: 100%;
}

.calc-text-element-title, .calc-grid-text {
    width: auto;
}

.calc-origins-container {
    width: auto;
    position: relative;
    overflow-y: scroll;
}

