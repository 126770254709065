////////////////////////////
//
//  MODAL WIN Styles
//
////////////////////////////
// MODAL-WIN panell de missatges informatius, d'error o d'atenció (panell amb opacitat)

.modal-win {
    //RAG --> absolute position solves  blur text in translate (chrome browser)
    @include position-all(absolute, 0, 0, 0, 0);
	display: block;
	float: inherit;
	overflow: auto;
	background-color: $grey-5;
	opacity: 0.8;
	filter: alpha(opacity=80); // For IE8 and earlier
	z-index: $layer-modal-background;
}

// Marc contingut missatge
.modal-win-box {
    @include position-all(absolute, 50%, null, null, 50%);
	@include div-size(450px, null);
    @include divStyle($white, $border-normal $white, null);
    margin: 0 auto;
    padding: 0;
    outline: 0;
    z-index: $layer-modal-box;
    border-radius: 2px;
	font-size: 12px;
    transform: perspective(1px)   translate(-50%, -50%);

    &.info{
        border: $border-normal $info-color;
    }
    &.error {
        border: $border-normal $error-color;
    }
    &.warning, &.confirm, &.custom {
        border: $border-normal $warning-color;
    }
}

// Habilita espai per al contigut - dins el marc
.modal-win-content {
	padding: 5px 40px 0 40px;
	background-color: $white;
}

// Capçalera del missatge
.modal-win-header {
    @include div-size(100%, 20px);
    @include divStyle($white, null, $txt-white);
    display: block;
	margin-top: 5px;
	text-align: center;
	font-size: 18px;
	font-weight: bolder;
    font-family: $special-font;

    &.info{
        color:  $txt-info;
    }
    &.error {
        color:  $txt-error;
    }
    &.warning, &.confirm, &.custom {
        color:  $txt-warning;
    }
}

// Cos del missatge text descriptiu del missatge
.modal-win-text{
    display: block;
    margin: 20px 5px;
    max-height: 200px;
    overflow-y: auto;
    padding: 4px;
    font-size: 14px;
	word-wrap: break-word;
    font-family: $special-font;
}

// Peu de la finestra contingut-botons
.modal-win-footer-btn {
    @extend .modal-win-header;
	height: auto;
	margin-bottom:5px;
	font-size: 14px;
}

// zona posició del botó tancar (close) finestra ("x" superior-dreta)
.modal-win-topright-btn-close{
    @include position-all(absolute, 0, 0, null, null);
    @include div-size(30px, 30px);
}

