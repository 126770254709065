////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///  SHIFTS PLANNING MODULE
///
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.shift-matrix-wrapper {
    @include div-size(100%, 100%);
    @include box-sizing(border-box);
    position: relative;
    max-height: 100%;
    display: inline-block;
}

.shift-list,
.shift-grid-position {
    @include div-size(100%, 100%);
    @include box-sizing(border-box);
    position: relative;
    display: inline-block;

    .shift-list-title {
        @include ellipsis-text;
        @include divStyle($grey-1, null, null);
        @include text($special-font, 12px, 1.5, null, center, $txt-white);
        @include div-size(null, 20px);
        text-transform: uppercase;
    }
}

.shiftHeaderDivider {
    @include div-size(null, 80%);
    @include margin(auto, .5em, auto, .5em);
    border-left: $border-normal $grey-3;
}

.shifts-map-wrapper {
    @include div-size(100%, 100%);
    position: relative;
    display: inline-block;
}

.matrix-wrapper {
    @include div-size(100%, 100%);
    @include box-sizing(border-box);
    display: block;
    position: relative;

    &.centered {
        .worker-day {
            line-height: 2.5;
        }
    }
}

.drag-rect {
    position: absolute;
    background-color: rgba(0, 160, 155, .5);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///  RULER
///
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.ruler-wrapper {
    @include div-size(100%, null);
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.ruler-item {
    @include text($special-font, 11px, 1.5em, null, center, $txt-grey-5);
    @include divStyle($grey-1, $border-invisible, null);
    @include ellipsis-text;
    @include box-sizing(border-box);
    @include div-size(100%, null);
    @include margin(1px, 1px, 0, 1px);
    position: relative;
    vertical-align: middle;

    &.odd {
        @include divStyle($grey-2, null, $txt-white);
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///  MATRIX & CELLs
///
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.worker-month {
    @include div-size(null, 24px);
    display: flex;
    flex-direction: row;
    background-color: white;
    border-top: $border-normal $white;
    font-family: $base-font;

    &:nth-child(even) {
        .worker-day {
            background-color: $grey-4;
        }
    }

    .worker-day {
        @include ellipsis-text;
        @include text(null, 10px, 1.5em, null, center, null);
        @include div-size(100%, null);
        @include margin(0, 1px, 0, 1px);
        flex: 1;
        position: relative;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;

        &.dark {
            color: white
        }

        &.light {
            color: black
        }

        &.selected:not(.pseudo-selected) {
            @include divStyle($yellow !important, null, $txt-brand !important);
        }

        &.pseudo-selected:not(.selected) {
            background-color: $warning-color !important;
            color: white;
        }

        &.selected.pseudo-selected {
            opacity: .75;
        }

        &.protected {
            opacity: .5;
        }

        &.protected.pseudo-selected {
            opacity: .75;
        }

        &:hover {
            outline: $border-normal $warning-color !important;
            box-shadow: $warning-color 0 0 2px 1px inset;
        }

        &.blank {
            background-color: ghostwhite;
        }

        &.not-committed,
        &.overwritten {
            box-shadow: 0 0 0 1px $warning-color inset;
            border-radius: 0 30% 0 0 / 0 60% 0 0;
        }

        &.noContract {

            &:after {
                content: "\02298";
                font-size: 14px;
                color: $txt-error;
                font-weight: bold;
                text-align: center;
                line-height: 1.5;
            }
        }

        .wrapper-planning {
            @include div-size(100%, 32%);
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: stretch;
            position: absolute;
            bottom: 1px;

            &.multi {
                padding: 0 10% 0 10%;

                .partial {
                    margin-right: 5%;
                    margin-left: 5%;
                }
            }

            .full {
                flex-grow: 1;
                outline: solid .5px white;
            }

            .partial {
                flex: 1;
                outline: solid .5px white;
            }

            .planning-hovered {
                box-shadow: 2px 1px 4px  $grey-0;
                animation: blinking 1.5s linear infinite;
                cursor: pointer;
                outline: $border-normal $grey-0 !important;
            }

            .line-pending-planning {
                @include div-size(auto, 0);
                display: flex;
                flex-grow: 1;
                position: relative;
                bottom: 5px;
                height: 9px;
                color: $white;
                background-image: linear-gradient(-45deg,
                    transparent,
                    transparent 25%,
                    currentColor 25%,
                    currentColor 50%,
                    transparent 50%,
                    transparent 75%,
                    currentColor 75%);
                background-size: 6px 6px;
                border: none;
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///  TOOLTIP
///
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.ps-tooltip-wrapper {
    display: inline-block;
}

.ps-tooltip-body {
    @include divStyle($tltp-back-color, $border-invisible, null);
    position: fixed; // absolute;  // cuidado con las liadas....
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-width: 200px;
    max-width: 300px;
    z-index: $layer-tooltip;
    padding: 2px;
    border-radius: 2px;
    box-shadow: $tltp-box-shadow;
    animation: fade-in $fade-in-time;

    &.left {
        transform: translateX(-100%);
    }

    &.right {
        transform: translateX(-22px);
    }

    &.up {
        transform: translateY(-100%);
    }

    &.down {
        transform: translateY(22px);
    }

    &.down.right {
        transform: translate(-20px, 37px);
    }

    &.down.left {
        transform: translate(-100%, 37px);
    }

    &.up.right {
        transform: translate(-27px, calc(-100% - 13px));
    }

    &.up.left {
        transform: translate(-100%, calc(-100% - 13px));
    }

    &:before {
        @include div-size(0, 0);
        border: 7px solid transparent;
        position: absolute;
        content: " ";
    }

    &.up:before {
        top: 100%;
        border-top-color: $tltp-back-color;
    }

    &.up.left:before {
        left: 100%;
        transform: translate(-13px, 0%);
    }

    &.up.right:before {
        right: 100%;
        transform: translate(13px, 0%);
    }

    &.down:before {
        border-bottom-color: $tltp-back-color;
        bottom: 100%;
    }

    &.down.left:before {
        left: 100%;
        transform: translate(-18px, 0%);
    }

    &.down.right:before {
        right: 100%;
        transform: translate(18px, 0%);
    }
}

.ps-tooltip-section {
    @include text($special-font, 10px, null, normal, null, $txt-black);
    @include margin(2px, 2px, 2px, 2px);
    display: inline-block;
    position: relative;
    vertical-align: middle;
    text-align: left;
    text-overflow: ellipsis;
    padding: 2px 2px;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
///  MATRIX HEADER
///
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.shiftHeaderDivider {
    @include div-size(null, 80%);
    @include margin(auto, .5em, auto, .5em);
    border-left: $border-normal $grey-3;
}

.s-planning-header-wrapper {
    @include div-size(null, 40px);
    display: inline-flex;
    justify-content: center;
    min-width: 100%;
    padding: 0 5px 0 5px;
    background-color: $white;

    .btn-section {
        align-self: center;

        button {
            @include margin(0, 0, 0, 1px);
        }

        & img.icon-flex {
            @include div-size(75%, auto);
            @include margin(2px, 2px, 2px, 2px);
            max-width: 100%;
        }
    }

    .chk-section {
        align-self: center;
    }

    .combo-section {
        @include div-size(null, 50%);
        @include margin(auto, .5em, auto, .5em);
        position: relative;
        flex-grow: 7;
    }
}
