////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   TIME-LINE Skeleton
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.module-wrapper {
    position: relative;
    height: 100%;
    width: 99%;
    margin: auto;
}

.timeline-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    /*height: 100%; JDS - Better fits on available space */
    flex: 1 1 auto;
}

.timeline-filters {
    position: relative;
    height: 36px;
    margin-top: 3px;

    img.icon-flex {
        @include div-size(100%, auto);
        @include margin(2px, 2px, 2px, 2px);
        max-width: 100%;
    }

    img.icon-flex:hover {
        cursor: pointer;
        background-color: transparent;
    }
}

.timeline-nav {
    position: relative;
    width: 100%;
}

.timeline-header {
    position: relative;
    background-color: whitesmoke;
    width: 100%;
}

.timeline-body {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    display: block;
}

.timeline-showcase {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    .time-divider {
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: orange;
        z-index: 700;

        &:before {
            content: " ";
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid orange;
            position: absolute;
            top: -4px;
            left: -3px;
        }
    }

    .time-divider-data {
        z-index: 700;
        position: absolute;
        width: 40px;
        bottom: -24px;
        text-align: center;
        color: white;
        background-color: #ff450082;
        border: 1px solid orange;
    }

    .current-time-line {
        position: absolute;
        height: 100%;
        width: 1px;
        border-left: 1px dotted $brand-color;
        z-index: 300;
    }

    .current-time-line-data {
        z-index: 300;
        position: absolute;
        width: 40px;
        bottom: -24px;
        text-align: center;
        color: white;
        background-color: $brand-color;
        border: 1px solid $brand-color;
    }

    .current-time-line-nav {
        position: absolute;
        width: 0;
        height: 0;
        bottom: -24px;
        display: inline-block;
        z-index: 777;

        .current-time-line-data {
            bottom: -8px;
            border: none;
        }

        &.right {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 9px solid $brand-color;
            right: 0;

            .current-time-line-data {
                right: 9px;
            }
        }

        &.left {
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-right: 9px solid $brand-color;
            left: 0;

            .current-time-line-data {
                left: 9px;
            }
        }
    }

    .time-drag {
        height: 24px;
        background-color: #9e9e9e85;
    }

    .time-drag-bound {
        position: absolute;
        height: 100%;
        width: 1px;
        border-left: 1px dotted #a9a9a9c2;
        z-index: 300;
    }

    .time-drag-bound-date {
        z-index: 300;
        position: absolute;
        width: 40px;
        bottom: -24px;
        text-align: center;
        color: white;
        background-color: $grey-1;
        border: 1px solid $grey-1;
    }

    .meta-wrap {
        position: absolute;
        width: 50px;
        z-index: 300;

        .meta-row {
            height: 24px;

            span {
                font-size: 10px;
                line-height: 10px;
                width: 25px;

                &.meta-total {
                    top: 4px;
                    float: left;
                    color: grey;
                    height: 100%;

                    &:after {
                        background-image: url('../svg/users_brand.svg');
                        background-size: 15px 15px;
                        display: inline-block;
                        position: absolute;
                        right: 15%;
                        bottom: 25%;
                        width: 15px;
                        height: 15px;
                        content: "";
                    }
                }

                &.meta-in {
                    display: inline-block;
                    float: right;
                    top: 0;
                    height: 50%;
                    line-height: 18px;
                    text-align: center;
                    color: dodgerblue;

                    &:after {
                        background-image: url('../svg/zone-enter_on.svg');
                        background-size: 15px 15px;
                        display: inline-block;
                        position: absolute;
                        left: 101%;
                        top: 1px;
                        width: 15px;
                        height: 15px;
                        content: '';
                    }
                }

                &.meta-out {
                    float: right;
                    bottom: 3px;
                    color: red;
                    line-height: 24px;
                    display: inline-block;
                    height: 50%;
                    text-align: center;

                    &:after {
                        background-image: url('../svg/zone-leave_on.svg');
                        background-size: 15px 15px;
                        display: inline-block;
                        position: absolute;
                        left: 101%;
                        bottom: 1px;
                        width: 15px;
                        height: 15px;
                        content: '';
                    }
                }
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Header Nav
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.timeline-nav-wrapper {
    position: relative;
    height: 50px;
    width: 100%;
}

.timeline-nav-section { // Header rows
    display: flex;
    height: 50%;
    width: 100%;
    flex-wrap: nowrap;
    max-width: 100%;
}

.timeline-nav-header,
.timeline-nav-detail {
    @include text($special-font, 11px, 25px, null, left, $txt-grey-1);
    @include ellipsis-text;
    @include div-size(100%, null);
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    outline: $border-normal $white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;

    &.left-align {
        text-align: left;
    }

    &:hover {
        .timeline-nav-cover {
            display: inline-block;
        }
    }
}

.timeline-nav-cover {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fafafa75;
}

.timeline-nav-thumb {
    float: right;
    max-width: 20px;
    vertical-align: middle;
    margin-right: 4px;
}

.timeline-nav-header {
    @include divStyle($grey-1, null, $txt-white);

    &.even {
        background-color: $warning-color;
    }

    &:hover {
        background-color: $brand-color;
    }
}

.timeline-nav-detail {
    padding-left: 2px;
    text-align: left;

    &:nth-child(even) {
        background-color: $grey-3b;
    }

    &:hover {
        overflow: visible;
        background-color: $brand-color;
        color: white;
    }


     &.pivot {
         background-color: $warning-color;
         color: $white;
     }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Row layers
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.timeline-body {

    .row-wrap {
        z-index: 0;
        display: flex;
        flex: 1;
        width: 100%;
        height: 24px;
        flex-direction: row;
        background-color: white;
        font-family: $base-font;

        &:nth-child(even) {
            .row-base {
                background-color: $grey-4;
            }
        }
    }

    .row-layer {
        z-index: 0;
        height: 0;
        width: 100%;
        position: absolute;
        display: block;
        background-color: transparent;
        overflow: visible;

        .row-base {
            flex: 1;
            outline: 1px solid white;
        }

        .row-data {
            top: 0;
            height: 100%;
            position: absolute;

            &:hover {
                box-shadow: 2px 1px 4px $grey-0;
                animation: blinking 1.5s linear infinite;
                cursor: pointer;
            }
        }

        &.level0 { // base
            display: flex;

            .row-base {
                z-index: 0;
                height: 24px;
            }
        }

        &.level1 { // shift
            .row-data {
                z-index: 5;
                height: 22px;
                top: 1px;
                opacity: .2;

                &:hover {
                    opacity: .2;
                    cursor: pointer;
                }
            }
        }

        &.level2 { // schedule
            .row-data {
                height: 8px;
                top: 8px;
                opacity: .75;
                z-index: 10;
            }
        }

        &.level3 { // system

            .row-data {
                height: 8px;
                top: 8px;
                opacity: 1;
                z-index: 15;
            }
        }

        &.level5 { // activities

            .row-data {
                height: 4px;
                top: 0;
                opacity: 1;
                z-index: 25;
                border-bottom: $border-normal $white;
            }
        }

        &.level6 { // workOrders

            .row-data {
                height: 4px;
                top: 5px;
                opacity: 1;
                z-index: 30;
                border-bottom: $border-normal $grey-2;
            }
        }

        &.level7 { // plannings

            .row-data {
                height: 5px;
                top: 17px;
                opacity: 1;
                z-index: 35;
            }
        }

        &.level9 { // marks

            .row-data {
                height: 20px;
                top: 2px;
                opacity: 1;
                z-index: 40;
                min-width: 1px;
            }
        }

    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Custom Module settings
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.level2,
.level3 {
    .row-data {
        box-shadow: 2px 1px 4px $grey-2;
    }
}

.row-data {

    &.initial-fit {
        border-left: 2px dotted $white;
    }

    &.final-fit {
        border-right: 2px dotted $white;
    }

    .pending-planning-line {
        @include div-size(auto, 0);
        display: flex;
        flex-grow: 1;
        position: relative;
        bottom: 7px;
        height: 5px;
        color: $white;
        background-image: linear-gradient(-45deg,
            transparent,
            transparent 25%,
            currentColor 25%,
            currentColor 50%,
            transparent 50%,
            transparent 75%,
            currentColor 75%);
        background-size: 9px 9px;
        border: none;
    }

    .time-type {}
    .system {}
    .shift {}
    .anomaly {}
    .activity {}
    .wo {}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Tooltip
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.tooltip-wrapper {
    display: inline-block;

    &.hidden {
        display: none;
    }
}

.tooltip-body {
    @include divStyle($tltp-back-color, $border-normal $grey-2, null);
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-width: 200px;
    max-width: 300px;
    z-index: $layer-tooltip;
    padding: 2px;
    border-radius: 2px;
    box-shadow: $tltp-box-shadow;
    animation: fade-in $fade-in-time;
}

.tooltip-section {
    @include text($special-font, 10px, null, normal, null, $txt-grey-0);
    @include margin(2px, 2px, 2px, 2px);
    display: inline-block;
    position: relative;
    vertical-align: middle;
    text-align: left;
    text-overflow: ellipsis;
    padding: 2px 2px;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// Modal not popUp
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.column-header-title {
    color: $txt-grey-0;
    text-align: center;
    font-size: 11px;
    font-family: $special-font;
    overflow: hidden;
    margin: auto;
    padding: 5px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 2px;
    border-bottom: $border-normal $grey-1;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   DEBUG
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.timeline-logger {
    display: block;
    margin: 0 auto;
    white-space: pre;
    padding: 2em;
    background-color: slategrey;
    position: absolute;
    right: 0;
    top: 25px;
    opacity: .85;
    z-index: 99999;

    b {
        white-space: pre;
    }

    span {
        display: block;
        white-space: pre;
    }
}

.toggle-button-list-item {
    &.selected {
        background-color: orange;
    }
}

.empty-data-pad {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .inner-empty-data {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 0 auto;
        background-color: #8080801f;
        padding: .5em;
    }

    span {
        display: inline-block;
        margin: 0 auto;
        color: grey;
        text-transform: uppercase;
        font-weight: bold;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   Access Module
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.timeline-wrapper#time-line-zones {

    canvas {
        margin-left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    .timeline-body {
        .row-layer {

            &.level1 {
                .row-data {
                    width: 100%;
                    top: 0;
                    opacity: .5;
                    box-shadow: none;
                }
            }
            &.level2 {
                .row-data {
                    height: 42px;
                    width: 100%;
                    top: 0;
                    opacity: 1;
                    box-shadow: none;
                }
            }
            &.level3 {
                .row-data {
                    width: 100%;
                    top: 0;
                    opacity: 1;
                    box-shadow: none;
                }
            }
            &.level4 {
                .row-data {
                    height: 8px;
                    top: 18px;
                    opacity: .75;
                    z-index: 10;
                }
            }

            &.level5 {
                .row-data {
                    width: 100%;
                    top: 1px;
                    opacity: 1;
                    box-shadow: none;
                }
            }

            // Check it, due needed to show bad access as a red mark
            &.level7 { // marks
                .row-data {
                    height: 39px;
                    top: 1px;
                    opacity: 1;
                    min-width: 1px;
                }
            }

        }
    }
}


.workers-time-line-wrap {
    .timeline-wrapper {
        .level7 { // marks
            .row-data {
                height: 20px;
                top: 2px;
                opacity: 1;
                min-width: 1px;
            }
        }
    }
}


.zones-grid-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 20px;

    .icon-btn {
        margin-top: 10px;
    }

    .btn-icon .icon-btn {
        margin-top: auto;
    }
}

.sub-grid-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 94%;
}

.workers-time-line-wrap {
    /*display: block;*/
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 5px;


    /* JDS - Better fits on available space */
    display: flex;
    flex-direction: column;

}

/////////////////////////  Toolbox  /////////////////////////

.access-tool-box {
    display: flex;
    flex-direction: row;
    /*height: 55px;*/
    background-color: #f1f1f1;

    /*JDS - Better fits on available space */
    flex: 0 1 55px;

}

.access-tool-box-actions {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    max-width: 200px;
    padding: .5em;

    button {
        margin: .25em;
    }

}

.access-breadcrumb {
    display: inline-flex;
    flex-wrap: nowrap;
    padding: .5em;
    width: 100%;
    @include ellipsis-text;

    .breadcrumb-wrap {
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-self: center;
        @include ellipsis-text;
        margin: 0;

        .breadcrumb-item {
            display: inline-flex;
            flex-wrap: nowrap;
            align-self: center;
            justify-content: center;
            @include ellipsis-text;

            .breadcrumb-text {
                @include text($special-font, 12px, 2em, null, left, $txt-grey-1);
                @include ellipsis-text;
            }
        }
    }
}

.avertical-divider {
    height: 80%;
    margin: auto 0.5em auto 0.5em;
    border-left: 1px solid #dbdbdb;
}

.zones-timeline-wrap {
    .toggle-button-list-item {
        float: left;
    }
}

.graph-ruler {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .graph-ruler-labels {
        flex-direction: column;
        position: relative;
        width: 3em;

        .graph-ruler-label {
            color: grey;
            font-size: 10px;
            text-align: end;
            margin-right: .75em;
        }
    }

    .graph-ruler-steps {
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        width: 1.75em;

        .graph-ruler-step {
            position: relative;
            border-bottom: 1px solid orangered;
            margin: auto 0 0 auto;
            width: 1em;

            &:nth-child(odd) {
                width: 2em;
            }
        }
    }

}

.timeline-nav-btn-wrap {
    display: flex;
    text-align: center;
    justify-content: space-around;
    margin: auto;
    height: 30px;
    max-width: 450px;
}

.timeline-nav-level-picker {
    flex: 1;
    max-width: 150px;
    min-width: 50px;
    margin-top: .35em
}

.timeline-nav-layer-picker {
    flex: 1;
    max-width: 150px;
    align-self: center;
}
