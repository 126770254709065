////////////////////////////
//
//  ANIMATION Styles
//
////////////////////////////

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.anim-fade-in {
    animation: fade-in $fade-in-time;
}

@keyframes fade-in-80 {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

.anim-fade-in-80 {
    animation: fade-in-80 $fade-in-time;
}


//////////////////////
//
//  DOCUMENTS
//
/////////////////////

.fadeIn {
    animation: fadein $fade-in-time;
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

///////////////////////////
//
//  ASYNC progress
//
////////////////////////////

.progress-async {
    @include trans-opa( opacity 0.25s linear);
    @include position-all(absolute, 0, null, null, null);
    @include div-size(100%, .15em);
    clear: both;
    z-index: $layer-bar-loader;
}

.progress-async.loading {
    opacity: 1.0;
}

.progress-async .percent {
    height: .15em;
    background-color: $brand-color;

}

///////////////////////////
//
// MOVEMENTS (hover planif), LOADER Reporting progress
//
///////////////////////////

@keyframes blinking {
    50% { opacity: .5; }
}
