
//MIXINS
@mixin btn-brand-color {
    background-color: $brand-color;
    border: $border-normal $brand-color;
    color: $txt-white;
}

@mixin btn-grey-color {
    background-color: $grey-1;
    border: $border-normal $grey-1;
}

@mixin btn-error-retry-color {
    background-color: $error-page-color;
    border: $border-normal $error-page-color;

    &:hover {
        background-color: $grey-0;
        color: $white;
        border: $border-normal $grey-0;
    }
}

@mixin text($family, $size, $height, $weight, $align, $color) {
    font-family: $family;
    font-size: $size;
    line-height: $height;
    font-weight: $weight;
    text-align: $align;
    color: $color;
}

@mixin btn($bkg, $bstyle, $bcolor, $txt-color) {
    background-color: $bkg;
    border: $bstyle;
    border-color: $bcolor;
    color: $txt-color;
}

@mixin divStyle($bkg, $bstyle, $txt-color) {
    background-color: $bkg;
    border: $bstyle;
    color: $txt-color;
}

@mixin ellipsis-text {
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// Width and Height
@mixin div-size($width, $height) {
    width: $width;
    height: $height;
}

// Position
@mixin position-all($pos, $top, $right, $bot, $left) {
    position: $pos;
    top: $top;
    right: $right;
    bottom: $bot;
    left: $left;
}

@mixin margin($top, $right, $bot, $left) {
    margin-top: $top;
    margin-right: $right;
    margin-bottom: $bot;
    margin-left: $left;
}

@mixin padding($top, $right, $bot, $left) {
    padding-top: $top;
    padding-right: $right;
    padding-bottom: $bot;
    padding-left: $left;
}

// Box sizing
@mixin box-sizing($type) {
 -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
         box-sizing: $type;
}

// user-select - evita la selecció de text d'un element <div>
@mixin user-select() {
 -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
}

//transition width
@mixin trans-width($trans-width) {
    -webkit-transition: $trans-width;
    -moz-transition: $trans-width;
    -o-transition: $trans-width;
    transition: $trans-width;
}
//transition all
@mixin trans-all($trans-all) {
    -webkit-transition: $trans-all;
    -moz-transition: $trans-all;
    -o-transition: $trans-all;
    transition: $trans-all;
}
//transition opacity
@mixin trans-opa($trans-opa) {
    -webkit-transition: $trans-opa;
    -moz-transition: $trans-opa;
    -o-transition: $trans-opa;
    transition: $trans-opa;
}
//transition right
@mixin trans-right($trans-right) {
    -webkit-transition: $trans-right;
    -moz-transition: $trans-right;
    -o-transition: $trans-right;
    transition: $trans-right;
}
//transition-duration
@mixin trans-dur($trans-dur) {
    -webkit-transition-duration: $trans-dur;
    -moz-transition-duration: $trans-dur;
    -o-transition-duration: $trans-dur;
    transition-duration: $trans-dur;
}

