////////////////////////////
//
//  INPUT Styles
//
////////////////////////////

// .input - estil base - text i search
.input[type='text'],
.datepicker-input[type='text'],
.input-valors[type='text'],
.input-valors-edit[type='text'],
.input-valors-select[type='text'],
.input-period[type='text'],
.fltr-edit-box[type='text'] {

    @include divStyle($white, $border-normal $grey-3, $txt-black);
    display: inline-block;
    overflow: hidden;
    height: 22px;
    font-size: 12px;
    text-align: left;
    text-indent: 4px;
    appearance: none;

    &:hover, &.filter-insert-entity-panel  {
        @include divStyle(null, $border-invisible, null);
        border-bottom: $border-normal $brand-color;
        box-shadow:  0 1px $brand-color;
        outline-offset: unset;
        outline: 0;
    }

    .fltr-edit-box[type='text']::placeholder.filter-insert-entity-panel {
        color: $txt-grey-2;
        font-size: 9px;
    }

    &.filter-insert-entity-panel:hover  {
        background-color: $yellow;
    }

    &:focus {
        background-color: $white;
        box-shadow:  0 1px $brand-color;
        border-bottom: $border-normal $brand-color;
        outline-offset: unset;
        outline: 0;
    }

    &.disabled {
        @include divStyle($grey-4, $border-normal $grey-4, null);
    }

    &:hover.disabled, &:focus.disabled {
        @include divStyle($grey-4, $border-normal $grey-4, null);
        outline: none;
    }

    .cierres & {
        background-color: transparent;
    }
}

.input-period[type='text'] {
    width:  35px;
    margin-right: 10px;
    margin-left: 10px;
}

.datepicker-input[type='text'] {
    width: 75px;
}

.datepicker-input {
    @include box-sizing(border-box);
    width: 70px;
    margin-right: 4px;
    text-align: center;
    font-size: 12px;
    color: $txt-black;
}

input[type='text'][disabled],
input[type='pssword'][disabled],
input[type='email'][disabled],
input[type='search'][disabled],
input[type='text'].disabled,
input[type='email'].disabled,
input[type='search'].disabled {
    @include divStyle($grey-4 !important, $border-normal $grey-4 !important, null);
    cursor: default;

        &:hover, &:focus {
            @include divStyle($grey-4, $border-normal $grey-4, null);
            outline: none;
    }
}

input[type="number"] {
    appearance: none;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}

.input-valors[type='text'],
.input-valors-edit[type='text'],
.input-valors-select[type='text'] {
    @include div-size(100%, 22px);
    @include box-sizing(border-box);
    @include divStyle($white, $border-invisible, $txt-grey-1);
    margin-left: 0;
    margin-right: 0;
    text-align: right;
}

// JDS - just font color!
.input-valors-edit[type='text'] {
    color: $txt-warning !important;
}

.input-valors-select[type='text'] {
    @include divStyle($yellow, null, $txt-grey-1);
}

////////////////////////////
//
//  INPUT SEARCH Styles
//
////////////////////////////

// Estil input cerca + variacions segons on estigui

.input-search[type='search'] {
    @include div-size(null, 22px);
    @include divStyle($white, $border-normal $grey-2, $txt-black);
    @include padding(2px, null, null, 5px);
    max-width: 250px;
    display: inline-block;
    overflow: auto;
    float: left;
    font-size: 12px;
    appearance: none;

    &:hover {
        @include divStyle($white, $border-invisible, null);
        border-radius: 1px;
        text-align: left;
        box-shadow: 0 1px $brand-color;
        border-bottom: $border-normal $brand-color;
        outline-offset: unset;
        outline: 0;
    }

    &:focus {
        background-color: $white;
        box-shadow: 0 1px $brand-color;
        border-bottom: $border-normal $brand-color;
        outline-offset: unset;
        outline: 0;
    }

    &::placeholder {
        color: $txt-grey-2;
    }

    // input-search - dins la llista que desplega la combo petita o dins cierres toolbox
    .win-mini-container &, .cierres-toolbox & {
        width: 120px;
    }

    .fltr-list-container & {
        width: 65%;
    }

    .g-win-panel-content1 & {
        width: 265px;
    }

    .hor-bottom & {
        margin-left: 2px;
    }
}

.input-search[type='search'].extra-combo {
    width: 80%; /* input search inside an extraCombo component, right space is preserved for N elements label*/
    margin-left: 5px;
}

.input-search[type='search'].filtering {
    background-color: $yellow;
}

.input-search[type='search'].no-results {
    @include divStyle(#fce4e4, $border-invisible , null);
    border-bottom: $border-normal $error-color;
    box-shadow: 0 1px $error-color;
}


////////////////////////////
//
//  INPUT WIDGET Styles
//
////////////////////////////

// .input-text-wgt - a cform (box-sizing)

.cform-row .input-text-wgt[type='text'],
.cform-row .input-text-wgt[type='password'],
.cform-row .input-email-wgt[type='email'],
.cform-row .input-num-wgt[type='number'],
.cform-row .input-date-wgt[type='text'],
.cform-row .datepicker-input[type='text'],
.cform-row .input-multiname-wgt[type='text'],
.input-multiname-wgt[type='text'],
.input-email-wgt[type='email'],
.input-text[type='text'],
.input-text-wgt[type='password'],
.hours-input[type='text'],
.enroll-times-input[type='text'],
.input-num-wgt[type='number'] {
    @include div-size(100%, 22px);
    @include box-sizing(border-box);
    @include divStyle($white, $border-normal $grey-3, $txt-black);
    display: inline-block;
    font-size: 12px;
    text-align: left;
    appearance: none;
    text-indent: 4px;

    &:hover,  .mov-detail-row &:hover {
        @include divStyle(null, $border-invisible, null);
        box-shadow: 0 1px $brand-color;
        border-bottom: $border-normal $brand-color;
        outline-offset: unset;
        outline: 0;
    }

    &:focus,  .mov-detail-row &:focus {
        background-color: $white;
        box-shadow: 0 1px $brand-color;
        border-bottom: $border-normal $brand-color;
        outline-offset: unset;
        outline: 0;
    }

    &::placeholder {
        color: $txt-grey-2;
    }


    &.invalid {
        @include divStyle(#fce4e4, $border-invisible , null);
        border-bottom: $border-normal $error-color;
        box-shadow: 0 1px $error-color;
    }

    &.disabled {
        pointer-events: none;
        color: $grey-0;
    }

    .hours-input[type='text'] {
        width: 5em;

        .mov-detail-row .hours-input[type='text'] {
            max-width: 5em;
            border: $border-normal $grey-3;
        }

        .mov-detail-row .hours-input[type='text'].disabled {
            max-width: 5em;
            border: $border-invisible !important;
        }
    }
}

.cform-row .input-num-wgt[type='number'],
.input-num-wgt[type='number'] {
    appearance: none;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}

//MOVIMENTS
//Marcatges esborrats - canvi color font i cursor.
.mov-deleted .hours-input[type='text'].disabled {
    cursor: default;
    pointer-events: none;
    color: $mov-deleted !important;
}

.mov-not-effective-mark-row .hours-input[type='text'].disabled,
.mov-error-mark-row .hours-input[type='text'].disabled {
    cursor: default;
    pointer-events: none;
    color: $mov-error !important;
}

.act-detail .hours-input[type='text'].disabled,
.ot-detail .hours-input[type='text'].disabled,
.mov-detail-row .hours-input[type='text'].disabled,
.mov-virtual-mark .hours-input[type='text'].disabled,
.mov-new-mark .hours-input[type='text'].disabled,
.mov-new-shift .hours-input[type='text'].disabled,
.mov-new-row .hours-input[type='text'].disabled {
    @include divStyle(transparent !important, $border-invisible !important, null);
    cursor: default;
    pointer-events: none;
}

.status-pending + .mov-detail-row .hours-input[type='text'].disabled {
    background-color: $status-pending-bkgr !important;
}

////////////////////////////
//
//  INPUT COLOR Styles
//
////////////////////////////

input[type='color'] {
    @include div-size(40px, 22px);
    @include divStyle(transparent, $border-invisible, null);
    padding: 0;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-color-swatch-wrapper {
        padding: 0;
    }

    &::-webkit-color-swatch {
        border: none;
    }
}



////////////////////////////
//
//  INPUT MULTINAME widget Styles
//
////////////////////////////
// .input-multiname-wgt - a cform i a win filtres desactiva border, hover i focus

.cform-row .input-multiname-wgt[type='text'],
.fltr-name-zone .input-multiname-wgt[type='text'],
.input-multiname-wgt[type='text'] {
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover,
    &:focus {
        border: none;
        outline: none;
    }
}

.input-text[type='text'] {
    height: 26px;

    .fltr-list-val-container & {
        margin-top: 4px;
    }
}

.enroll-times-input[type='text'] {
    @include div-size(30px, 18px);
    margin-left: 10px;
}

////////////////////////////
//
//  INPUT-DATE  Styles
//
////////////////////////////
// .input-date-wgt - a cform (box-sizing)

.cform-row .input-date-wgt[type='text'],
.cform-row .datepicker-input[type='text'] {
    width: 80px;
}

select {
    @include div-size(45px, 24px);
    font-size: 12px;
    margin: 0 10px;
    border: $border-normal $grey-3;

    &:hover {
        border: $border-normal $brand-color;
    }
}

option {
    font-size: 12px;
    font-family: $special-font;

    &:hover {
        background-color: $brand-color;
        color: $txt-white;
    }
}
