////////////////////////////
//
//  DASHBOARD  Styles
//
////////////////////////////

/* ============================== Arrodoniment al voltant d'una icona, tal i com ho fa ACE-Theme =============*/

// TOOLTIP
.util-ttip {
    position: absolute ;
    max-width: 250px;
    padding: 5px;
    overflow: hidden;
    background: $tltp-back-color;
    box-shadow: $tltp-box-shadow;
    pointer-events: none;
    z-index: $layer-tooltip ;
    font-family: $special-font;
    text-align: center;

    & .ttip-val{
        color: $txt-grey-0;
        font-size: .8em;
        padding-bottom: 5px;
        font-weight: bold;
    }

    & .ttip-info {
        color: $txt-brand;
        font-size: .8em;
    }
}

// Estils per a layoutLib

// RAG - fa que el contigut sigui flexible i es posicioni be.
.flex-content {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    padding-left: 5px;
    min-width: 200px;
}

//RAG - modifica l'alçada de la row quan és la primera despres de win-module-container. Això elimina  l'espai
// de la row quan s'obra el canvas. No importa en el dashboard donat que la primera row te contingut, però si
// en el canvas donat que es manté l'estructura original i hi ha una row de mes buida.
.win-module-container-dashboard > .s-row.flex-content {
    min-height: 0;
    margin-bottom: 0;
    margin-top: 0;
    }

///defineix una cel·la del layout
///* IMPORTANT: El margin-left i right han de casar amb el que es defineix a config.wMarginCells
//   NOTA: No posem 'height' aquí, doncs ha de venir determinada pel component (generalment CInfoBox)
//             que anirà dins de la cel·la. Tampoc posem 'width', ja vindrà determinada pel infoBox.


.lay-cell {
    position: relative;
    padding: 0;
    border: 2px solid $grey-3;
    overflow: hidden;
    margin: 5px;
    flex-grow: 1;
    min-height: 100px;
    justify-content: flex-start;
    box-shadow: 2px 2px 8px $grey-2;
}


.lay-cell.cursor-pointer {
    position: relative;
    padding: 0;
    border: 2px solid $grey-3;
    overflow: hidden;
    margin: 5px;
    flex-grow: 1;
    min-height: 100px;
    justify-content: flex-start;
    box-shadow: 2px 2px 8px $grey-2;
    transition:all 1s;

    &:after,&:before{
        content:" ";
        width:1px;
        height:1px;
        position:absolute;
        border :0 solid $grey-3;
        transition:all 1s;
    }
    &:after{
        top:-1px;
        left:-1px;
        border-top: 2px solid $brand-color;
        border-left: 2px solid $brand-color;
    }
    &:before{
        bottom:-1px;
        right:-1px;
        border-bottom: 2px solid $brand-color;
        border-right: 2px solid $brand-color;
    }
    &:hover{
        cursor: pointer;
        box-shadow: 2px 2px 8px $grey-0;

        &:before,&:after{
            width:100%;
            height:100%;
        }
    }
}

.lay-cell > .ib {
    padding-top: 3px;
}

.lay-cell .ib-txt {
    margin: 10px 0 5px 15px;
    font-family: $special-font;

    .ib-txt-special {text-shadow: 1px 1px 1px $txt-grey-0;}

    .ib-value {
        font-size: 2em;

        .over-canvas {
            padding: 0;
        }
    }
    .ib-name {
        font-size: 1.2em;
        color: $txt-grey-0;

        .over-canvas {
            padding:0;
        }
    }

    &.over-canvas {
        text-shadow: none;
    }
}

.lay-cell.cursor-pointer > canvas.ib-canvas {
    position: absolute;
    margin: auto;
    display: block;
    bottom: 10px;
    padding-right: 5px;
}

.lay-cell > canvas.ib-canvas {
    position: relative;
    margin: auto;
    display: block;
    bottom: 0;
}

canvas ~ .ib-txt  {
    margin-left: 30px !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    position: relative;
    font-family: $base-strong-font;
}

canvas ~ .ib-name  {
    margin-top: 5px !important;
}

canvas ~ .ib-value  {
    margin-top: 15px !important;
    font-weight: bold;
}

canvas ~ .ib-icon .icon-flex {
    width: 50px !important;
    float: left !important;
    margin-top: 5px !important;
    opacity: .7 !important;
}

.lay-cell .ib-icon {
    width:100%;
    text-align: right;

    & .icon-flex {
        width: 80px;
        margin: 0;
        float: right;

        &.icon-flex:hover {
            background-color: transparent;
        }
    }
}

.lay-cell > .s-row.ib-header  {
    @include position-all(absolute, 0, null, null, 0);
    @include div-size(100%, 50px);
    @include divStyle($grey-4, $border-normal $grey-3, null);
    font-family: $special-font;
 }

.ib-header .ib {
    //width: 100%;
}

 .ib-header .ib-txt.over-canvas {
    margin: 0;
    font-family: $special-font;
     display: inline-block;

    .ib-value {
        font-size: 2em;
        text-align: right;
        margin: 5px 10px 0 0;
        display: inline-block;
        width: auto;
    }

    .ib-name {
        color: $txt-grey-0;
        font-size: 2em;
        margin: 5px 0 0 10px;
        display: inline-block;
        width: auto;
    }
}

.ib-header  {
    display: inline-block;

    & .ib-icon {
        text-align: left;
        display: inline;
        margin-right: 20px;

        .icon-flex {
            width: 48px;
            margin: 0;
            float: left;
        }

        .ib-icon-close {
            text-align: right;
            display: inline;
            margin-right: 5px;
            float: right;

            & .icon-flex {
                width: 45px !important;
                margin: 5px;
                float: right;

                &.icon-flex:hover {
                    cursor: pointer;
                    background-color: $yellow !important;
                }
            }
        }
    }
}

/// IMPORTANT => Aquí estem seleccionat elements que tenen .ib-value i a la vegada .no-icon. Contrastar amb ttip-val
.ib-value.no-icon, .ib-name.no-icon {
    //left: 20px !important;
}

///CInfoBox - text de la dreta (el valor ex. "19:40")
.ib-right {
    top: 5px;
    right: 10px;
    text-align: right;
    font-size: 1.2em;
}

/* ==================================== Estils per a spinner ============================== */
.spin-internal {
    @include position-all(fixed, 50%, null, null, 50%);
    color: #888888;
}

