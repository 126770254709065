////////////////////////////
//
//  GRID Styles
//
////////////////////////////

.cgrid-root {
    @include position-all(absolute, 0, 0, 0, 0);

    .fltr-list-container & {
        top: 31px;
    }

    &.append-pointer {

        .gg-row {
            cursor: url(../svg/cur-sel-multi.svg), auto;
        }

        .gg-row.selected {
            cursor: url(../svg/cur-dessel-multi.svg), auto;
        }
    }

    #CELL_IMG[class*='ir-'] {
        cursor: pointer;
    }
}

.cform-item-widget .cgrid-root, .gg-container.calc-origins-container  {
    border: $border-normal $grey-2;
}

///////////////////////////////////////////////
// SCROLL ZONE
////////////////////////////////////////////
.cscroll-div-out {
    @include position-all(absolute, 0, 0, 0, null);
    width: 18px;
    margin: 0;
    padding: 0;
    overflow: auto hidden;

    &.searchbox {
        overflow-y: scroll;
        overflow-x: auto;
    }

    //This case is IMPORTANT: when no scroll is required for the grid, DIV_OUT that contains DIV_IN is moved to the right
    //to avoid problems when clicking on renderers icons that are painted to the right and DIV_OUT captures the click
    //The solution is, as DIV_OUT is hidden, its position is shifted to the right
    &.shift-right {
        right: -20px;
    }

    &.visible-header {
        top: 24px;
    }

    .cscroll-div-in {
        width: 1px;
        margin: 0;
        padding: 0;
        border: none;
    }
}

// posició botó selecció columnes - sobre l'scroll //
.win-column-btn {
    @include position-all(absolute, 0, 0, null, null);
    @include div-size(20px, null);
    display: inline-block;
    border-top: $border-normal $grey-4;
    border-left: $border-normal $grey-4;
    background-color: $grey-4;
    z-index: $layer-column-btn; //Col selector button must appear over headCell handle

    &:hover, &:focus {
        background-color: $yellow;
        outline: 0;
    }

    .calendar-right-box & {
        @include div-size(25px, 30px);
        right: 5px;
        top: 0;
        background-color: $grey-4;
        padding-right: 5px;
    }
}

/* topbar "right" will be fixed programatically*/
.topbar {
    @include position-all(relative, 0, 0, null, 0);
    height: 34px;
    background-color: $grey-5;
    overflow: hidden;
    padding-top: 5px;
    padding-left: 2px;
    display: flex;

    .win-mini-container & {
        //padding: 0;
        background-color: transparent;
    }
}

//IMPORTANT: ROW background-color needs to be applied via 'style' (not via class)
// This style 'row-selected' is not applied to selected row, only its 'background-color' is done via 'style:background-color = ...'
.row-selected {
    background-color: $grid-row-selected-color;
}

////////////////////////////
//
//  Internal 'grid' for presentation of cells, substituting <table> structure
//
////////////////////////////

.gg-root {
    @include position-all(absolute, 0, 0, 0, 0);
    overflow: hidden;
    border-top: 1px solid $grey-3;
    border-bottom: 1px solid $grey-3;

    &.searchbox {
        top: 34px
    }
}

// Required to preserve 20px for the grid scrollbar (cscroll-div-out)
.gg-viewport {
    @include position-all(absolute, 0, 0, 0, 0);
    @include box-sizing(border-box);
    overflow-y: hidden; // Allows horizontal scroll

    &.right-18 {
        right: 16px;
    }
}

.gg-container {
    @include box-sizing(border-box);
    background-color: $white;
    overflow: hidden; //must be hidden (gg-viewport allows horiz scroll)
    position: relative;// When horizontal scroll is not visible (combos, grid widgets, etc) GRID_CONTAINERS must be positioned as 'relative'
    font-size: 12px;
    line-height: 24px;
    font-family: $base-font;
    //IMPORTANT: by default "left & right" must not be fixed to allow horizontal scroll

    //Only for CWinGrid, where columns can be added, position must be absolute
    &.absolute {
        position: absolute;
    }

    .cform-item-widget & {
        border-left: none;
    }

    &.cierres {
        line-height: 12px;
    }
}

#CELL_IMG[class*='ir-'] {
    cursor: pointer;
}


/////////////////////////////////////////////////
// ROW - Grid
//////////////////////////////////////////////////

.gg-row {
    @include box-sizing(border-box);
    @include divStyle(transparent, null, null);
    border-collapse:  collapse;
    white-space: nowrap;
    font-family: $base-font;
    font-size: 12px;

    &:hover, &:hover:nth-child(odd) {
        background-color: $grid-row-hover-color;
    }

    &:hover.selected {
        background-color: $grid-row-hover-selected-color;
    }

    &:hover.selected:hover {
        background-color: $grid-row-hover-selected-hover-color;
    }

    &:nth-child(odd),
    .intervals-list &:nth-child(odd) {
        background-color: $grey-5;
        border-top: $border-invisible;
        border-bottom:  $border-invisible;
    }

    &.selected,  &:nth-child(odd).selected {
        background-color: $grid-row-selected-color;
    }

    .intervals-list & {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .calc-origins-container & {
        height: 24px;
    }
    .cierres-list & {
        height:  24px;
    }
}

.cierres-list  {
    line-height: 1.7;

    & .txt-brand {
        @include divStyle(null, null, $txt-brand);
    }

    & .txt-grey-1 {
        @include divStyle(null, null, $txt-grey-1);
    }
}


//out of validity row
.gg-row.lapsed {
    opacity: 0.5;
}

.gg-row.disabled {
    opacity: 0.5;
}


.gg-row-header {
    @include box-sizing(border-box);
    @include divStyle($grey-4, null, $txt-grey-0);
    border-collapse:  collapse;
    white-space: nowrap;
    height: 24px;

    .s-row.gg-row-header {
        @include divStyle($grey-4, null, $txt-grey-0);
        font-family: $special-font;
        font-weight: bold;
        margin: auto;
        min-height: 24px;
        height:  24px;

        &:hover {
            @include divStyle($grey-4, $border-invisible, null);
            cursor: default;
        }
    }

    .gg-head-cell {
        @include box-sizing(border-box);
        @include divStyle(null, null, $txt-grey-0);
        @include div-size(null, auto);
        @include ellipsis-text;
        position: relative;
        display: inline-block;
        border-right: $border-normal $grey-3;
        cursor: pointer;
        text-align: center;
        text-indent: 0;

        .cierres-list .gg-head-cell {
            text-indent: 0;
        }

        .gg-head-text {
            @include text($special-font, 12px, null, bold, null, $txt-grey-0);
            @include ellipsis-text;
            width: auto;
            cursor: pointer;
            display: inline;
            position: relative;
        }

        .wgt-box .gg-head-cell, .wgt-wrapper .gg-head-cell,
        .wgt-box .gg-head-text, .wgt-wrapper .gg-head-text {
            cursor: default;
        }
    }
}

.header-grid-sticky {
    position: sticky;
    top: 0;
    z-index: $header-sticky;
    margin-bottom: 0;
}

.head-cell-order {
    @include div-size(auto, auto);
    transform: rotate(90deg);
    margin: auto;
}

.gg-head-cell-handle {
    @include div-size(10px, 100%) ;
    @include position-all(absolute, 0, 0, null, null);
    cursor: ew-resize;
    background-color: $white;
    opacity: 0.05;
}

.gg-cell, .gg-tree-cell {
    @include box-sizing(border-box);
    @include ellipsis-text;
    @include div-size(100%, 100%); // JDS 24px
    position: relative;
    display: inline-block;
    border-right: $border-normal $grey-3;
    text-indent: 5px;

    .intervals-list & {
        text-align: center;
        height: 24px;
    }

    .cierres-list & {
        text-indent: 0;
    }

    .mov-detail-row &, .win-view-combo-zone &,
    .cb-button & {
        border-right: $border-invisible;
        text-indent: 0;
    }

    .mov-detail-row .activators-grid-position & {
        text-indent: 5px;
    }

    .cform-row & {
        border-right: $border-invisible;
    }
}

.gg-row {
    .gg-cell,
    .gg-tree-cell {
        .triangle-wrap {
            @include position-all(absolute, 0, null, 0, null);
        }

        & > img {
            @include div-size(20px, 20px);
            @include position-all(absolute, 0, 0, 0, null);
        }
    }
}

.gg-tree-cell {
    border-right: none;

}

.gg-cell.gg-cell-level-0 {
    @include ellipsis-text;

    .selected & {
        background-color:  transparent;
    }
}

[class*='tree-level-'] {
      @include ellipsis-text;
  }

////////////////////////////
//
//  TREE STYLES - estils de l'arbre
//
////////////////////////////

.tree-dimensions {
    width: $grid-tree-tab-width;
}

.tree-arrow {
    @include padding(2px, null, null, 4px);
    position: absolute;
    color: $txt-grey-0;
    font-size: 16px;
    display: table-cell;
    text-align: center;

    &:hover {
        background-color: grey;
    }
}


.tree-check {
    @include div-size(15px, 15px);
    border: $border-normal $black;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.row-base {
    display: block;
}

.row-panel {
    display: none;
}

.tree-first:hover .row-panel {
    display: block;
}
