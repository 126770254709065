////////////////////////////
//
// FILTER - 2  (llp)
//
////////////////////////////

.entity-block {
    display: inline-block;
    border: 1px dotted $dark-yellow;
    margin: 2px 4px;
    padding: 0 4px;

    &.selected {
        @include divStyle(transparent, $border-normal $dark-yellow, null);
        background-color: $yellow;
    }

    &:hover {
        text-decoration: underline;
        background-color: $yellow;
    }

    &.hovered { //DON'T CHANGE THE NAME (it is used in code)
        text-decoration: underline;
        background-color: $yellow;
        cursor: pointer;
    }
    //Required to wrap a single box (not a group of boxes)
    &.simple {
        border: none;
        margin: 0;
        padding: 0;
    }
}

.arith-block {
    display: block;
    position: relative;
    //border: none; //
    border: $border-invisible;
    margin-left: 2px;
    margin-top: 2px;
    margin-bottom: 2px;

    //Important: right margin must be supressed, this way all inner level blocks are adjusted to the right & click on block is better detected
    margin-right: 0;
    padding-right: 0;
    border-right: none;

    &.hovered { //DON'T CHANGE THE NAME (it is used in code)
        background-color: $grey-4b;
    }
     &.selected {
        border: 1px dashed $brand-color;
        cursor: pointer
    }
}

.arith-box-inline {
    display: inline-block;
    font-weight: bold;
    padding-right: 2px;
    font-family: $special-font;
    margin-right: 4px;
}

.arith-box-new-line {
    display: block;
    font-weight: bold;
    color: $inc-orange;

    &.endif {
        color: $inc-green;
    }
}

.arith-if {
    display: block;
    position: relative;
    border: $border-invisible;
    border: none;
    padding-left: 2px;
    color: $inc-green;

    //Important: right margin must be supressed, this way all inner level blocks are adjusted to the right & click on block is better detected
    margin-right: 0;
    padding-right: 0;
    border-right: none;

    &.true {
        margin-left: 40px;
        border-left: 4px solid $inc-green;
    }

    &.false {
        margin-left: 40px;
        border-left: 4px solid $inc-orange;
    }

    &.hovered { //DON'T CHANGE THE NAME (it is used in code)
        background-color: $grey-4b;
    }
}

.arith-tooltip {
    position: fixed;
    background-color: $tltp-back-color;
    z-index: $layer-tooltip;
    box-shadow: $tltp-box-shadow;
    animation: fade-in $fade-in-time;
    font-family: $base-font;
    font-size: 11px;
    padding: 5px 10px;
}

.arith-block-menu {
    @include position-all(absolute, 0, 0, null, null);
    padding: 0 2px;
    background-color: transparent;
}

.sub-block {
    display: block;
    padding: 2px;
}

//Container for box expression inside CWgtFilterButton
.wgt-cont-expression {
    height: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    border: $border-normal $grey-3;
}

.expression-box {
    @include box-sizing(border-box);
    @include position-all(absolute, 32px, 0, 0, 0);
    overflow: hidden;
    background: white;
    font-size: 12px;
}

.expression-subpanel {
    @include position-all(absolute, 2px, 2px, 2px, 2px);
    @include divStyle($white, $border-normal $grey-3, $txt-black);
    overflow: hidden auto;

    &.expr-error {
        background-color: rgb(255, 251, 250);
        border: $border-normal $error-color !important;
        box-shadow: 0 0 0 1px $error-color !important;
    }

    &.expr-error:focus-within {
        box-shadow: 0 0 0 1px $error-color !important;
        border: $border-normal $error-color !important;
        background-color: rgb(255, 251, 250);
    }

    &:focus-within, &.focused { // ".focused" is required for graph box (it is not an editbox, focus must be handled by program)
        border: $border-normal $brand-color;
        background-color: $white;
    }

    &.not-focused {
        background-color: $grey-4;
        border: $border-normal $grey-3;
    }

    &.help-intro {
        top: 50px;
        border: none;
        font-family: $base-strong-font;
        font-size: 12px;

        .help-text-box {
            width: 80%;
            padding: 1% 0;
            margin-left: 15%;

            .s-row {
                min-height: 0;
            }

            .help-Title {
                color: $txt-brand;
                font-weight: bold;
                font-size: 14px;
                overflow-wrap: break-word;
            }

            .help-txt {
                color: $txt-grey-1;
                text-align: left;
                margin-top: 2px;
                overflow-wrap: break-word;
            }

            .help-txt-brand {
                color: $txt-brand;
                font-weight: bold;
                overflow-wrap: break-word;
            }
        }
    }
}

.subpanel-textarea {
    background-color: $grey-4;

    &.expr-error {
        background-color: rgb(255, 251, 250);
    }

    &:focus {
        background-color: white;
    }
}

.subpanel-error {
    @include position-all(absolute, null,0,0,0);
    background-color: $white;
    padding: 6px;
    margin-left: 2px;
    margin-right: 2px;
    border-top: 2px solid $error-color;
    border-bottom: 2px solid $error-color;
}

.subpanel-error-info {
    display: inline-block;

    &.red {
        padding-left: 0;
        background-color: $error-color;
        color: $white;
        padding-right: 0;
        font-weight: bold;
    }
}

.fltr-fixed-overlay {
    position: fixed;
    background-color: $yellow;
    width: 60px;
    height: 20px;

    //When ovl-img is inside fltr-fixed-overlay (not & !!)
    .ovl-img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 2px;
        cursor: pointer;
    }

    .ovl-img:hover {
        background-color: $white;
    }
}

////////////////////////////
//
//  FILTER EDITOR WINDOW Styles
//
////////////////////////////

// Base contingut estructura fltr-grid
.fltr-win-base {
    @include position-all(absolute, 70px, 0, 0, 0);
    @include box-sizing(border-box);
    overflow: hidden;
    width: 100%;
    padding: 2px;
    display: block;
}

.fltrs-name-zone {
}

// Contenidor de llista de filtres

.fltr-list-container, .fltr-list-op-container, .fltr-list-val-container {
    @include div-size(100%, 350px);
    @include box-sizing(border-box);
    overflow: hidden auto;
    display: inline-block;
    font-size: 12px;

    .fltr-list-op-container {
                height: 284px;
            }

    .fltr-list-temp {
        font-size: 12px;
        overflow: hidden;
    }

    .fltr-list-val-container {
        height: auto;
    }
}

.fltr-list {
    border: $border-normal $grey-3;
    padding: 2px;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.fltr-col-title {
    background-color: $brand-color;
}

.col-title {
    @include text($special-font, 14px, 2, null, center, $txt-white);
    margin: auto;
    width: auto;
    display: block;
}

//When a filter panel has title & content is a CGrid, it must be wrapped under this style to allow title to be visible
.fltr-content {
    @include position-all(absolute, 30px, 0, 0, 0);
}
.fltr-content-space-bottom {
    @include position-all(absolute, 30px, 0, 36px, 0);
}
.fltr-content-and-or-option {
    position: absolute;
    bottom: 0;
    margin: 2px;
}


////////////////////////////
//
// PANELL (VISOR DE FORMULES) -> Estils del calcul
//
////////////////////////////

.fltr-txt-and, .fltr-txt-or, .fltr-txt-valor,
.fltr-txt-neutral, .fltr-txt-function,
.fltr-txt-field, .fltr-txt-operators, .fltr-txt-tempOp, .fltr-txt-time,
.fltr-txt-missing {
    @include text($base-strong-font, null, 2, null, center, null);
    display: inline-block;
    margin: 0 2px;
    padding: 0 2px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        background-color: $yellow;
    }

    &.selected {
        @include divStyle(transparent, $border-normal $dark-yellow, null);
        background-color: $yellow;
    }

    &.missing {
        background-color: $error-color;
        color: $txt-white;
    }
}

.fltr-txt-operators, .fltr-txt-and, .fltr-txt-or {
    @include text($base-strong-font, null, null, null, center,$txt-black);
    text-transform: uppercase;
    :not(.hovered) {
        background-color: $grey-4b;
    }
    min-width: 12px;

    &.missing {
        background-color: $error-color;
        color: $txt-white;
    }
}

//Used only when the group is incomplete (a field, operator or value is missing)
.fltr-txt-missing {
    color: $txt-brand;
    text-transform: uppercase;
    background-color: $yellow;
}

.fltr-txt-function {
    color: $txt-brand;
}

.fltr-txt-tempOp {
    color: $inc-blue;
    }

.fltr-txt-field {
    color: $txt-field;
}

.fltr-txt-valor {
    color: $brand-color
}

.fltr-txt-time {
    color: $txt-black;
}

.fltr-txt-neutral {
    color: $txt-black;
}


@keyframes blink-cursor {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fltr-graph-cursor, .fltr-hole {
    @include text(null, null, 2, null, center, null);
    display: inline-block;
    margin: 0;
    cursor: pointer;
    width: 8px;
    height: 17px;
    position: relative;
    top: 4px;
    animation: blink-cursor 1s alternate infinite;
    background-color: $brand-color;
}

.fltr-hole {
    //the same base styles as "fltr-graph-cursor"
    background-color: transparent;
    border: 1px dashed $brand-color;
    animation: none;

    &:hover {
        background-color: $yellow;
        border: none;
    }
}

.tempOp-panel-btn {
    position: absolute;
    top: 4px;
    width: 20px;
    height: 20px;

    &.close {
        right: 4px;
    }

    &.remove {
        right: 30px;
    }
}

.fltr-insert-row {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 30px;

    //Style for every option in CInsertEntityPanel
    .fltr-insert-option {
        @include divStyle(transparent, $border-normal $brand-color, null);
        @include text($base-strong-font, null, 2, null, center, null);
        margin: 0 4px;
        display: inline-block;
        height: 26px;
        padding: 0 4px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            background-color: $yellow;
        }

        & .fltr-txt-field {
            color: $txt-field;
        }

        & .fltr-txt-time {
            color: $txt-black;
        }

        & .fltr-txt-neutral {
            color: $txt-black;
        }

        & .fltr-txt-function {
            color: $txt-brand;
        }

        & .fltr-txt-tempOp {
            color: $inc-blue;
        }

        &.fltr-math {
            min-width: 20px;
        }

        & .txt-inc-green {
            color: $inc-green;
        }

        & .txt-inc-orange {
            color: $inc-orange;
        }
    }
}


// LLISTA FILTRES APLICATS
// Contenidor de la llista desplegable de filtres aplicats
.filter-list-container {
    width: 300px;
    max-height: 250px;
    overflow: hidden auto;
}

//MINI-PANEL (llista desplegable) -> Espai de formula dels filtres (No editable) + scroll horitzontal visible
.filter-mini-panel {
    @include position-all(absolute, 38px, null, null, 10px);
    @include div-size(95%, auto);
    @include divStyle($grey-5, $border-normal $brand-color, $txt-grey-0);
    @include text(null, 12px, 2, null, null, null);
    white-space: nowrap;
    text-overflow: inherit;
    text-indent: 5px;
    margin: 0;
    padding: 2px;
    display: inline-block;
    overflow: scroll hidden;
}

.g-win-panel-content1 .filter-mini-panel-title {
    @include text($special-font, 12px, null, null, center, $white);
    overflow: hidden;
    margin: auto;
    padding: 4px 8px;
    background-color: $brand-color;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;

    & .filter-mini-panel-txt-title {
        display: inline;
        margin-left: 40px;
        line-height: 2;
    }

        & .filter-mini-panel-btn {
        width: 60px;
        display: inline;
        float: right;
        text-align: right;

        & .filter-mini-panel-close {
            @include div-size(20px, 20px);
            display: inline-block;
        }

        & .filter-mini-panel-undo {
            @include div-size(20px, 20px);
            display: inline-block;
            margin-right: 5px;
        }
    }
}



//MINI-PANEL -> Estils dels operadors del calcul (and i or)
.cb-txt-and, .cb-txt-or {
    @include divStyle($grey-4, null, $txt-warning);
    @include text(null, 12px, null, bold, left, null);
    display: inline-block;
    text-decoration: underline;
    padding-right: 5px;
    cursor: pointer;

    &:hover {
        background-color: $yellow;
    }
}

.cb-txt-or {
    color: $txt-brand;
}
