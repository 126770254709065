////////////////////////////
//
//  RENDER Styles
//
////////////////////////////
// Styles for several item renderers, ex: IncidenciasIR, BooleanIR, etc

.ir-access {
    @include div-size(12px, 12px);
    display: inline-block;
    margin-top: 4px;
}

.ir-hor-centered {
    text-align: center;
    margin: auto;

    .mov-detail-row & {
        line-height: 1.6;
    }
}

.ir-incidencias, .ir-jornadas {
    @include div-size(12px, 12px);
    display: inline-block;
    margin-top: 4px;
    border-radius: 20px;
    border: $border-normal $grey-3b;

    .cb-button.disabled & {
        opacity: .5;
    }

    .cb-button.deleted & {
        opacity: .3;
    }
    &.workflow {
        @include margin(1px, 4px, null, null);
    }
}

.ir-jornadas {
    border-radius: 0;
}

.ir-simple-icon {
    display: inline-block;

    &:hover {
        background-color: $yellow;
    }

    .gg-row.selected &:hover{
        background-color: $white;
    }

    .cierres-list & {
        //margin-top: 3px;
    }
}

.ir-simple-icon.pointed {
    cursor: pointer;

    &.disabled {
        opacity: 0.4;
        cursor: default;

        &:hover {
            background-color: transparent;
        }

    }
}

// TEMPORAL: when a renderer is not yet created, a div with this special style is displayed
.ir-undefined {
    display: inline-block;
    border: 2px solid #ffdfde;
}

// CIntervals component
//List of intervals (component CIntvList)
.intervals-list {
    overflow-y: auto;
    min-width: 300px;
    height: auto;

    .cform-row & {
        height: 179px;
    }
}

// CCierres component

.cierres-toolbox {
    position: absolute;
    height: 35px;
    top: 0;
    bottom: 0;
    padding-left: 5px;
    }

// List of Cierres (component CCierresList)
.cierres-list {
    @include div-size(auto, 100%);
    overflow-y: auto;
}

