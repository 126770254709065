////////////////////////////
//
//  TOOLTIP Styles
//
//////////////////////////////

.calendar-day-tooltip, .mov-tooltip {
    @include box-sizing(border-box);
    min-width: 50px;
    max-width: 450px;
    width: 80%;
    padding: 0;
    background-color: $tltp-back-color;
    position: absolute;
    z-index: $layer-tooltip;
    box-shadow: $tltp-box-shadow;
    animation: fade-in $fade-in-time;
    float: none;

    /* custom-calendar is applied to Gestor Accessos tooltip*/
    &.custom-calendar {
        width: auto;
    }
}

.mov-tooltip{
    width: auto;
}

.calendar-day-tooltip .tltp-right, .mov-tooltip .tltp-right {
    @include margin(2px, 2px, null, null);
}

.calendar-day-tooltip .tltp-right i, .mov-tooltip .tltp-right i {
    @include position-all(absolute, 5px, 100%, null, null);
    @include div-size(12px, 24px);
    overflow: hidden;
}

.calendar-day-tooltip .tltp-right i::after, .mov-tooltip .tltp-right i::after {
    @include position-all(absolute, 50%, null, null, 0);
    @include div-size(12px, 12px);
    content:'';
    transform:translate(50%,-50%) rotate(-45deg);
    background-color: $tltp-back-color;
    box-shadow: $tltp-box-shadow;
}

//CONTENT TOOLTIP
.calendar-day-tooltip .title, .mov-tooltip .title {
    @include text($special-font, 11px, 12px, bold, center, $txt-grey-0);
    margin: auto;
    display: block;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: $border-normal $grey-2;
}

.calendar-day-tooltip .text-content, .mov-tooltip .text-content  {
    @include text($special-font, 10px, null, normal, null, $txt-grey-0);
    padding: 5px 5px;
    border: $border-invisible;
}

.mov-tooltip .text-content {
    color: $txt-black;
    border: none;
}

.marked {
    margin-bottom: 0;
    padding-bottom: 5px;
    border: $border-normal rgba(255, 235, 114, .7);
}

.tooltip-item-label {
    @include text($special-font, 10px, null, bold, null, $txt-grey-0);
    display: inline-block;
    padding-left: 2px;
    margin: auto;
}

.calendar-day-tooltip .calendar-list-item-quad {
    @include div-size(12px, 12px);
    margin: auto;
    display: inline-block;

    &.alt_shift {
        border-radius: 0 60% 0 0 / 0 45% 0 0;
    }

    &.timeType {
        border-radius: 50%;
    }

    & img {
        width: 14px;
    }

    &.img {
        width: 14px;
    }
}


.cform-item-label[tooltip]:before {
    @include trans-all(all 0.15s ease);
    position : absolute;
    content : attr(tooltip);
    opacity : 0;
    display: none;
    padding: .7em;
    border-radius: 2px;
    box-shadow: 2px 2px 1px $grey-1;

}

.cform-item-label[tooltip]:hover:before {
    @include divStyle(#fce4e4, $border-normal #cc0033, #333);
    @include margin(-3em, null, null, 1em);
    display: inline-block;
    opacity : 1;
    z-index: 1;
}


//////////////////////////////////
//
//  Expression Tooltip
//
///////////////////////////////////

.tltp-panel-table {
    width: 80%;
    height: auto;
    font-family: $base-strong-font;
    font-size: 10px;

    .tltp-panel-caption, .tltp-panel-head {
        text-align: center;
        font-weight: bold;
    }

    .tltp-panel-head {
        text-transform: uppercase;
    }


}
