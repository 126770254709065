////////////////////////////
//
//  LOADER Styles - recurso de transición
//
////////////////////////////

.loader {
    @include position-all(absolute, 45%, null, null, 45%);
    @include div-size(100px, 100px);
    transform: translate(-50%, -50%);
    border: 14px solid $grey-4;
    border-radius: 50%;
    border-top: 14px solid $grey-2;
    border-right: 14px solid $grey-1;
    border-bottom: 14px solid $brand-color;
    animation: spin 2s linear infinite;
}

.loader-holder {
    @include position-all(absolute, 0, 0, 0, 0);
    @include div-size(100%, 100%);
    background-color: $loader-bg-color;
    z-index: $layer-loader;
    -webkit-animation: fadein 1s linear 1 normal forwards;
    -moz-animation: fadein 1s linear 1 normal forwards;
    animation: fadein 1s linear 1 normal forwards;
}

.grid-loader-holder {
    @include position-all(absolute, 0, null, null, 0);
    height: 100%;
    display: inline-block;
    z-index: $layer-menu;
    background-color: #ffffff6b;
    -webkit-animation: fadein 1s linear 1 normal forwards;
    -moz-animation: fadein 1s linear 1 normal forwards;
    animation: fadein 1s linear 1 normal forwards;

    .loader {
        @include div-size(50px, 50px);
        @include position-all(null, 38%, 0, null, 0);
        margin: 0 auto;
        border: 7px solid $grey-4;
        border-top: 7px solid $grey-2;
        border-right: 7px solid $grey-1;
        border-bottom: 7px solid $brand-color;
    }
}

////////////////////////////
//
// SPINNER
//
////////////////////////////

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

//Text finestra llistat - progres.

.loader-message {
    text-align: center;
    color: $txt-brand;
    font-size: 2em;
    margin-top: 2em;
}

.blinking {
    animation: blinking 1.5s linear infinite;
}
