@charset "UTF-8";
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/Roboto-Regular-webfont.woff') format('woff');
    src: url('../fonts/Roboto-Regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light-webfont.woff') format('woff');
    src: url('../fonts/Roboto-Light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../fonts/OpenSans-Regular-webfont.woff') format('woff');
    src: url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import "_variables.scss";

// 'Roboto font' - Copyright 2004 Apache Software Foundation
// Licensed under the Apache License, Version 2.0;

html {
    font-family: $base-font;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

img {
    vertical-align: middle;
}

p {
    margin: 15px 0;
}

body {
    line-height: 1.2;
    margin: 0 auto;
    padding: 0;
    position: relative;
    font-size: 14px;
    font-family: $base-font;
    background-color: $app-back-color;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

h1, h2, h3, h4, h5, h6 {
    color: $txt-brand;
}

small {
    font-size: 10px;
}

b, strong {
    font-weight: bold;
}

a {
    @include trans-all(all ease-in-out .2s);
    color: $txt-brand;
    text-decoration: none;
}

a:hover {
    color: $txt-brand;
}

.left {
    float: left;
}

.right {
    float: right;
}

.alignleft {
    float: left;
    margin-right: 15px;
}

.alignright {
    float: right;
    margin-left: 15px;
}

.clearfix:after, ol, ul {
        list-style: none;
}

blockquote, q {
    quotes: none;
}

.centered {
    text-align: center !important;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right !important;
    padding-right: 5px; //text-indent doesn't work for right align
}

// Inline
.inline {
    display: inline-block;
}

// Inline-flex
.inline-flex {
    display: inline-flex;
}

// txt-color
.txt-brand {
    color: $txt-brand !important;
}

.txt-warning {
    color: $txt-warning;
}

.txt-error {
    color: $txt-error;
}

.txt-grey-2 {
    color: $txt-grey-2;
}

.txt-grey-3 {
    color:$txt-grey-3;
}

// time-type color
.inc-green {
    background-color: $inc-green;
}

.txt-inc-green {
    color: $inc-green;
}

.inc-blue {
    background-color: $inc-blue;
}

.txt-inc-blue {
    color: $inc-blue;
}

.inc-yellow {
    background-color: $inc-yellow;
}

.txt-inc-yellow {
    color: $inc-yellow;
}

.inc-orange {
    background-color: $inc-orange;
}

.txt-inc-orange {
    color: $inc-orange;
}

//color
.color-brand {
    color: $txt-brand;
}

.color-white {
    color: $txt-white;
}

.color-grey {
    color: $grey-3;
}

.color-orange {
    color: $warning-color !important;
}



.row_logo_img {
    @include margin(1em, null, 3em, null);

    .win-module-container & {
        @include margin(1em, null, 1em, null);
    }
}

.brand-image {
    @include div-size(35%, auto);
    max-width: 45%;
    min-width: 20%;
    text-align: center;
    margin: auto;
}

.login-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .login-header {
        height: 5%;
        width: 100%;
        align-self: flex-start;
    }

    .login-body {
        display: block;
        height: 90%;
        max-height: 600px;
        width: 90%;
        max-width: 600px;
        transition: max-height 0.25s ease-in;
        background-color: #fafafa;
        box-shadow: 2px 2px 50px $grey-3;
    }

    .login-footer {
        width: 100%;
        align-self: flex-end;
        padding: 5px;
        display: flex;
        align-content: flex-end;
        justify-content: flex-end;

        .copyright-footer {
            text-align: end;
            font-size: 10px;
            color: $txt-grey-1;
            align-self: flex-end;

            .win-module-container & {
                display: none;
            }
        }
    }
}


////// Inner Form ///

.login-form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 100%;

    .login-form-header {
        margin: 1em .5em .5em .5em;

        .login-logo {
            text-align: center;
            margin-bottom: 1em;
        }
    }

    .login-form-body {
        position: relative;
        height: 100%;
        overflow: auto;
        text-align: center;
        padding: .75em;
        @include divStyle($app-back-color, null, $txt-brand);

        .login-group {
            margin: 1em .5em 1em .5em;

            &.with-child {
                margin: 0;
            }

            &.flex-row {
                display: flex;
                flex-direction: row;
                align-self: center;
                justify-content: space-between;

                input {
                    flex: 1;
                }

                label {
                    flex-basis: 30%;
                    padding: .4em;
                }

                button {
                    margin-left: .25em;
                }

                .checkbox {
                    margin-top: .3em;
                }
            }

            label {
                padding: .5em;
            }
        }
    }

    .login-form-footer {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: space-between;
        width: 100%;
        padding: 5px 5px 10px 5px;

        button:not(:last-child){
            margin-right: 5px;
        }
    }
}


.win-internal .login-wrapper {
    height: 100%;
    overflow: auto;
}

.login_pos_label {
    margin-top: 2em;

    &.buttons {
        margin-top: 2em;
    }
    .win-module-container & {
    }
    & .s-row {
        min-height: 0;
    }
}

.login_label {
    @include divStyle(transparent, null, $txt-brand);
    @include text($special-font, 1em, null, null, left, null);
    display: block;
    vertical-align: middle;

    &.supervisor {
        display: inline-flex;
        margin-right: .5em;
    }

    &.link {
        cursor: pointer;
    }
    &.link:hover {
        color: $txt-warning;
    }

    &.user {
        margin: auto;
    }

    &.login_title {
        @include text($special-font, 1.2em, 1em, bold, center, $brand-color);
        margin: auto;
        text-transform: uppercase;
        width: 100%;
        padding-bottom: .35em;
    }

    &.qa {
        color: $qa-color ;
    }

    &.dev-act-list {
        color: $txt-grey-1;
        line-height: 1.5em;
    }
}

.activation-device-picker {
    border: $border-invisible;

    &:hover {
        border: $border-normal $grey-3;
    }
}
.icon-btn.dev-act-list {
    margin-top: 4px;

    &:hover {
        background-color: $yellow;
        cursor: pointer;
    }
}

.area-text {
    @include divStyle($white, $border-normal $grey-2, $txt-black);
    display: inline-block;
    overflow: auto;
    font-size: 12px;
    text-align: left;
    padding: 4px;
    width: 100%;
    resize: none;

    &:hover, &:focus {
        border: $border-normal $brand-color;
        outline: none;
    }
}

// .input - estil base - text i search
.input-login[type='text'],
.input-login[type='password'] {
    @include div-size(100%, 24px);
    @include divStyle($white, $border-invisible, $txt-black);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance:    none;
    display: block;
    overflow: hidden;
    font-size: 12px;
    text-align: left;
    text-indent: 4px;
    font-family: $base-font;
    border-bottom: $border-normal $grey-3;

    &:hover  {
        @include divStyle(null, $border-normal $brand-color, null);
        outline: none;
    }
    &:focus {
        box-shadow: 0 1px $brand-color;
        border-bottom: $border-normal $brand-color;
        outline-offset: unset;
        outline: 0;
    }

    &.disabled {
        @include divStyle($grey-4, $border-normal $grey-4, null);
    }

    &:hover.disabled, &:focus.disabled {
        @include divStyle($grey-4, $border-normal $grey-4, null);
        outline: none;
    }
}

.label {
    width: 100%;
    display: block;
    overflow: hidden;
    color: $txt-brand;
    font-family: $special-font;
    text-align: left;
}

// MAIN opcions estàtiques - inferior dreta

.main-fixed-data-footer {
    @include div-size(auto, auto);
    @include position-all(absolute, null, 5px, 0, null);
    text-align: right;
    padding: 5px;
    font-size: 10px;

    .win-module-container & {
        display:  none;
    }
}

// CHECKBOX Start by hiding the checkboxes  //

label {
    float: inherit;
    overflow: hidden;
    margin: 0;
    color: $txt-brand;
}

input[type='checkbox'] {
    visibility: hidden;
}

.checkbox {
    position: relative;
}

// BOX - Create the box for the checkbox //

.checkbox label {
    @include div-size(20px, 20px);
    @include position-all(absolute, 0, null, null, 0);
    @include divStyle($white, $border-normal $grey-2, null);
    display: inline-block;
    cursor: pointer;
}

.checkbox:hover label,
.checkbox:focus label {
    border: $border-normal $brand-color;
}

// TICK - Display the tick inside the checkbox //

.checkbox label:after {
    @include div-size(18px, 9px);
    @include position-all(absolute, 2px, null, null, 2px);
    @include divStyle(transparent, 3px solid $brand-color, null);
    opacity: 0;
    content: '';
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
}

// Create the hover event of the tick //
.checkbox label:hover::after {
    opacity: 0;
}

// Create the checkbox state for the tick  //
.checkbox input[type='checkbox']:checked + label:after {
    opacity: 1;
    color: $txt-brand;
}

////////////////////////////
//
//ESTRUCTURA (s-row i s-col -> estandar;
//
////////////////////////////

.cmain_login {
    @include div-size(100%, 100%);
    position: fixed;
    overflow: hidden;
}

.main-body-back {
    @include div-size(auto, auto);
    display: flex;
    background-color: $app-back-color;
}

.s-grid-base {
    @include position-all(relative, 55px, 0, 0, 0);
    @include div-size(100%, 86%);
    @include box-sizing(border-box);
    overflow: hidden;

    .modal-win & {
        top: 80px;
    }
}

@media only screen and (max-width: 550px) {
    .s-col-1, .s-col-2, .s-col-3, .s-col-4,
    .s-col-5, .s-col-6, .s-col-7, .s-col-8,
    .s-col-9, .s-col-10, .s-col-11, .s-col-12 {
        float: none;
        width: auto;
    }
    .s-col + .s-col {
        margin-left: 0;
    }
}

@media only screen and (max-width: 600px) {

    .login-wrapper {
        .s-row {
            margin-bottom: 0;
        }
        [class*="s-col-"] {
            width: 100%;
        }
        .s-col {
            margin-bottom: 10px;
        }
        .s-row:last-child .s-col:last-child {
            margin-bottom: 0;
        }
        .login_form {
            font-size: .8em;
        }
        .main-fixed-data-footer {
            display: none;
        }
    }
}

////////////////////////////
//
// DEVICES ACTIVATION - styles
//
////////////////////////////

.devices-activation {
   min-width: 45%;
}

.dev-act-title {
    @include ellipsis-text;
    @include divStyle($grey-3, null, $txt-grey-1);
    font-size: 12px;
    text-align: center;
    font-family: $special-font;
    text-transform: uppercase;
    line-height: 30px;
    padding: 2px;
}

.m_auto {
    margin:auto;
}

// DEVICES ACTIVATION -

.tag {
    display: inline-block;
    padding: .3em;
    margin: .3em;
    border-radius: 2px;
    text-align: center;
    font-size: 12px;
    font-family: $special-font;
}

.bg-brand {
    @include divStyle($brand-color, null, $txt-white);
}

.bg-grey-1 {
    @include divStyle($grey-1, null, $txt-white);
}

.blink {
    color: $txt-warning;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% { opacity: 0; }
}


.full-width {
    width: 100%;
}

/// Display login by SSO

.sso-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.sso-txt {
    @include text ($special-font, 1.5em, null, bold, null, $brand-color);
    margin: .5em !important;
    width: 100%;
}

.sso-btn {
    background-color: $brand-color;
    border: $border-normal $brand-color;
    color: $white;
    margin: .5em !important;
    width: 45%;
    box-shadow: 2px 2px 15px $grey-2;

    img {
        width: 100%;
    }

    &:hover {
        width: 50% !important;
        transition: width .25s ease-in-out;
        box-shadow: none;
    }
}
