////////////////////////////
//
//  ACCORDION Styles
//
////////////////////////////

.acc-main {
    @include trans-width(width .25s ease-in-out);
    @include position-all(absolute, 26px, 1px, 1px, null);
    @include divStyle($grey-4, $border-normal $grey-3, null);
    display: flex;
    width: 140px;
    float: right;

    &.collapsed {
        width: 15px;
    }

    .cform-acc-container & {
        bottom: 40px;
    }
}

.acc-submenu {
    width: 97%;
    margin-left: 2px;
    font-size: 11px;
}

/* element del submenú (botó acció) desactivat */
.acc-root-item-disabled {
    cursor: none;
    opacity: 0.3;
}

.action-collapse-position {
    @include margin(null, 1px, null, 1px);
    height: 100%;
}

button.side-action-collapse {
    @include div-size(18px, 100%);
    @include divStyle($grey-1, $border-normal $white, null);
    margin-left:  -4px;

    &:hover {
        @include divStyle($brand-color, $border-normal $white, null);
    }
}


