////////////////////////////
//
//  MAIN MENU Styles
//
////////////////////////////
/* To apply only when instancing main horizontal menu */
.main-menu {
    @include position-all(fixed, 0, null, null, 0);
    width: 100%;
    z-index: $layer-menu;
}

/* General style to apply to every menu panel (hor or vert) */
.menu-panel {
    position: absolute;
    background-color: $grey-4;
    z-index: $layer-menu;
    display: inline-block;
    box-shadow: 1px 1px 2px $grey-1;
    width: max-content;

    .cform-topbar & {
        margin-top: 2px;
        right: 22px;
        box-shadow: none;
    }
}
//panel filter-expressions
.menu-panel-expr {
    position: absolute;
    background-color: $white;
    z-index: $layer-menu;
    display: inline-block;
    box-shadow: 1px 2px 2px $grey-1;
    width: max-content;
    border: $border-normal $brand-color;
    margin-top: 1px;
}

// WARNING: Helper classes for positioning submenus
.menu-item-hor > .submenu {
    position: absolute;
}

.menu-item-vert > .submenu {
    @include position-all(absolute, 0, null, null, null);
}

// A submenu of a menú item needs a 100% width
.submenu {
    width: 100%;
}

.menu-item-hor {
    float: left;
    margin: 1px;
    display: inline-block; // inline-block pq cada item es quedi en la mateixa fila
}

.menu-item-vert {
    position: relative;
    margin: 0;
    padding: 0;
    display: block; /* 'block' perquè hi hagi un item vertical per cada fila */
}
