////////////////////////////
//
//  CHECKBOX - Styles
//
////////////////////////////

label {
    @include margin(0, 0, 5px, 5px);
    float: inherit;
    overflow: hidden;
    text-align: right;
    color: $txt-brand;
}

// CHECKBOX Start by hiding the checkboxes  //

input[type='checkbox'] {
    visibility: hidden;
}

.checkbox {
    @include div-size(30px, 30px);
    @include margin(0, 0, 0, 0);
    position: relative;
}

// BOX - Create the box for the checkbox //

.checkbox label {
    @include div-size(20px, 20px);
    @include position-all(absolute, 0, null, null, 0);
    @include divStyle($white, $border-normal $grey-3, null);
    display: inline-block;
    cursor: pointer;
}

.checkbox:hover label,
.checkbox:focus label {
    @include divStyle(null, $border-normal $brand-color, null);
}

// TICK - Display the tick inside the checkbox //

.checkbox label:after {
    @include position-all(absolute, 2px, null, null, 2px);
    @include div-size(18px, 9px);
    @include divStyle(transparent, 3px solid $brand-color, null);
    opacity: 0;
    content: '';
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
}

// Fons del checkbox quan es treuen alguns elements de la selecció //
.checkbox.partial-selected label {
    background: $white;
}

// Display the box inside the checkbox (quan es treuen elements de la selecció) //
.checkbox.partial-selected label:after {
    @include position-all(absolute, 5px, null, null, 5px);
    @include div-size(8px, 8px);
    @include divStyle($brand-color, $border-invisible, null);
    opacity: 0;
    content: '';
    transform: rotate(0deg);
}

// Create the hover event of the tick //
.checkbox label:hover::after {
    opacity: 0;
}

// Create the checkbox state for the tick  //
.checkbox input[type='checkbox']:checked + label:after {
    opacity: 1;
    color: $txt-brand;
}

// SMALL CHECKBOX & ALL-CHECK (check x seleccionar tots elements llista) //
.small-checkbox, .all-check, .enroll-dev-check {
    @include div-size(20px, 20px);
    @include margin(0, 0, 0, 0);
    position: relative;

    // Variant: quan l'small-checkbox es dins d'un cform o es un item d'un panell
    .gg-row &, .g-win-item &, .generic-list-item & {
        display: inline-block;
    }
}

.enroll-dev-check {
    @include div-size(16px, 16px);
    @include margin(0, 0, 0, 0);
}

// small-checkbox quan esta dins una cel·la
.gg-cell .small-checkbox {
    top: 3px;

    .cierres-list & {
        top: 2px;
    }
}

.tree-level-2 .enroll-dev-check {
    top: 1px;
}

.small-checkbox.inside-tree {
    @include position-all(absolute, 4px, null, null, null);
    //NOTE: 'left' is assigned by code
}

// Create the box for the checkbox
.small-checkbox label, .all-check label, .enroll-dev-check label {
    @include position-all(absolute, 0, null, null, 0);
    @include div-size(15px, 15px);
    @include divStyle($white, $border-normal $grey-3, null);
    display: inline-block;
    cursor: pointer;

    &.disabled {
        @include divStyle($grey-4 !important, $border-normal $grey-4 !important, null);
        cursor: default;
    }
}

.enroll-dev-check label {
    top: 2px;
    @include div-size(16px, 16px);
}

.small-checkbox:hover label, .all-check:hover label,
.small-checkbox:focus label, .all-check:focus label,
.enroll-dev-check:focus label {
    border: $border-normal $brand-color;
}

.win-info-all-check .all-check label {
    top: 2px;
    right: 3px;
}

// Display the tick inside the checkbox
.small-checkbox label:after, .all-check label:after,
.enroll-dev-check label:after {
    @include position-all(absolute, 1px, null, null, 1px);
    @include div-size(14px, 7px);
    @include divStyle(transparent, 2px solid $brand-color, null);
    opacity: 0;
    content: '';
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
}

// Fons del checkbox quan es treuen alguns elements de la selecció
.small-checkbox.partial-selected label,
.all-check.partial-selected label {
    background: $white;
}

// Display the box inside the checkbox (quan es treuen elements de la selecció)
.small-checkbox.partial-selected label:after,
.all-check.partial-selected label:after {
    @include position-all(absolute, 3px, null, null, 3px);
    @include div-size(7px, 7px);
    @include divStyle($brand-color, $border-invisible, null);
    opacity: 0;
    content: '';
    transform: rotate(0deg);
}

// Create the hover event of the tick
.small-checkbox label:hover::after, .all-check label:hover::after,
.enroll-dev-check label:hover::after {
    opacity: 0;
}

// Create the checkbox state for the tick
.small-checkbox input[type='checkbox']:checked + label:after,
.all-check input[type='checkbox']:checked + label:after,
.enroll-dev-check input[type='checkbox']:checked + label:after {
    opacity: 1;
    color: $txt-brand;
}

////////////////////////////
//
//  RADIO-BUTTON Styles
//
////////////////////////////

// Etiqueta text radio-btn
.radio-label {
    @include text($special-font, 12px, null, null, null, $txt-grey-0);

    &:hover {
        color: $txt-brand;
    }
}

.radio {
    margin: 1px;

    .modal-wrapper & {
        margin: 0;
    }
}

// s'oculta el radio-btn original
.radio input[type="radio"] {
    position: absolute;
    opacity: 0;
}

// Estructura radio-btn + posició etiqueta
.radio input[type="radio"] + .radio-label:before {
    @include position-all(relative, 0, null, null, null);
    @include div-size(15px, 15px);
    @include divStyle($white, $border-normal $grey-3, null);
    display: inline-block;
    margin-right: 10px;
    border-radius: 100%;
    content: '';
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    transition: $radio-btn-trans;
}

//box-shadow - ombra interior en blanc per reduir l'espai background i faci l'efecte punt
.radio input[type="radio"]:checked + .radio-label:before {
    background-color: $brand-color;
    box-shadow: inset 0 0 0 3px $white;
}

.radio input[type="radio"]:focus + .radio-label:before {
    outline: none;
    border-color: $brand-color;
}

//en hover - box-shadow - augmenta l'ombra interior i redueix mes l'espai background
.radio input[type="radio"]:hover + .radio-label:before {
    border-color: $brand-color;
    background: $brand-color;
    box-shadow: inset 0 0 0 5px $white;
    outline: none;
}

// JDS - Even on disabled input, usually still wanna know its value or that there is something there
//
//.radio input[type="radio"]:checked:disabled + .radio-label:before {
//    border-color: $grey-4;
//    background: $grey-4;
//    box-shadow: inset 0 0 0 3px $white;
//}
//
//.radio input[type="radio"]:not(:checked):disabled + .radio-label:before {
//    border-color: $grey-4;
//    background: $white;
//    box-shadow: inset 0 0 0 3px $white;
//}
//
//.radio input[type="radio"]:disabled + .radio-label {
//    color: #ccc;
//}

.radio input[type="radio"] + .radio-label:empty:before {
    margin-right: 0;
}
