////////////////////////////
//
//  STANDARD MODAL Styles
//
////////////////////////////

.modal-wrapper {
    @include divStyle($grey-5, 3px solid $brand-color, null);
    min-width: 30em;

    &.expand {
        min-width: 38em;
    }
}

.modal-header {
    display: block;
    background-color: $brand-color;
    line-height: 30px;
    width: 100%;
}

.modal-body {
    display: block;
    margin: auto;
    width: 100%;
}

.modal-footer {
    @include margin(10px, null, 5px, null);
    display: block;
    border-top: $border-normal $grey-2;
    text-align: center;
    width: 100%;
}

.modal-title {
    text-align: center;
    font-size: 15px;
    background-color: transparent;
    text-transform: uppercase;
    width: 100%;
    font-family: $special-font;
}

.modal-title {
    color: $txt-white;
    text-align: center;
    line-height: 2;
}

.modal-msg-zone {
    color: $txt-error;
    font-size: 14px;
    font-family: $special-font;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    line-height: 25px;
}
