////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   WIDGET STYLES - cform
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.wgt-label {
    @include divStyle(transparent, null, $txt-grey-1);
    font-size: 12px;
}

.wgt-table {
    @include divStyle($white, $border-normal $grey-1, null);
    @include div-size(null, 100px);
}

//When widgets contain a grid, or a list of values, they require a 100% width and a fixed height
.wgt-box {
    @include div-size(100%, 205px);
    background-color: transparent;
    outline: $border-invisible;

    &:hover, &:focus {
        outline: $border-normal $brand-color;
    }

    .calendar-acc-container &:hover, .calendar-acc-container &:focus {
        outline: none;
    }
}

.wgt-wrapper {
    @include divStyle($grey-5, $border-normal $grey-3, null);
    @include div-size(null, auto);
}

.wgt-intervals {
    @include div-size(null, 100px);
    background-color: $white;
}

.wgt-check {
}

.wgt-generic {
    @include div-size(null, 30px);
    border: $border-normal $grey-3;
    padding: 10px;
}

.wgt-btn-generic {
    @include div-size(80px, 30px);
    @include divStyle($grey-3, null, $txt-grey-1);
    float: left;
}

.wgt-multiname-cont-txt {
    @include position-all(absolute, null, 60px, null, 0);
}

.wgt-multiname-langcode {
    @include divStyle($grey-2, null, $txt-white);
    @include div-size(60px, null);
    right: 0;
    padding-top: 1px;
}

// widget Styles
.img-fp {
    @include div-size(40px, 50px);
    cursor: pointer;
}

.wgt-photo, .wgt-fp {
    padding: 5px;
}

.img-flex {
    @include div-size(null, auto);
    max-width: 100%;
    min-width: 20%;
}

.wgt-photo-btns {
    @include div-size(28px, null);
    display: inline-block;
}

.wgt-top {
    @include position-all(absolute, 10px, null, null, null);
}

.wgt-bottom {
    @include position-all(absolute, null, null, 10px, null);
}

.wgt-enroll-btn {
    @include position-all(absolute, 10px, null, null, 75px);
}

//
// ARITMETICOS - Periodicidad Cierre widget-styles
//

.wgt-period-cierre {
    position: relative;
    display: inline-block;
    margin: auto;
    vertical-align: middle;
}

.wgt-radio-period {
    @include div-size(100%, null);
    display: flex;
    margin-bottom: 0;
    padding-top: 2px;
}

////////////////////////////
//
//  ENROLL - widget Styles
//
////////////////////////////

.enroll-step {
    margin: auto;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 5px;
}

.s-row-btns {
    padding-top: 2%;
}

.enroll-slide {
    @include margin(5px, auto, auto, auto);
    text-align: center;
}

.enroll-img-slide {
    margin-bottom: 10px;
}

.enroll-title, .enroll-message {
    @include divStyle(transparent, null, $txt-brand);
    @include margin(10px, 5px, 5px, 5px);
    @include text($special-font, 18px, null, bold, center, null);
    @include div-size(100%, null);
    text-transform: uppercase;
}

.enroll-message {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    color: $txt-warning;
    min-height: 30px;
}

.enroll-device-version {
    @include divStyle(transparent, null, $txt-grey-1);
    @include margin(10px, 5px, 5px, 5px);
    @include div-size(100%, null);
    min-height: 14px;
    text-align: left;
    font-size: 10px;
}

////////////////////////////
//
//  ENROLL CARD WIDGET
//
////////////////////////////

.progress-bar {
    @include div-size(98%, 1.5em);
    @include padding(.5em, .5em, 0, .5em);
    @include trans-opa(opacity 0.25s linear);
    font-size: .75em;
    clear: both;
    background-color: $white;
    border-radius: 30px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .3);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .3);
}

.progress-bar.loading {
    opacity: 1.0;
}

.progress-bar .percent {
    @include div-size(0, 1.2em);
    @include divStyle($yellow, null, $txt-grey-0);
    @include text(null, null, 1.5em, bold, center, null);
    border-radius: 30px;
}

// Missatges widget card-reader
.card-reader-msg {
    @include divStyle(transparent, null, $txt-brand);
    @include text($special-font, 14px, null, bold, center, null);
    margin: auto;
    text-transform: uppercase;
}

////////////////////////////
//
//  INPUT COLOR wgt Styles
//
////////////////////////////

.input-color {
    @include div-size(40px, 22px);

    &:hover {
        border: $border-normal $brand-color;
    }
}

////////////////////////////
//
//  ENROLL-TIMES widget Styles
//
////////////////////////////

// .enroll-times-input (widget) position inside-tree
.enroll-times-position {
    padding-left: 1em;
    display: inline;
}

///////////////////////////
//
//  EVENTS widget Styles
//
////////////////////////////

.event-panel {
    @include box-sizing(border-box);
    @include divStyle($white, $border-normal $grey-3, $txt-black);
    @include div-size(100%, null);
    overflow-x: hidden;
    display: block;
    margin: auto;
    padding: 2px;
    font-size: 12px;
    min-height: 70px;
}

.span-block {
    display: block;
}

///////////////////////////
//
//  AREA-TEXT widget Styles
//
////////////////////////////

.area-text-wgt {
    @include divStyle($white, $border-normal $grey-2, $txt-black);
    display: inline-block;
    overflow: auto;
    font-size: 12px;
    text-align: left;
    padding: 4px;

    &:hover, &:focus {
        @include divStyle(null, $border-normal $brand-color, null);
        outline: none;
    }

    &.disabled {
        @include divStyle($grey-4, $border-normal $grey-3, null);
    }

    &:hover.disabled, &:focus.disabled {
        @include divStyle($grey-4, $border-normal $grey-3, null);
        outline: none;
    }

    &.alert-error, &:hover.alert-error, &:focus.alert-error {
        @include divStyle(transparent, $border-normal $error-color, null);
            outline: none;
    }

    &.alert-warn, &:hover.alert-warm, &:focus.alert-warm {
        @include divStyle(transparent, $border-normal $warning-color, null);
            outline: none;
    }
}


span.showMoreInfo {
    margin: .5em .5em .5em 0;
    font-size: 1em;
    font-weight: bold;

    &:hover, &:focus {
        color: $brand-color;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Drop down list - Auto Complete
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.dd-btn {
    @include box-sizing(border-box);
    @include divStyle($grey-3, $border-invisible, $txt-white);
    @include div-size(25px, null);
    display: inline-block;
    right: 0;
    text-align: center;
    font-size: 16px;


    &:hover {
        @include divStyle($brand-color, $border-normal $brand-color, null);
    }
}

.dd-wrapper {
    @include div-size(100%, null);
    position: relative;
    display: block;

    .loader-holder {
        @include div-size(inherit, 240px);
        display: none;
        z-index: 500;
        animation: none;

        .loader {
            top: 20%;
            left: 40%;
        }
    }

    .dd-body {
        @include divStyle($white, $border-normal $brand-color, null);
        display: none;
        position: absolute;
        z-index: 500;
        width: inherit;
        max-height: 240px;
        overflow-y: auto;
        box-shadow: 1px 1px 2px $grey-1;

        .dd-list {
            margin: 0;
            padding: 0;

            li {
                @include ellipsis-text;
                @include div-size(null, 24px);
                display: block;
                line-height: 24px;
                text-indent: 5px;

                &:hover {
                }
            }
        }
    }

    &.opened {
        .dd-body {
            display: block;
        }
        .loader-holder {
            display: block;
        }
    }
}

//RESULTS
.row-col-zone {
    border: 1px dashed $grey-2 !important;
    box-sizing: border-box;
    font-family: $special-font;
    background-color: $grey-4b !important;
}

.position-label {
    margin-bottom: 0;
    text-align: left;
    min-width: 350px;
    min-height:20px;
    font-weight: bold;
    padding-top: 3px;
}

.position-row-cols {
    min-width: 350px;
    min-height:20px;
}

.win-view-save-zone {
    @include position-all(absolute, null, null, 34px, 205px);
    width: 24px;
}

.topbar-results-combo {
    padding: 10px 0 0 10px;
}

