////////////////////////////
//
// ESTRUCTURA GRID (s-row i s-col -> estandar;
// fltr-row i fltr-col -> amb possibles variacions per la finestre de filtres.)
//
////////////////////////////

.s-grid-base {
    @include position-all(relative, 55px, 0, 0, 0);
    @include div-size(100%, 86%);
    @include box-sizing(border-box);
    overflow: hidden;

    .modal-win & {
        top: 80px;
    }
}

.s-row, .s-col,
.fltr-row, .fltr-col {
    @include box-sizing(border-box);
}

.s-row:before, .s-row:after,
.fltr-row:before, .fltr-row:after {
    content: " ";
}

.s-row:after,
.fltr-row:after {
    clear: both;
}

.s-col,
.fltr-col {
    position: relative;
    float: left;

    .mov-main-area & {
        margin: auto;
    }
}

.s-col + .s-col,
.fltr-col + .fltr-col {
    margin-left: 1.6%;
}

.s-col-1,
.fltr-col-1 {
    width: 6.86666666667%;
}

.s-col-2,
.fltr-col-2 {
    width: 15.3333333333%;
}

.s-col-3,
.fltr-col-3 {
    width: 23.8%;
}

.s-col-4,
.fltr-col-4 {
    width: 32.2666666667%;
}

.s-col-5,
.fltr-col-5 {
    width: 40.7333333333%;
}

.s-col-6,
.fltr-col-6 {
    width: 49.2%;
}

.s-col-7,
.fltr-col-7 {
    width: 57.6666666667%;
}

.s-col-8,
.fltr-col-8 {
    width: 66.1333333333%;
}

.s-col-9,
.fltr-col-9 {
    width: 74.6%;
}

.s-col-10,
.fltr-col-10 {
    width: 83.0666666667%;
}

.s-col-11,
.fltr-col-11 {
    width: 91.5333333333%;

    .mov-days & {
        width:  94%;
    }
}

.s-col-12,
.fltr-col-12 {
    width: 100%;
    margin-left: 0;
}

@media only screen and (max-width: 550px) {
    .s-col-1, .fltr-col-1,
    .s-col-2, .fltr-col-2,
    .s-col-3, .fltr-col-3,
    .s-col-4, .fltr-col-4,
    .s-col-5, .fltr-col-5,
    .s-col-6, .fltr-col-6,
    .s-col-7, .fltr-col-7,
    .s-col-8, .fltr-col-8,
    .s-col-9, .fltr-col-9,
    .s-col-10, .fltr-col-10,
    .s-col-11, .fltr-col-11,
    .s-col-12, .fltr-col-12 {
        float: none;
        width: auto;
    }
    .s-col + .s-col,
    .fltr-col + .fltr-col {
        margin-left: 0;
    }
}

// ESTILS - GRID
.s-col,
.fltr-col {
    text-align: left;

    .intervals-list &,
    .gg-row-header & {
        text-align: center;
    }

    &.gg-head-cell {
        border-right: $border-normal $white;
    }
}

.s-row,
.fltr-row {
    display: flex;
    margin-bottom: 10px;
    min-height: 30px;

    .calendar-base &,
    .calendar-info-list &,
    .scalendar-col-title &,
    .mod-access-cont-stripes-list &,
    .scicles-col-title &,
    .gg-container &,
    .g-win-item &,
    .dev-act-title & {
        margin-bottom: 3px;
        min-height: 0;
    }

    .calendar-day-tooltip &,
    .mov-tooltip & {
        margin-bottom: 5px;
        min-height: 0;
    }


    .g-win-item & {
        min-height: 25px;
    }

    .intervals-list & {
        @include divStyle(transparent, $border-invisible, $txt-black);
        margin-bottom: 0;
    }

    &.intervals-row-error {
        background-color: #fce4e4;
        border: $border-invisible;
        border-bottom: $border-normal $error-color;
        margin-bottom: 0;
    }

    .mov-month & {
        margin-bottom: 1px;
        min-height: 0;
    }

    &  .generic-list .g-win-item  {
        margin-bottom: 0;
    }
}

.s-row:last-child,
.fltr-row:last-child {
    margin-bottom: 0;
}

.s-col .s-col,
.fltr-col .fltr-col {
    @include divStyle(transparent, $border-invisible, null);
}

@media only screen and (max-width: 550px) {
    .s-row, .fltr-row {
        margin-bottom: 0;
    }
    .s-col, .fltr-col {
        margin-bottom: 10px;
    }
    .s-row:last-child .s-col:last-child,
    .fltr-row:last-child .fltr-col:last-child {
        margin-bottom: 0;
    }
}

////////////////////////////
//
//ESTRUCTURA GRID (IFRAMES - import milligram.css
//
////////////////////////////

.row {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.row.row-no-padding {
  padding: 0;
}

.row.row-no-padding > .column {
  padding: 0;
}

.row.row-wrap {
  flex-wrap: wrap;
}

.row.row-top {
  align-items: flex-start;
}

.row.row-bottom {
  align-items: flex-end;
}

.row.row-center {
  align-items: center;
}

.row.row-stretch {
  align-items: stretch;
}

.row.row-baseline {
  align-items: baseline;
}

.row .fcolumn {
  display: block;
  flex: 1 1 auto;
  margin-left: 0;
  max-width: 100%;
  width: 100%;
}

.row .fcolumn.fcolumn-offset-10 {
  margin-left: 10%;
}

.row .fcolumn.fcolumn-offset-20 {
  margin-left: 20%;
}

.row .fcolumn.fcolumn-offset-25 {
  margin-left: 25%;
}

.row .fcolumn.fcolumn-offset-33, .row .fcolumn.fcolumn-offset-34 {
  margin-left: 33.3333%;
}

.row .fcolumn.fcolumn-offset-50 {
  margin-left: 50%;
}

.row .fcolumn.fcolumn-offset-66, .row .fcolumn.fcolumn-offset-67 {
  margin-left: 66.6666%;
}

.row .fcolumn.fcolumn-offset-75 {
  margin-left: 75%;
}

.row .fcolumn.fcolumn-offset-80 {
  margin-left: 80%;
}

.row .fcolumn.fcolumn-offset-90 {
  margin-left: 90%;
}

.row .fcolumn.fcolumn-10 {
  flex: 0 0 10%;
  max-width: 10%;
}

.row .fcolumn.fcolumn-20 {
  flex: 0 0 20%;
  max-width: 20%;
}

.row .fcolumn.fcolumn-25 {
  flex: 0 0 25%;
  max-width: 25%;
}

.row .fcolumn.fcolumn-33, .row .fcolumn.fcolumn-34 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.row .fcolumn.fcolumn-40 {
  flex: 0 0 40%;
  max-width: 40%;
}

.row .fcolumn.fcolumn-50 {
  flex: 0 0 50%;
  max-width: 50%;
}

.row .fcolumn.fcolumn-60 {
  flex: 0 0 60%;
  max-width: 60%;
}

.row .fcolumn.fcolumn-66, .row .fcolumn.fcolumn-67 {
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
}

.row .fcolumn.fcolumn-75 {
  flex: 0 0 75%;
  max-width: 75%;
}

.row .fcolumn.fcolumn-80 {
  flex: 0 0 80%;
  max-width: 80%;
}

.row .fcolumn.fcolumn-90 {
  flex: 0 0 90%;
  max-width: 90%;
}

.row .fcolumn .fcolumn-top {
  align-self: flex-start;
}

.row .fcolumn .fcolumn-bottom {
  align-self: flex-end;
}

.row .fcolumn .fcolumn-center {
  -ms-grid-row-align: center;
      align-self: center;
}

@media (min-width: 40rem) {
  .row {
    flex-direction: row;
    margin-left: -1.0rem;
    width: calc(100% + 2.0rem);
  }
  .row .fcolumn {
    margin-bottom: inherit;
    padding: 0 1.0rem;
  }
}

