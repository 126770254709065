////////////////////////////
//
//  VERTICAL & HORIZONTAL dividers (panels that can divide by two their area
//
////////////////////////////

.divider {
    @include div-size(100%, 100%);
    position: absolute; /* Because window has absolute, divider must be also absolute */
    display: inline-block;
    bottom: 0;
    top: 0;

    //VERTICAL
    &.vert-left {
        left: 0;

        // JDS - Jeez, take me asap... ¬¬
        // width: 69.5%; //Default pcent value (complementary to vert-right)

        background-color: $grey-5;
    }

   &.vert-handle {
       @include div-size(4px, 30px);
       top: 50%;
       margin-left: 2px;
       cursor: ew-resize;
       z-index: $layer-win;
       border-left: $border-normal $brand-color;
       border-right: $border-normal $brand-color;
       -ms-transform: translateY(-50%);
       transform: translateY(-50%);

       .access-monitor-module & {
           margin-left: 4px;
       }

    }

    &.vert-right {
        // width: 29.5%; //Default pcent value
        background-color: $grey-5;

        .access-monitor-module &.vert-right {
            margin-left: 4px;
        }
    }

    //HORIZONTAL
    &.hor-top{
        // height: 69.5%; //Default pcent value (complementary to vert-right)
        background-color: $grey-5;
    }

    &.hor-handle {
        @include div-size(30px, 4px);  //HEIGHT  value must be the same used inside CVerticalDivider.js
        left: 50%;
        cursor: ns-resize;
        z-index: $layer-win;
        border-top: $border-normal $brand-color;
        border-bottom: $border-normal $brand-color;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);

        .access-monitor-module .hor-handle {
            padding-top: 4px;
        }
    }

    &.hor-bottom {
        height: 29.5%; //Default pcent value
        background-color: $grey-5;
    }

    .access-monitor-module &.hor-bottom{
        padding-top: 5px;
    }
}
