////////////////////////////
//
//  IFRAME - Styles
//
////////////////////////////

#LAY_HEADER {
}

.icontainer {
    width: 95%;
    margin: 0 auto;
    border: 0;
    padding-top: 5px;
    min-height: 20px;
}

.progressbar {
    background-color: $brand-color;
    @include div-size(90%, 5px);
    overflow: hidden;
    visibility: hidden;
    padding: 2px;
    text-align: right;
    margin: 1px;
    vertical-align: middle;
    border-radius: 30px;
}

//  DOCUMENTS DOCS-DROP_ZONE Styles

.idocs-drop-zone {
    @include box-sizing(border-box);
    @include divStyle($white, 1px dashed $grey-1, $txt-grey-2);
    padding: 15px;
    text-align: center;
    margin: 5px;
    font-size: 1.2em;
    font-family: $special-font;
    width: 100%;
}

.idocs-file-container {
    width: 85%;
    margin: auto;
    border: $border-normal $grey-3;
    padding: 2px;
    position: relative;
    overflow: hidden;
    margin-top: 5px;
}

.orange {
    border-left: 3px $warning-color;
    border-bottom: $border-normal $warning-color;
}

.blue {
    border-left: 3px solid $blue;
    border-bottom: $border-normal $blue;
}

.brand-color {
    border-left: 3px solid $brand-color;
    border-bottom: $border-normal $brand-color;
}

.idocs-foto {
    float: left;
}

.idocs-file-name {
    padding-top: 20px;
    font-family: $special-font;
    font-size: 12px;
}

.idocs-title {
    @include divStyle($grey-2, null, $txt-grey-0);
    font-size: 14px;
    margin-bottom: 15px;
    width: auto;
    text-align: left;
    display: block;
    line-height: 2;
    font-family: $special-font;
}

.idocs-item-list {
    display: inline-block;
    margin: 5px 0 5px 0;
    background-color: $grey-4;
    width: 80%;
    padding: 5px;
    text-align: left;

    &:hover {
        background-color: $grey-3;
    }
}

.idocs-download {
    display: inline-block;
    width: 85%;
}

.idocs-thumbnail {
    @include div-size(60px, 60px);
    display: inline-block;
    float: right;
}

.idocs-delete {
    @include div-size(30px, 60px);
    display: inline-block;
    float: right;
    padding-top: 16px;

    & img:hover {
        background-color: $yellow;
    }
}

/////////////////////////////////
//
//  IMPORT EMPLOYEES Styles
//

.dropdown {
    @include divStyle($grey-4, $border-normal $brand-color, null);
    @include padding(10px, null, 10px, null);
    height: auto;

    &:hover {
        background-color: rgba(0, 160, 155, .5);
    }
}

.dropdown-txt {
    @include ellipsis-text;
    font-family: $special-font;
    display: inline-block;
    font-size: 12px;
    text-align: left;
    text-indent: 4px;
    color: $txt-grey-0;
    line-height: 2;
}

.dropdown-menu {
    @include position-all(absolute, 100%, null, null, 0);
  z-index: $layer-dropdown-import;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 10px;
  text-align: left;
  list-style: none;
  background-color: $white;
  -webkit-background-clip: padding-box;
          background-clip: padding-box;
  border: $border-normal #ccc;
  border: $border-normal rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .175);
          box-shadow: 0 3px 6px rgba(0, 0, 0, .175);
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: $brand-color;
  text-decoration: none;
  background-color: $yellow;
}

.btn-sp-height {
    height: 46px;
}

.arrow-sp {
    margin-top: 10px;
    margin-right: 8px;
    float: right;
}

.idocs-paste-zone {
    border: $border-normal $grey-2;
    padding: 5px;
    color: $txt-grey-2;
    margin: 5px;
    font-size: 1em;
    text-align: left;
    text-indent: 4px;
    font-family: $special-font;
    background-color: $white;
    @include div-size(100%, 40px);
    @include box-sizing(border-box);

    &:hover, &:focus {
        border: $border-normal $brand-color;
        outline: none;
    }
}

.special-button {
    background-color: $brand-color;
    font-size: 12px;
    padding: 4px;
    color: $txt-white;
    height: 30px;
    margin: auto;
    text-align: center;

    &:hover {
        background-color: $grey-1;
        border-color: $grey-1;
    }
}

.msg-panel {
    overflow-x: auto;
    padding: 2px;
    background-color: $grey-5;
    border: $border-normal $grey-3;
    font-size: 12px;
    @include box-sizing(border-box);
    @include div-size(100%, 70px);
    margin: 5px;
}

#msg{
    line-height: 1em;
}

.msgOK{
 color: #69A150;
 font-family: $special-font;
}

.msgInfo{
 color: $info-color;
 font-family: $special-font;
}

.msgError{
 color: $error-color;
 font-family: $special-font;
 font-weight: bold;
 line-height: 2em;
 text-transform: uppercase;
}
.msgWarn{
 color: $warning-color;
 font-family: $special-font;
}

.blue {
    color: $blue !important;
}

.table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: $white !important;
    padding: 5px;
    line-height: 2em;
    vertical-align: top;
    font-size: 12px;
    height: 26px;
  }

  .table-bordered th,
  .table-bordered td {
    padding: 5px;
    line-height: 2em;
    vertical-align: top;
    font-size: 12px;
    height: 26px;
    border: 1px solid #ddd !important;
  }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: top;
  border-top: $border-normal #ddd;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ddd;
}
.table .table {
  background-color: $white;
}

.table-bordered {
  border: $border-normal #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: $border-normal  #ddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}
table col[class*="s-col-"] {
  position: static;
  display: table-column;
  float: none;
}
table td[class*="s-col-"],
table th[class*="s-col-"] {
  position: static;
  display: table-cell;
  float: none;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

/////////////////////////////////
//
//  TERMINAL MONITOR Styles
//

.status {
    float: right !important;
    position: relative;
    @include div-size(15px, 15px);
    border: $border-normal $white;
    border-radius: 50px;
    border-left: $border-normal #E5E5E5;
    border-top: $border-normal #E5E5E5;
    border-right: $border-normal #E5E5E5;
    border-bottom: $border-normal #E5E5E5;
}

.running{
    background-color: $brand-color;
    animation: online 1s linear forwards;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
}

.connecting{
    background-color: #FFC400;
    animation: loading 1s linear forwards;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
}
.offline{
    background-color: #de3626;
}

@keyframes loading {
    from {background-color: #FFC400;}
    to {background-color: #FFada1;}
}

@keyframes online {
    from {background-color: #008E80;}
    to {background-color: #00ada1;}
}

.sticky {
    position: sticky;
    top: .2em;
    align-self: flex-start;
    z-index: 200;
}

.alert {
    font-size: 1.2em;
    padding: 8px;
    font-family: $special-font;
    font-weight: bold;
    width: 100%;

    &.red {
    color: $error-color;
    }
}

.alert.alert-running {
    background-color: $info-color;
    color: $txt-white;
    border: $border-normal $info-color;
}
.alert.alert-warning {
    background-color:  #f39f0e;
    color: $txt-white;
    border: $border-normal  #f39f0e;
}
.alert.alert-offline {
    background-color: $error-color;
    color: $txt-white;
    border: $border-normal $error-color;
}

.list-group.components {
    float: left;
    padding: 5px;
    min-height: 260px;
    min-width: 250px;
    max-width: 450px;
    font-size: 1em;
    font-family: $special-font;
}

.list-group .list-group-item {
    padding: 5px;
    position: relative;
    display: block;
    border: $border-normal $grey-3;
    overflow: auto;
}

.group-items {
    line-height: 1.428571429;
}

.group-name {
    background-color: $grey-4;
}

.name-title {
  font-size: 12px;
  color: $txt-grey-0;
  font-family: $special-font;
  display: inline-block;
  font-weight: bold;
  @include ellipsis-text;
}

.group-items {
    background-color: $white;
    border: $border-normal $grey-3;
    font-size: 0.8em;
}

.pull-right {
    float: right !important;
    text-align: right;
}

.section-name {
  font-size: 10px;
  color: $txt-grey-0;
  font-family: $special-font;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
}

.prop-item-section {
    font-family: $base-font;
    font-size: 10px;
    color: $txt-grey-1;
}

.txt-title-monitor {
    font-size: 18px;
    font-family: $special-font;
    display: inline-block;
    padding: 4px;
    width: 100%;
}
