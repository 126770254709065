////////////////////////////
//
//  FILE-LOADER Styles
//
////////////////////////////

.file-loader-wrapper, .img-fp {
    position: relative;
    border: $border-normal $grey-3;
}

.file-loader-wrapper > input {
    display: none;
}

.file-loader-wrapper img.file-loader-img {
    @include div-size(100px, 125px);
    cursor: pointer;
}

button.file-loader-remove {
    @include position-all(absolute, 50%, null, null, 50%);
}

button.file-loader-remove:hover {
    background-color: $black;
}

////////////////////////////
//
//  MEDIA DEVICE Styles
//
////////////////////////////

/////  Video stuff and related...

.mediaDeviceWrapper, .enroll-wrapper {
    @include div-size(500px, 350px);
    @include divStyle($grey-5, 3px solid $brand-color, null);
    position: fixed;
}

video, canvas {
    @include div-size(400px, 300px);
    margin-left: 10%;

    .gg-cell & {
        @include div-size(30px, 24px);
        margin: auto;
    }
}

.video-buttons {
    @include position-all(absolute, null, null, 1px, null);
    @include div-size(100%, 50px);
    display: flex;
    align-items: center;
    background-color: rgba(240, 240, 240, 240.24);
}

.video-buttons button {
    flex: 1;
    height: 100%;
    color: $txt-grey-1;
}

.video-buttons .btn-icon:hover,
.video-buttons .btn-icon:focus {
    background-color: $grey-2;
}

.hidden {
    display: none;
}




